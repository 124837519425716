import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { CButton, CCol, CFormInput, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { successAlert } from "src/utils/Swal";
import PropTypes from "prop-types";
const RegistrationForm = (props) => {
  const dispatch = useDispatch();
  const [submission, setSubmission] = useState(false);

  const initialValues = {
    loanId: "",
    memName: "",
    memcode: "",
    mobile: "",
    type: "",
    centerName: "",
    payDate: "",
    amount: "",
    receivedBy: "",
    payMode: "",
  };

  const validationSchema = Yup.object().shape({
    memName: Yup.string().required("Member Name is required"),
    memcode: Yup.string().required("Member Code is required"),
    mobile: Yup.number().required("Mobile Numeber is required"),
    type: Yup.string().required("Type is required"),
    centerName: Yup.number().required("Center Name is required"),
    payDate: Yup.date().required("Payment Date is is required"),
    amount: Yup.number().required("Amount is required"),
    receivedBy: Yup.string().required("Received By is required"),
    payMode: Yup.string().required("Payment mode is required"),
  });

  const handleSubmit = (values) => {
    props.updateFormData("registration", values);
    props.setKey("address");
    // dispatch(addUser(values));
    setSubmission(true);
  };

  useEffect(() => {
    if (submission === true) {
      successAlert("Success", "New Customer has been Created");
    }
  }, [submission]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <CRow style={{ margin: "20px 0" }}>
          <CCol xs={6} className="position-relative">
            <Field
              type="text"
              id="email"
              name="memName"
              as={CFormInput}
              floatingClassName="mb-4"
              floatingLabel="Member Name"
            //   placeholder="name@example.com"
            />
            <ErrorMessage
              name="memName"
              component="div"
              className="text-danger"
            />
          </CCol>

          <CCol xs={6} className="position-relative">
            <Field
              type="text"
              id="memcode"
              name="memcode"
              as={CFormInput}
              floatingClassName="mb-4"
              floatingLabel="Member Code"
            //   placeholder="Mobile Number"
            />
            <ErrorMessage
              name="memcode"
              component="div"
              className="text-danger"
            />
          </CCol>
        </CRow>

        <CRow style={{ margin: "20px 0" }}>
          <CCol xs={6} className="position-relative">
            <Field
              as={CFormInput}
              type="number"
              id="mobile"
              name="mobile"
              floatingClassName="mb-4"
              floatingLabel="Mobile Number"
            //   placeholder="Date of Birth"
            />
            <ErrorMessage
              name="mobile"
              component="div"
              className="text-danger"
            />
          </CCol>

          <CCol xs={6} className="position-relative">
            <Field
              as={CFormInput}
              type="text"
              id="fatherName"
              name="type"
              floatingClassName="mb-4"
              floatingLabel="Type"
            //   placeholder="Father's Name"
            />
            <ErrorMessage name="type" component="div" className="text-danger" />
          </CCol>
        </CRow>

        <div style={{ margin: "0px 0px 20px" }}>
          <CRow>
            <CCol xs={6} className="position-relative">
              <Field
                type="text"
                id="centerName"
                name="centerName"
                as={CFormInput}
                floatingClassName="mb-4"
                floatingLabel="Center Name"
              />
              <ErrorMessage
                name="centerName"
                component="div"
                className="text-danger"
              />
            </CCol>
            <CCol xs={6} className="position-relative">
              <Field
                type="date"
                id="payDate"
                name="payDate"
                as={CFormInput}
                floatingClassName="mb-4"
                floatingLabel="Pay Date"
              />
              <ErrorMessage
                name="payDate"
                component="div"
                className="text-danger"
              />
            </CCol>
          </CRow>
        </div>

        <CRow style={{ margin: "20px 0" }}>
          <CCol xs={6} className="position-relative">
            <Field
              type="number"
              id="amount"
              name="amount"
              as={CFormInput}
              floatingClassName="mb-4"
              floatingLabel="Amount"
            />
            <ErrorMessage
              name="amount"
              component="div"
              className="text-danger"
            />
          </CCol>
          <CCol xs={6} className="position-relative">
            <Field
              type="text"
              id="receivedBy"
              name="receivedBy"
              as={CFormInput}
              floatingClassName="mb-4"
              floatingLabel="Received By"
            />
            <ErrorMessage
              name="receivedBy"
              component="div"
              className="text-danger"
            />
          </CCol>
        </CRow>

        <CRow style={{ margin: "20px 0" }}>
          <CCol xs={6} className="position-relative">
            <Field
              type="text"
              id="payMode"
              name="payMode"
              as={CFormInput}
              floatingClassName="mb-4"
              floatingLabel="Pay Mode"
            />
            <ErrorMessage
              name="payMode"
              component="div"
              className="text-danger"
            />
          </CCol>
        </CRow>
        <div className="identification_btn">
          <CButton type="submit">Submit </CButton>
        </div>
      </Form>
    </Formik>
  );
};
RegistrationForm.propTypes = {
  formData: PropTypes.object,
  updateFormData: PropTypes.func,
  setKey: PropTypes.func,
};
export default RegistrationForm;
