import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
} from "@coreui/react";
import * as Yup from "yup";
import StaffVerification from "./staffVerification";
import ManagerVerification from "./managerVerification";
import PropTypes from "prop-types";

const initialValues = {
  aadharNumber: "",
  PanNumber: "",
  singnatureImageUrl: null,
  panImageUrl: null,
  aadharImageUrl: null,
  permanentResidencyStatus: "",
};

const VerificationForm = ({ setShowVerification, selectedData }) => {
  const panRef = useRef();
  const aadharRef = useRef();
  const singnatureRef = useRef();
  const photoRef = useRef();
  const [identification, setIdentification] = useState(initialValues);
  const [activeKey, setActiveKey] = useState(1);
  const [activeKeys, setActiveKeys] = useState("");

  const companyId = sessionStorage.getItem("companyId");
  const roletype = sessionStorage.getItem("role");

  useEffect(() => {
    setActiveKeys(roletype);
  }, []);

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setIdentification({ ...identification, [name]: value });
  };

  const validationSchema = Yup.object().shape({
    aadharCard: Yup.string()
      .typeError("please select a file")
      .required("aadharNumber is required"),
    aadharNumber: Yup.number().required("AadharCard is required"),
    PanNumber: Yup.number().required("PanCard is required"),
    permanentResidencyStatus: Yup.string().required(
      "Permanent Residency Status is required"
    ),
    panCard: Yup.string()
      .typeError("please select a file")
      .required("panCard is required"),
    panImageUrl: Yup.mixed()
      .required("Images is required")
      .test(
        "fileSize",
        "File size is too large",
        (value) => value && value.size <= 1024000
      )
      .test(
        "fileType",
        "Unsupported file format",
        (value) => value && ["image/jpeg", "image/png"].includes(value.type)
      ),
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = {
      aadharNumber: aadharNumber,
      PanNumber: PanNumber,
      singnatureImageUrl: singnatureRef?.current?.files[0],
      panImageUrl: panRef?.current?.files[0],
      aadharImageUrl: aadharRef?.current?.files[0],
      permanentResidencyStatus: permanentResidencyStatus,
      passportSizePhoto: photoRef?.current?.files[0],
    };
    const formData = new FormData();
    formData.append("aadharNumber", aadharNumber);
    formData.append("PanNumber", PanNumber);
    formData.append("singnatureImageUrl", singnatureRef?.current?.files[0]);
    formData.append("panImageUrl", panRef?.current?.files[0]);
    formData.append("aadharImageUrl", aadharRef?.current?.files[0]);
    formData.append("permanentResidencyStatus", permanentResidencyStatus);
    formData.append("passportSizePhoto", photoRef?.current?.files[0]);
    const newData = {
      id: companyId,
      data: formData,
    };
  };

  const handleCustomerList = () => {
    setShowVerification(false);
  };

  const {
    aadharNumber,
    PanNumber,
    permanentResidencyStatus,
  } = identification;
  return (
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol xs={11}></CCol>
          <CCol xs={1}>
            <CButton
              type="button"
              aria-label="Close"
              onClick={() => handleCustomerList()}
            >
              Back
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>

      <CCardBody> 
        <CContainer>
              <>
                <CNav variant="tabs" role="tablist">
                  <CNavItem role="presentation">
                    {roletype === "employee" ? (
                      <CNavLink
                        active={roletype === "employee"}
                        component="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected={roletype === "employee"}
                      >
                        Staff
                      </CNavLink>
                    ) : (
                      <CNavLink
                        active={roletype === "manager"}
                        component="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected={roletype === "manager"}
                      >
                        Manager
                      </CNavLink>
                    )}
                    {roletype === "company" && (
                      <>
                        <CNavLink
                          active={activeKey === 1}
                          component="button"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected={activeKey === 1}
                        >
                          Staff
                        </CNavLink>
                        <CNavLink
                          active={activeKey === 2}
                          component="button"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected={activeKey === 2}
                        >
                          Manager
                        </CNavLink>
                      </>
                    )}
                  </CNavItem>
                </CNav>
                <CTabContent>
                  {roletype === "employee" ? (
                    <CTabPane
                      role="tabpanel"
                      aria-labelledby="home-tab-pane"
                      visible={activeKeys === "employee"}
                    >
                      <StaffVerification setShowVerification={setShowVerification} selectedData={selectedData} />
                    </CTabPane>
                  ) : (
                    <CTabPane
                      role="tabpanel"
                      aria-labelledby="profile-tab-pane"
                      visible={activeKeys === "manager"}
                    >
                      <ManagerVerification setShowVerification={setShowVerification} selectedData={selectedData} />{" "}
                    </CTabPane>
                  )}
                </CTabContent>
              </>
            </CContainer>
      </CCardBody>      
    </CCard>
  );
};

VerificationForm.propTypes = {
  setShowVerification: PropTypes.func,
  selectedData: PropTypes.object,
};

export default VerificationForm;
