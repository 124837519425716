import DataTable from "src/components/DataTable";

import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import Tooltip from "src/components/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import CompanyAllDetail from "../../company/companyAllDetails";
import ReactPaginate from "react-js-pagination";
import DeleteModal from "src/components/commonComponents/commonDeleteModal";
import CompanyAddAndUpdateForm from "src/components/Modal/editAndAddForm";
import { Button } from "react-bootstrap";
import CustomerAddForm from "../../customer/customerAddForm";
import AddForm from "../../customer/addForm";
import CustomerEditForm from "../../customer/customerEditForm";
import CustomerAllDetails from "../../customer/customerAllDetails";
import { getAllCustomers } from "src/api/allApis";
import { useNavigate } from "react-router-dom";

const Rejected = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state?.companyReducer);
  const { routeValue } = useSelector((state) => state?.planReducer);


  const [model, setModel] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [updateForm, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [companyModel, setCompanyModel] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customersPendingStatus, setCustomersPendingStatus] = useState([])
  const { staffReducer } = useSelector((state) => state)
  const [SchemaName, setSchemaName] = useState(sessionStorage.getItem("schemaName"))
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    getAllCompanyData();
  }, []);

  const getAllCompanyData = () => {
    const payload = { schemaName: SchemaName };
    getAllCustomers(payload).then((res) => {
      setCustomersPendingStatus(res?.data);
    });
    // dispatch(getAllInterests(payload));
  };


  const handleShow = () => setShowModal(true);
  const handleDelete = () => {
    setSelectedItemId(null);
  };

  const handleDeleteClick = (itemId) => {
    setSelectedItemId(itemId);

    setCompanyModel(true);
    handleShow();
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = customersPendingStatus?.slice(indexOfFirstRow, indexOfLastRow);
  const handleEditLoan = (loanData) => {
    setUpdate(true);
    setEditCustomerData(loanData);
    setModel("update");
  };

  const handleShowAddForm = () => {
    // When the user clicks the "Add" button, hide the button and show the form
    setShowAddButton(false);
    setShowSelectAndSearch(false);
    setShowAddForm(true);
  };
  const handleViewdetails = (company) => {
    setShowSelectAndSearch(false);
    setSelectedCompany(company);
    setShowAccordion(true);
  };
  const roletype = sessionStorage.getItem("role");


  const handlePending = (customerData) => {
    navigate("/customers/pending");
  };

  const handleApproved = (customerData) => {
    navigate("customers/approved");
  };

  const handleRejected = (customerData) => {
    navigate("/customers/rejected");
  };

  // const handleReject = () => {
  //   navigate("/customers/customer/reject");
  // };

  return (
    <>
      <CRow className="p-1">
        <CCol xs={12}>
          <CCard className="mb-4">
            {/* <div className="btn_add">
              {roletype !== "customer" && showAddButton && (
                <Button className="add-btn" onClick={handleShowAddForm}>
                  Add
                </Button>
              )}
            </div> */}

            <div className="search_bar">
              {showSelectAndSearch && (
                <div>
                  <CFormSelect
                    size="sm"
                    className="mb-3"
                    style={{ cursor: "pointer" }}
                    aria-label="Small select example"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </CFormSelect>
                </div>
              )}
              <div style={{ display: "flex" }}>
                {showSelectAndSearch && (
                  <>
                    <CFormInput
                      type="text"
                      size="sm"
                      placeholder="Search"
                      aria-label="sm input example"
                      style={{ margin: "0px 0px 16px 0px" }}
                    // onChange={handleSearchChange}
                    // value={searchInput}
                    />

                    <span
                      style={{
                        border: " 1px solid #d1c5c5",
                        borderRadius: "4px",
                        margin: "0px 5px 16px -1px",
                        padding: "1px 8px 0px 8px",
                        background: "#ebedef",
                        cursor: "pointer",
                      }}
                    >
                      <i className="fa fa-search"></i>
                    </span>
                  </>
                )}
              </div>
            </div>

            <CCardBody>
              <div
                // className={`table-container ${showAccordion ? "hidden" : ""}`}
                // className={`table-container ${showAddForm ? "hidden" : ""}`}
                className={`table-container ${showAccordion ? "hidden" : ""} ${showAddForm ? "hidden" : ""
                  }`}
              >
                <CTable color="dark" hover className="" responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col" className="col-md-1">
                        S. No.
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Address
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Email Id
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Mobile Number
                      </CTableHeaderCell>
                      {/* <CTableHeaderCell scope="col" className="col-md-2">
                        Loan Status
                      </CTableHeaderCell> */}
                      {role === "employee" ? "" :
                        <CTableHeaderCell scope="col" className="col-md-3">
                          Action
                        </CTableHeaderCell>}
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {customersPendingStatus && customersPendingStatus.length > 0 ? (
                      currentRows?.map((customerData, index) => (
                        <>
                          {customerData?.loanApprovalStatus === "rejected" ?
                            <CTableRow key={index}>
                              <CTableHeaderCell
                                scope="row"
                                style={{ paddingLeft: "15px" }}
                              >
                                {indexOfFirstRow + index + 1}
                              </CTableHeaderCell>
                              <CTableDataCell>
                                {customerData?.firstName || "--"}
                              </CTableDataCell>
                              <CTableDataCell>
                                {customerData?.address || "--"}
                              </CTableDataCell>
                              <CTableDataCell>
                                {customerData?.email || "--"}
                              </CTableDataCell>
                              <CTableDataCell>
                                {customerData?.phoneNumber || "--"}
                              </CTableDataCell >
                              {/* <CTableDataCell>
                                {customerData?.loanApprovalStatus === null ? (
                                  <span className="pending_status">
                                    <span
                                      style={{
                                        backgroundColor: '#0d6efd',
                                        color: '#fff',
                                        border: 0,
                                        padding: '4px 8px'
                                      }}
                                    // onClick={() => handlePending(customerData)}
                                    >
                                      Pending
                                    </span>
                                  </span>
                                ) : (
                                  <span>
                                    {customerData?.loanApprovalStatus ===
                                      "approved" ? (
                                      <span
                                        className="approve"
                                      // onClick={() => handleApproved(customerData)}
                                      >
                                        Approved
                                      </span>
                                    ) : (
                                      <span
                                        className="reject"
                                      // onClick={() => handleRejected(customerData)}
                                      >
                                        Rejected
                                      </span>
                                    )}
                                  </span>
                                )}
                              </CTableDataCell> */}
                              {/* <CTableDataCell>
                          {customerData?.customerDataStatus}
                        </CTableDataCell> */}
                              {role === "employee" ? "" :
                                <CTableDataCell
                                  style={{ padding: "10px 0px 10px 0px" }}
                                  className="col-md-3 "
                                >
                                  <>
                                    <Tooltip text="View">
                                      <FontAwesomeIcon
                                        role="button"
                                        icon={faEye}
                                        size="1x"
                                        className="viewIcon"
                                        onClick={() =>
                                          handleViewdetails(customerData)
                                        }
                                      />
                                    </Tooltip>

                                    <Tooltip text="Update">
                                      <FontAwesomeIcon
                                        role="button"
                                        icon={faPenToSquare}
                                        size="1x"
                                        onClick={() => handleEditLoan(customerData)}
                                        className="px-3 updateIcon"
                                      />
                                      {model === "update" ? (
                                        <CustomerEditForm
                                          setVisible={setModel}
                                          visible={model}
                                          customerData={editCustomerData}
                                          updateForm={updateForm}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Tooltip>

                                    <Tooltip text="Delete">
                                      <FontAwesomeIcon
                                        onClick={() =>
                                          handleDeleteClick(customerData?.id)
                                        }
                                        role="button"
                                        icon={faTrash}
                                        size="1x"
                                        className="deleteIcon"
                                      />
                                      {showModal ? (
                                        <DeleteModal
                                          showModal={showModal}
                                          setShowModal={setShowModal}
                                          onDelete={handleDelete}
                                          selectedItemId={selectedItemId}
                                          companyModel={companyModel}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Tooltip>
                                  </>
                                </CTableDataCell>}
                            </CTableRow > : ""}
                        </>
                      ))
                    ) : (
                      // Display a loading message or handle the case where data is not available
                      <div className="loading_data">Loading...</div>
                    )}
                  </CTableBody >
                </CTable >

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontSize: "16px", fontWeight: "600" }}>
                    Showing {indexOfFirstRow + 1} to{" "}
                    {Math?.min(indexOfLastRow, customersPendingStatus?.length)} of{" "}
                    {customersPendingStatus?.length} entries
                  </div>
                  <div className="pagination-container">
                    <ReactPaginate
                      activePage={currentPage}
                      itemsCountPerPage={rowsPerPage}
                      totalItemsCount={customersPendingStatus?.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      prevPageText="Previous"
                      nextPageText="Next"
                      firstPageText="First"
                      lastPageText="Last"
                      innerClass="pagination"
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </div >
              {showAccordion && (
                <CustomerAllDetails
                  selectedCompany={selectedCompany}
                  setShowAccordion={setShowAccordion}
                  setShowSelectAndSearch={setShowSelectAndSearch}
                />
              )}
              {
                showAddForm && (
                  <AddForm
                    selectedCompany={selectedCompany}
                    setShowAddForm={setShowAddForm}
                    setShowAddButton={setShowAddButton}
                    setShowSelectAndSearch={setShowSelectAndSearch}
                  />
                )
              }
            </CCardBody >
          </CCard >
        </CCol >
        <CCol xs={12}>
          <div
            // className={`accordion-container ${showAccordion ? "visible" : ""}`}
            // className={`accordion-container ${showAddForm ? "visible" : ""}`}
            className={`accordion-container ${showAccordion ? "visible" : ""} ${showAddForm ? "visible" : ""
              }`}
          ></div>
        </CCol>
      </CRow >
    </>
  );
};

export default Rejected;
