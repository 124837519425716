import {
  GET_ALL_STAFFS,
  ADD_STAFFS_DATA,
  EDIT_STAFFS_DATA,
  DELETE_STAFFS_BY_ID,
  UPDATE_STAFFS_BY_ID,
  STAFF_LOGIN,
  ADMINISTRAOR_LOGIN,
  ADMIN_LOGIN,
  SELECTEDROLE,
  SET_SCHEMANAME,
  CREATE_DISBURSEMENT,
  DELETE_DISBURSEMENT_BY_ID,
  GET_STAFFS_BY_ID,
  EMPTY_STORE,
} from "../action_Types";

export const getAllStaffs = () => {
  return {
    type: GET_ALL_STAFFS,
  };
};
export const staffLogin = (data) => {
  return {
    type: STAFF_LOGIN,
    payload: data
  };
};
export const setSchemaName = (data) => {
  return {
    type: SET_SCHEMANAME,
    payload: data
  };
};
export const adminstratorLogin = (data) => {

  return {
    type: ADMINISTRAOR_LOGIN,
    payload: data
  };
};
export const adminLogin = (data) => {
  return {
    type: ADMIN_LOGIN,
    payload: data
  };
};

export const addStaff = (data) => {
  return {
    type: ADD_STAFFS_DATA,
    payload: data,
  };
};

export const setUpdateFalse = () => {
  return {
    type: "SET_UPDATE"
  };
};

export const edtiStaff = (data) => {
  return {
    type: EDIT_STAFFS_DATA,
    payload: data,
  };
};

export const getStaffDetailsById = (data) => {

  return {
    type: GET_STAFFS_BY_ID,
    payload: data,
  };
};

export const deleteStaff = (id) => {
  return {
    type: DELETE_STAFFS_BY_ID,
    payload: id,
  };
};

export const updateStaff = (id) => {
  return {
    type: UPDATE_STAFFS_BY_ID,
    payload: id,
  };
};

export const selectedRoleType = (data) => {

  return {
    type: SELECTEDROLE,
    payload: data
  }
}

export const addDisbursement = (data) => {

  return {
    type: CREATE_DISBURSEMENT,
    payload: data
  }
}

export const deleteDisbursement = (data) => {
  return {
    type: DELETE_DISBURSEMENT_BY_ID,
    payload: data
  }
}

export const emptyStore = () => {
  return {
    type: EMPTY_STORE
  }
}