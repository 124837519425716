import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import AddMasterData from "./addMaster";
import ReactPaginate from "react-js-pagination";
import { useDispatch } from "react-redux";
import { getAllAccount } from "src/redux/accounting/action";
import { useSelector } from "react-redux";
const MasterList = () => {
  const dispatch = useDispatch();

  const { newAccountData, error, allAccountData } = useSelector(
    (state) => state?.acountingReducer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [visible, setVisible] = useState(false);
  const schemaName = sessionStorage.getItem("schemaName");

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = allAccountData?.data?.slice(
    indexOfFirstRow,
    indexOfLastRow
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const obj = {
      schemaName: schemaName,
    };
    dispatch(getAllAccount(obj));
  }, [newAccountData]);

  const handleAddFormMaster = () => {
    setVisible(!visible);
  };
  return (
    <div className="content">
      {<AddMasterData visible={visible} setVisible={setVisible} />}
      <CCard>
        <CCardBody>
          <CRow>
            <CCol xs={12} className="add_bt">
              <CButton onClick={() => handleAddFormMaster()}>Add</CButton>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardBody>
          <CTable color="dark" hover responsive>
            <CTableHead className="tabl-head">
              <CTableRow className="table-row">
                <CTableHeaderCell scope="col">S. No.</CTableHeaderCell>
                <CTableHeaderCell scope="col">A/C Name</CTableHeaderCell>
                <CTableHeaderCell scope="col">A/C Type</CTableHeaderCell>
                <CTableHeaderCell scope="col">A/C Entry Type</CTableHeaderCell>

                <CTableHeaderCell scope="col">Debit/Credit</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {currentRows?.map((items, id) => {
                return (
                  <CTableRow key={id}>
                    <CTableDataCell>{id + 1}</CTableDataCell>
                    <CTableDataCell>{items.accountName ?? "--"}</CTableDataCell>
                    <CTableDataCell>{items.accountType ?? "--"}</CTableDataCell>
                    <CTableDataCell>
                      {items.accountEntryType ?? "--"}
                    </CTableDataCell>
                    <CTableDataCell>{items.debitCredit ?? "--"}</CTableDataCell>
                  </CTableRow>
                );
              })}
            </CTableBody>
          </CTable>
          <div className="pagin-div">
            <div>
              Showing {indexOfFirstRow + 1} to{" "}
              {Math?.min(indexOfLastRow, allAccountData?.data?.length)} of{" "}
              {allAccountData?.data?.length} entries
            </div>
            <div className="pagination-container">
              <ReactPaginate
                activePage={currentPage}
                itemsCountPerPage={rowsPerPage}
                totalItemsCount={allAccountData?.data?.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                prevPageText="Previous"
                nextPageText="Next"
                firstPageText="First"
                lastPageText="Last"
                innerClass="pagination"
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default MasterList;
