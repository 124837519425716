import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CRow,
  CCardBody,
  CCard,
  CCardHeader,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { getLoanData, getLoanId } from "src/redux/actions/customerAction";
import { useSelector } from "react-redux";
import LoanApproveModal from "./loanApproveModal";

const LoanDetailsData = ({ customerData, emiData, setKey }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const { loanAmountRequested, id } = customerData;
  const { loans, loandetails } = useSelector((state) => state?.customerReducer);
  const [status, setStatus] = useState(false);
  const [loanAmount, setLoanAmount] = useState(0);

  const [SchemaName, setSchemaName] = useState(
    sessionStorage.getItem("schemaName")
  );

  useEffect(() => {
    setLoanAmount(loanAmountRequested || 0);
  }, [loanAmountRequested]);
  const obj = {
    customerId: id,
    schemaName: SchemaName,
  };
  const handleSetId = (id) => {
    dispatch(getLoanId(obj));
    dispatch(getLoanData(id));
    // setKey("LOANAPPROVED");
    setVisible(!visible);
    setStatus(true);
  };
  const handleNavigateDisbursement = (id) => {
    console.log(id,"id")
    dispatch(getLoanId(obj));
    dispatch(getLoanData(id));
    setKey("DISBURSEMENT");
    setStatus(true);
  };
  useEffect(() => {
    dispatch(getLoanId(obj));
  }, [loans]);
  return (
    <>
      <LoanApproveModal customerData={customerData} visible={visible} setVisible={setVisible} />
      <CRow>
        <CCol xs={12}>
          <CCardBody>
            <CContainer>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Your Loans</strong>
                </CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol xs={12}>
                      <CCard className="mb-2">
                        <div className="search_bar">
                          {/* div 1 started */}
                          <div className="search-inner">
                          
                          </div>
                        </div>
                      
                        <div className="btn_add mt-0 mb-14">
                        </div>
                      
                        <CCardBody>
                          <div>
                            <div>
                              <CTable color="dark" hover responsive>
                                <CTableHead className="tabl-head">
                                  <CTableRow className="table-row">
                                    <CTableHeaderCell scope="col">
                                      Sr No.
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Requested Amount
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Approved Amount
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Approval Date
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Status
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Total EMIs
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Action
                                    </CTableHeaderCell>
                                  </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                  {loandetails?.data?.map((datas, index) => (
                                    <CTableRow key={index} className="cursor">
                                      <CTableDataCell>
                                        {index + 1}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {datas?.requestedAmount
                                          ? datas?.requestedAmount
                                          : "--"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {datas?.approvalAmount
                                          ? datas?.approvalAmount
                                          : "--"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {datas?.approvalDate
                                          ? datas?.approvalDate.split('T')[0]
                                          : "--"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {datas?.status ? datas?.status : "--"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {datas?.numberOfEmis
                                          ? datas?.numberOfEmis
                                          : "--"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {datas?.status === "pending" &&
                                          datas?.disbursements?.length ===
                                          0 && (
                                            <CButton
                                              onClick={() => handleSetId(datas)}
                                            >
                                              Approve
                                            </CButton>
                                          )}
                                        {datas?.status === "open" &&
                                          datas?.disbursements?.length ===
                                          0 && (
                                            <CButton
                                              onClick={() =>
                                                handleNavigateDisbursement(
                                                  datas
                                                )
                                              }
                                            >
                                              Disbursement
                                            </CButton>
                                          )}
                                      </CTableDataCell>
                                    </CTableRow>
                                  ))}
                                </CTableBody>
                              </CTable>
                            </div>

                           
                          </div>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CContainer>
          </CCardBody>
        </CCol>
      </CRow>
    </>
  );
};

LoanDetailsData.propTypes = {
  customerData: PropTypes.object.isRequired,
  emiData: PropTypes.array.isRequired,
  setKey: PropTypes.func.isRequired,
};

export default LoanDetailsData;
