import {
    GET_ALL_INTERESTS,
    ADD_INTERESTS_DATA,
    DELETE_INTERESTS_BY_ID,
    UPDATE_INTERESTS_BY_ID,
    EDIT_INTERESTS_DATA,
    REFRESH_INTERESTLIST,
  } from "../action_Types";
  
  export const getAllInterests = (data) => {
    return {
      type: GET_ALL_INTERESTS,
      payload:data,
    };
  };
  export const addInterests = (data) => {
    return {
      type: ADD_INTERESTS_DATA,
      payload: data,
    };
  };

  export const refreshInterestList = (data) => {
    return {
      type: REFRESH_INTERESTLIST,
      payload: data,
    };
  };


  export const editIntereset = (data) => {
    return {
      type: EDIT_INTERESTS_DATA,
      payload: data,
    };
  };

  export const deleteInterests = (id) => {
    return {
      type: DELETE_INTERESTS_BY_ID,
      payload: id,
    };
  };
  
  export const updateInterests = (id) => {
    return {
      type: UPDATE_INTERESTS_BY_ID,
      payload: id,
    };
  };
  