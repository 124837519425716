import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CRow,
  CCardBody,
} from "@coreui/react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { uploadSanctionLetter } from "src/redux/actions/customerAction";
import { useNavigate } from "react-router-dom";
import PrintSantionLetterForm from "./CustomerProfile/SanctionLetter/printSanctionLetterForm";
import { useReactToPrint } from "react-to-print";
const initialValues = {
  sanctionLetterImageUrl: null,
};
const SantialLetterUpload = ({ customerData, emiData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [printSantialLetter, setPrintSantialLetter] = useState(false);
  const [custData, setCustData] = useState([]);
  const [emiiData, setEmiiData] = useState([]);

  const santialRef = useRef();
  const [SchemaName, setSchemaName] = useState(
    sessionStorage.getItem("schemaName")
  );
  const [identification, setIdentification] = useState(initialValues);

  const handleSubmit = (event) => {
    event.preventDefault();

    const currentDate = new Date().toISOString().split("T")[0];
    let formData = new FormData();
    formData.append("schemaName", SchemaName);
    formData.append("isUploaded", true);
    formData.append("customerId", customerData?.id);
    formData.append("sanctionLetterDate", currentDate);
    formData.append("sanctionLetterImageUrl", santialRef?.current?.files[0]);
    dispatch(uploadSanctionLetter(formData));
    const emiCreate = {
      schemaName: SchemaName,
      loadAmount: customerData?.loanAmountRequested,
      loadDurationInMonths: customerData?.loanTerm,
      annualInterestRate: 8,
      customerId: customerData?.id,
    };
    // dispatch(createEmi(emiCreate));
    setStatus(true);
  };
  useEffect(() => {
    if (status === true) {
      //   navigate("/printdisburshment");
    }
  }, [status]);
  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setIdentification({ ...identification, [name]: value });
  };

  const componentRef = React.useRef();

  const handlePrintSantialLetter = (customerData, emiData) => {
    setCustData(customerData);
    setEmiiData(emiData);
    setPrintSantialLetter(true);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      {printSantialLetter ? (
        <PrintSantionLetterForm
          custData={custData}
          emiiData={emiiData}
          setPrintSantialLetter={setPrintSantialLetter}
        />
      ) : (
        <CRow>
          <CCol xs={12}>
            <CCardBody>
              <CContainer>
                <CForm
                  onSubmit={(event) => handleSubmit(event, isEdit)}
                  autoComplete="off"
                >
                  <div className="staffform_header"></div>
                  <div
                    className="download_Santialletter"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <CButton
                      onClick={() =>
                        handlePrintSantialLetter(customerData, emiData)
                      }
      
                      color="primary"
                      className="px-4"
                    >
                      DownLoad Sanction Letter
                    </CButton>
                    <CButton
                      onClick={() =>
                        handlePrintSantialLetter(customerData, emiData)
                      }
                
                      color="primary"
                      className="px-4"
                    >
                      Upload Sanction Letter
                    </CButton>
                  </div>
                </CForm>
              </CContainer>
            </CCardBody>
          </CCol>
        </CRow>
      )}
    </>
  );
};

SantialLetterUpload.propTypes = {
  customerData: PropTypes.object.isRequired,
  emiData: PropTypes.array.isRequired,
};

export default SantialLetterUpload;
