import React, { useEffect, useState } from "react";
import {
  CButton,
  CFormInput,
  CRow,
  CCard,
  CCol,
  CFormLabel,
  CFormSelect,
  CCardHeader,
  CCardBody,
  CForm,
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createGroup } from "src/redux/actions/userAction";
import { successAlert } from "src/utils/Swal";

const AddGroupForm = (event) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Branches } = useSelector((state) => state?.customerReducer);
  const { groupCreated } = useSelector((state) => state?.userReducer);
  const [SchemaName, setSchemaName] = useState(
    sessionStorage.getItem("schemaName")
  );
  const [selectedBranchId, setSelectedBranchId] = useState(null);

  const initialFormData = {
    groupName: "",
    groupCode: "",
    groupHeadName: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const { groupName, groupCode, groupHeadName } = formData;
  const obj = {
    schemaName: SchemaName,
    companyId: 1,
    groupName: groupName,
    groupCode: groupCode,
    groupHeadName: groupHeadName,
    branchId: selectedBranchId,
  };

  const handleAddForm = (e) => {
    e.preventDefault();
    dispatch(createGroup(obj));
    successAlert("Success", "New Group SuccessFully Created  ");
    navigate("/group/grouplist");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "groupName" || name === "groupHeadName") {
      const sanitizedValue = value.replace(/[^A-Za-z]/gi, "");
      setFormData({
        ...formData,
        [name]: sanitizedValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedBranchId(selectedId);
  };
  return (
    <>
      <CRow className="content">
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Add Group Details</strong>
          </CCardHeader>
          <CCol xs={12}>
            <CCardBody>
              <CForm onSubmit={handleAddForm}>
                <CCard className="mb-4">
                  <CRow className="row_margin">
                    <CCol xs={6}>
                      <div className="mb-4">
                        <CFormLabel htmlFor="groupName">
                          Group Name
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          placeholder="Group Name"
                          id="groupName"
                          name="groupName"
                          value={groupName}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </CCol>

                    <CCol xs={6}>
                      <div className="mb-4">
                        <CFormLabel htmlFor="groupCode">
                          Group Code
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="groupCode"
                          placeholder="Group Code"
                          name="groupCode"
                          value={groupCode}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </CCol>

                    <CCol xs={6}>
                      <div className="mb-4">
                        <CFormLabel htmlFor="Group Head Name">
                          Group Head Name
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          placeholder="Group Head Name"
                          id="groupHeadName"
                          name="groupHeadName"
                          value={groupHeadName}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </CCol>

                    <CCol xs={6}>
                      <div className="mb-4">
                        <CFormLabel htmlFor="Branches">
                          Branches
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormSelect
                          id="Branches"
                          name="userName"
                          onChange={handleSelectChange}
                          required
                        >
                          <option value="">Select Branch</option>

                          {Branches?.data?.map((branch) => (
                            <option key={branch.id} value={branch.id}>
                              {branch.branchName}
                            </option>
                          ))}
                        </CFormSelect>
                      </div>
                    </CCol>
                  </CRow>
                  <div className="branch_btn">
                    <CButton type="submit">Submit</CButton>
                  </div>
                </CCard>
              </CForm>
            </CCardBody>
          </CCol>
        </CCard>
      </CRow>
    </>
  );
};

export default AddGroupForm;
