import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "react-bootstrap";
import RegistrationForm from "../customerData/registrationForm";
import IdentiFicationForm from "../customerData/identificationForm";
import BankDetails from "../customerData/bankDetails";
import Income from "../customerData/income";
import NomineeGroup from "../customerData/nomineeForm";
import AllRefrence from "../customerData/refrence";
import Nomniee from "../customerData/nominee";
import Adress from "../customerData/address";
import "./addForm.css";
import { refreshPage } from "src/redux/actions/customerAction";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { addCustomer, updateCustomer } from "src/api/allApis";
import { errorAlert, successAlert } from "src/utils/Swal";
import { json, useNavigate } from "react-router-dom";
import AadharPanVerify from "./aadharPanVerify";
import {
  aadharNumber,
  aadharOtpVerification,
  aadharVerification,
  resetVerification,
  restver,
} from "src/redux/actions/companyAction";
import { getInitialRegistrationData } from "./helper";

const AddForm = ({
  setShowCustomerAddForm,
  callBack,
  showCustomerAddForm,
  groupId,
  customerData,
  getAllCompanyData
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    ere,
    errordata,
    errVe,
    error,
    verifiedData,
    verifiedMessage,
    otpVerifiedData,
    adharNumber: aaddhar_number
  } = useSelector((state) => state?.companyReducer);
  const { Branches } = useSelector((state) => state?.customerReducer);
  const { branchData } = useSelector((state) => state?.staffReducer);
  const [key, setKey] = useState("home");
  const [showTabs, setShowTabs] = useState(false);
  const [showPan, setShowPan] = useState(false);
  const [aadhar, setAadhar] = useState();
  const [aadharOtp, setAadharOtp] = useState();
  const [showPanOtp, setShowPanOtp] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const SchemaName = sessionStorage.getItem("schemaName");

  const [employee, setEmployee] = useState(sessionStorage.getItem("emplyeeId"));

  useEffect(() => {
    const obj = {
      id: employee,
      schemaName: SchemaName,
    };
    // dispatch(getStaffDataById(obj));
  }, []);
  const branchid = JSON.parse(sessionStorage.getItem("branc"));

  const [selectedBranchId, setSelectedBranchId] = useState(branchid || customerData?.branch?.id);
  const initialFormData = {
    registration: {},
    address: {},
    identiFication: {},
    bankDetails: {},
    income: {},
    references: {},
    Nomniee: {},
  };
  const [formData, setFormData] = useState(() => {
    if (!_.isEmpty(customerData)) {
      return {
        registration: getInitialRegistrationData(customerData),
        Nomniee: JSON.parse(customerData?.nominee) || {},
        address: JSON.parse(customerData?.address) || {},
        bankDetails: {
          accountNumber: customerData?.bankAccountNo || "",
          bankName: customerData?.bankName || "",
          bankStatementsImageUrl: customerData?.bankStatementsImageUrl || "",
          ifsc: customerData?.ifscCode || "",
          creditScore: customerData?.creditScore || "",
        },
        identiFication: {
          aadharNumber: customerData?.aadharNumber,
          PanNumber: customerData?.PanNumber,
          singnatureImageUrl: customerData?.singnatureImageUrl,
          panImageUrl: customerData?.panImageUrl,
          aadharImageUrl: customerData?.aadharImageUrl,
          passportSizePhoto: customerData?.passportSizePhoto,
          paySlipImageUrl: customerData?.paySlipImageUrl
        },
        references: JSON.parse(customerData?.customerreference) || {},
        income: {
          monthlyExpenses: customerData?.monthlyExpenses || "",
          income: customerData?.income || "",
        },

      }
    } else {
      return initialFormData
    }
  });
  const updateFormData = (formName, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: data,
    }));
  };

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedBranchId(selectedId);
  };

  const handleFormSubmit = async (e) => {
    if (!selectedBranchId) {
      alert('Please select a Branch');
      return
    }
    // e.preventDefault();
    const {
      firstName,
      middleName,
      lastName,
      dob,
      martialStatus,
      email,
      phoneNumber,
      occupation,
      empStartDate,
      loanAmountRequsted,
      gender,
      fatherName,
      loanType,
      loanTerm,
    } = formData.registration;

    const customerAddress = formData.address;

    const {
      bankName,
      ifsc,
      accountNumber,
      bankStatementsImageUrl,
      creditScore,
    } = formData.bankDetails;

    const {
      GuarantorName,
      GuarantorName1,
      aadhar,
      aadhar1,
      address,
      address1,
      fathersName,
      fathersName1,
      mobileNumber,
      mobileNumber1,
      pan,
      pan1,
    } = formData.Nomniee;

    const {
      aadharNumber,
      PanNumber,
      singnatureImageUrl,
      panImageUrl,
      aadharImageUrl,
      passportSizePhoto,
      paySlipImageUrl,
    } = formData.identiFication;

    const { income, monthlyExpenses } = formData.income;

    const cusReference = formData.references;
    const _Nomniee = formData?.Nomniee;

    try {
      const AddCustomerData = new FormData();
      AddCustomerData.append("schemaName", SchemaName);
      AddCustomerData.append("employeeId", 1);
      AddCustomerData.append("firstName", firstName);
      AddCustomerData.append("middleName", middleName || "");
      AddCustomerData.append("lastName", lastName);
      AddCustomerData.append("email", email);
      AddCustomerData.append("phoneNumber", phoneNumber);
      AddCustomerData.append("dob", dob);
      AddCustomerData.append("fatherName", fatherName);
      AddCustomerData.append("gender", gender);
      AddCustomerData.append("martialStatus", martialStatus);
      AddCustomerData.append("PanNumber", PanNumber);
      AddCustomerData.append("bankName", bankName);
      AddCustomerData.append("bankAccountNo", accountNumber);
      AddCustomerData.append("ifscCode", ifsc);
      AddCustomerData.append("creditScore", creditScore);
      AddCustomerData.append("income", income);
      AddCustomerData.append("monthlyExpenses", monthlyExpenses);
      AddCustomerData.append("occupation", occupation);
      AddCustomerData.append("empStartDate", empStartDate);
      AddCustomerData.append("branchId", selectedBranchId);
      AddCustomerData.append("address", JSON.stringify(customerAddress));
      AddCustomerData.append("nominee", JSON.stringify(_Nomniee));
      AddCustomerData.append("customerreference", JSON.stringify(cusReference));
      if (paySlipImageUrl) AddCustomerData.append("paySlipImageUrl", paySlipImageUrl);
      if (aadharImageUrl) AddCustomerData.append("aadharImageUrl", aadharImageUrl);
      if (panImageUrl) AddCustomerData.append("panImageUrl", panImageUrl);
      if (bankStatementsImageUrl) AddCustomerData.append("bankStatementsImageUrl", bankStatementsImageUrl);
      if (passportSizePhoto) AddCustomerData.append("passportSizePhoto", passportSizePhoto);
      if (singnatureImageUrl) AddCustomerData.append("singnatureImageUrl", singnatureImageUrl);
      if (customerData?.id) {
        AddCustomerData.append("aadharNumber", customerData?.aadharNumber);
        AddCustomerData.append("groupid", customerData?.groupid);
      } else {
        AddCustomerData.append("aadharNumber", aadharNumber||aaddhar_number);
        AddCustomerData.append("interestId", loanType);
        AddCustomerData.append("loanAmountRequested", loanAmountRequsted);
        AddCustomerData.append("loanTerm", loanTerm);
        groupId !== undefined
        ? AddCustomerData.append("groupid", groupId)
        : AddCustomerData.append("groupid", 0);
      }

      const saveApiCall = customerData?.id ? updateCustomer : addCustomer;
      const payload = customerData?.id ? { data: AddCustomerData, id: customerData?.id } : AddCustomerData
      saveApiCall(payload).then((response) => {
        if (response === undefined) {
          errorAlert("failed", "Some-thing Went Wrong ");
          return;
        } else if (response.status === "success") {
          successAlert("Success", "Customer Successfully Added For Loan");
          navigate("/customer-profile");
          if(customerData?.id) getAllCompanyData();
          dispatch(refreshPage());
          setShowCustomerAddForm(false);
        }
      });
      callBack && callBack();
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowAddButton = () => {
    setShowCustomerAddForm(false);
  };
  const role = sessionStorage.getItem("role");
  const branchId = JSON.parse(sessionStorage.getItem("branc"));

  const handleAadharVerify = (e) => {
    e.preventDefault();
    if (!aadhar || aadhar.length < 12) {
      return alert("Please Enter Valid Aadhaar Number");
    } else {
      dispatch(aadharVerification({ aadharNumber: aadhar }));
      // setShowPan(true);
    }
  };

  useEffect(() => {
    if (ere === false) {
      if (verifiedData?.data?.message === "OTP sent successfully") {
        successAlert("Success", "OTP Sent Successfully");
        dispatch(restver());
        setShowPan(true);
      }
    } else if (ere === true) {
      let errMessage = " Please enter a valid aadhar number";
      if (errordata?.response?.status === 403 || errordata?.response?.data?.status === 403) errMessage = "Your Aadhar Tokens Have Expired"
      if (errordata?.response?.status === 409 || errordata?.response?.data?.status === 409) errMessage = "Aaddhar Number Already Exist."
      errorAlert("Error", errMessage);
      dispatch(restver());
    }
  }, [dispatch, ere, errordata, verifiedData]);

  const handleOtpVerify = (e) => {
    e.preventDefault();
    if (!aadharOtp) {
      return alert("Please Enter OTP");
    } else {
      const obj = {
        otp: aadharOtp,
        ref_id: verifiedMessage?.data?.ref_id,
      };
      dispatch(aadharOtpVerification(obj));
    }
    setShowTabs(true);
    // setShowPan(false);
    // setShowForm(false);
  };
  const handlePanVerify = (e) => {
    e.preventDefault();
    setShowPanOtp(true);
  };
  const handlePanOtpVerify = (e) => {
    e.preventDefault();
    setShowForm(false);
  };

  useEffect(() => {
    if (error === false) {
      if (otpVerifiedData?.status === "success") {
        successAlert("Success", "You Are Verified");
        dispatch(resetVerification());
        dispatch(aadharNumber(aadhar));
        setAadharOtp("");
        // setAadhar("");

        setShowForm(false);
      } else if (errVe === true) {
        errorAlert("Failed", "Please Enter Valid otp");
        dispatch(restver());

        setAadharOtp("");
      }
    }
  }, [otpVerifiedData, error, errVe]);

  return (
    <>
      <CRow className={customerData?.id ? "" : "content"}>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Customer</strong>
            </CCardHeader>
            <CCardBody>
              {(showForm && !customerData?.id) ? (
                <AadharPanVerify
                  showTabs={showTabs}
                  showPan={showPan}
                  showPanOtp={showPanOtp}
                  handlePanOtpVerify={handlePanOtpVerify}
                  handlePanVerify={handlePanVerify}
                  handleOtpVerify={handleOtpVerify}
                  setAadhar={setAadhar}
                  setAadharOtp={setAadharOtp}
                  aadharOtp={aadharOtp}
                  aadhar={aadhar}
                  handleAadharVerify={handleAadharVerify}
                />
              ) : (
                <div>
                  <div className="close_btn">
                    {role === "manager" ? (
                      <CRow>
                        <CCol xs={5}>
                          <CFormLabel>Branch Name :</CFormLabel>
                        </CCol>
                        <CCol xs={6}>
                          <CFormInput
                            type="text"
                            value={
                              Branches?.data?.find(
                                (branch) => branch?.id === branchId
                              )?.branchName
                            }
                            disabled
                          />
                        </CCol>
                      </CRow>
                    ) : (
                      <CCol>
                        <CFormLabel>
                          Select Branch
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>

                        <CFormSelect
                          id="branchName"
                          name="branchName"
                          className="w_12"
                          onChange={handleSelectChange}
                          value={selectedBranchId}
                          required
                        >
                          <option value="">Select Branch</option>

                          {Branches?.data?.map((branch) => (
                            <option key={branch.id} value={branch.id}>
                              {branch.branchName}
                            </option>
                          ))}
                        </CFormSelect>
                      </CCol>
                    )}

                    {showCustomerAddForm && (
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => handleShowAddButton()}
                      ></button>
                    )}
                  </div>
                  <div style={{ height: "20px" }}> {"  "}</div>
                  <CRow>
                    <CCol xs={12}>
                      <CCard className="mb-2">
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key}
                          onSelect={(k) => setKey(k)}
                          className="mb-3"
                        >
                          <Tab eventKey="home" title="Main">
                            {RegistrationForm && (
                              <RegistrationForm
                                formData={formData.registration}
                                customer={customerData}
                                updateFormData={updateFormData}
                                setKey={setKey}
                                key={key}
                              />
                            )}
                          </Tab>
                          <Tab
                            eventKey="address"
                            title="Address"
                            disabled={_.isEmpty(formData.registration)}
                          >
                            {Adress && (
                              <Adress
                                formData={formData.address}
                                updateFormData={updateFormData}
                                setKey={setKey}
                                key={key}
                              />
                            )}
                          </Tab>
                          <Tab
                            eventKey="Identification"
                            title="User Identification"
                            disabled={_.isEmpty(formData.address)}
                          >
                            {IdentiFicationForm && (
                              <IdentiFicationForm
                                // aadharNumber={aadharNumber}
                                // PanNumber={PanNumber}
                                formData={formData.identiFication}
                                customerData={customerData}
                                isUpdateForm={customerData?.id ? true : false}
                                updateFormData={updateFormData}
                                setKey={setKey}
                                key={key}
                              />
                            )}
                          </Tab>
                          <Tab
                            eventKey="Location"
                            title="Bank Details & Location"
                            disabled={_.isEmpty(formData.identiFication)}
                          >
                            {BankDetails && (
                              <BankDetails
                                formData={formData.bankDetails}
                                updateFormData={updateFormData}
                                isUpdateForm={customerData?.id ? true : false}
                                setKey={setKey}
                                key={key}
                              />
                            )}
                          </Tab>
                          <Tab
                            eventKey="Income"
                            title="Income"
                            disabled={_.isEmpty(formData.bankDetails)}
                          >
                            {Income && (
                              <Income
                                formData={formData.income}
                                updateFormData={updateFormData}
                                setKey={setKey}
                                key={key}
                              />
                            )}
                          </Tab>
                          <Tab
                            eventKey="Nomniee"
                            title="Guarantor"
                            disabled={_.isEmpty(formData.income)}
                          >
                            {Nomniee && (
                              <Nomniee
                                formData={formData.Nomniee}
                                updateFormData={updateFormData}
                                setKey={setKey}
                                key={key}
                              />
                            )}
                          </Tab>
                          <Tab
                            eventKey="Reference"
                            title="Reference"
                            disabled={_.isEmpty(formData.Nomniee)}
                          >
                            {AllRefrence && (
                              <AllRefrence
                                formData={formData.references}
                                updateFormData={updateFormData}
                                setKey={setKey}
                                key={key}
                              />
                            )}
                          </Tab>
                          <Tab
                            eventKey="Nominee"
                            title="Final Submit"
                            disabled={_.isEmpty(formData.references)}
                          >
                            {NomineeGroup && (
                              <NomineeGroup
                                handleFormSubmit={(event) =>
                                  handleFormSubmit(event)
                                }
                                formdata={formData}
                              />
                            )}
                          </Tab>
                        </Tabs>
                      </CCard>
                    </CCol>
                  </CRow>
                </div>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
AddForm.propTypes = {
  setShowCustomerAddForm: PropTypes.func,
  showCustomerAddForm: PropTypes.bool,
  selectedCompany: PropTypes.object,
  customerData: PropTypes.object,
  groupId: PropTypes.number,
  callBack: PropTypes.func,
  getAllCompanyData: PropTypes.func
};

export default AddForm;
