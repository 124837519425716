import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";

// routes config
import routes from "../navigations/AppRoutes";

const AppContent = () => {
  let userData = sessionStorage.getItem("token");

  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        {userData ? (
          <Routes>
            {routes.map((route, idx) => {
              return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                )
              );
            })}
            <Route path="/" element={<Navigate to="login" replace />} />
          </Routes>
        ) : (
          ""
        )}
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
