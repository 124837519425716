import {
  ADD_PLAN_DATA,
  DELETE_PLAN_BY_ID,
  GET_ALL_PLAN,
  SET_ROUTE_VALUE,
  UPDATE_PLAN_BY_ID,
} from "../action_Types";

export const getAllPlan = () => {
  return {
    type: GET_ALL_PLAN,
  };
};
export const addPlan = (data) => {


  return {
    type: ADD_PLAN_DATA,
    payload: data,
  };
};
export const deletePlan = (id) => {
  return {
    type: DELETE_PLAN_BY_ID,
    payload: id,
  };
};

export const setSidebarVisibility = (visible) => {
  return {
    type: "set",
    sidebarShow: visible,
  };
};
export const setSidebarRouteValue = (data) => {
  return {
    type: SET_ROUTE_VALUE,
    payload: data,
  };
};
export const updatePlan = (data) => {
  return {
    type: UPDATE_PLAN_BY_ID,
    payload: data,
  };
};