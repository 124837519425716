import {
  ADD_ACCOUNT,
  ADD_CONTRA_ENTRIES,
  ADD_JOURNAL_ENTRIES,
  GET_ALL_ACCOUNT,
  GET_ALL_CONTRA_ENTRIES,
  GET_ALL_JOURNAL_ENTRIES,
  GET_ALL_LEDGER,
  GET_ALL_OPENING,
  GET_ALL_TRADING,
} from "../action_Types";

export const getAllEntries = (data) => {
  return {
    type: GET_ALL_JOURNAL_ENTRIES,
    payload: data,
  };
};

export const addEntries = (data) => {
  return {
    type: ADD_JOURNAL_ENTRIES,
    payload: data,
  };
};

export const addAccount = (data) => {
  return {
    type: ADD_ACCOUNT,
    payload: data,
  };
};

export const getAllAccount = (data) => {
  return {
    type: GET_ALL_ACCOUNT,
    payload: data,
  };
};

export const addContraEntries = (data) => {
  return {
    type: ADD_CONTRA_ENTRIES,
  };
};

export const getAllContraEntries = (data) => {
  return {
    type: GET_ALL_CONTRA_ENTRIES,
  };
};

export const getAllLedger = (data) => {
  return {
    type: GET_ALL_LEDGER,
    payload : data
  };
};

export const getOpeningBalance= (data) => {
  return {
    type: GET_ALL_OPENING,
    payload : data
  };
};

export const getTrading= (data) => {
  return {
    type: GET_ALL_TRADING,
    payload : data
  };
};
