import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { addEntries, getAllAccount } from "src/redux/accounting/action";
import { useSelector } from "react-redux";
import { successAlert } from "src/utils/Swal";

const RecieptEntryForm = () => {
  const dispatch = useDispatch();
  const { newJournalData, allAccountData, error } = useSelector(
    (state) => state?.acountingReducer
  );

  const branchId = JSON.parse(sessionStorage.getItem("branc"));
  const schema = sessionStorage.getItem("schemaName");
  const [values, setValues] = useState();
  const [selectedDebitAccount, setSelectedDebitAccount] = useState({
    id: "",
    accountName: "",
  });
  const [selectedCreditAccount, setSelectedCreditAccount] = useState({
    id: "",
    accountName: "",
  });

  const setDebitAccountValue = (e) => {
    const selectedValue = e.target.value;

    const selectedAccount = allAccountData?.data?.find(
      (item) => item?.accountName === selectedValue
    ) || { id: "", accountName: "" };
    setSelectedDebitAccount(selectedAccount);
  };

  const setCreditAccountValue = (e) => {
    const selectedValue = e.target.value;
    const selectedAccount = allAccountData?.data?.find(
      (item) => item?.accountName === selectedValue
    ) || { id: "", accountName: "" };
    setSelectedCreditAccount(selectedAccount);
  };
  const [showData, setShowData] = useState(false);
  const initialValues = {
    debitAmount: 0,
    creditAmount: 0,
    narration: "",
    entryDate: "",
    schemaName: schema,
    branchId: branchId,
  };

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    const obj = {
      schemaName: schema,
    };
    dispatch(getAllAccount(obj));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevData) => ({ ...prevData, [name]: value }));
  };

  const { entryDate, debitAmount, creditAmount, narration } = formValues;
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...formValues,
      debitAccount: selectedDebitAccount?.accountName,
      creditAccount: selectedCreditAccount?.accountName,
      debitAccountId: selectedDebitAccount?.id,
      creditAccountId: selectedCreditAccount?.id,
    };
    dispatch(addEntries(payload));
    setFormValues("");
    setShowData(true);
  };

  useEffect(() => {
    if (error === false) {
      if (newJournalData?.status === "success") {
        setValues(newJournalData);
      }
    }
  }, [newJournalData]);

  return (
    <div className="content">
      <CCard>
        <CCardHeader>
          <strong>Add Journal</strong>
        </CCardHeader>
        <CCardBody>
          <CCard>
            <CCardBody>
              <CForm onSubmit={(e) => handleSubmit(e)}>
                <CRow className="ad_row">
                  <CCol xs={5}>
                    <CFormLabel>Select Date</CFormLabel>
                    <CFormInput
                      type="date"
                      placeholder="Select A Date"
                      value={entryDate}
                      name="entryDate"
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow className="ad_row">
                  <CCol xs={5}>
                    <CFormLabel>Enter Account</CFormLabel>
                    <CFormSelect
                      type="text"
                      placeholder="Enter Account Name"
                      required
                      value={selectedDebitAccount.accountName}
                      onChange={(e) => setDebitAccountValue(e)}
                    >
                      <option value="">Select Accounts</option>
                      {allAccountData?.data
                        ?.filter((ele) => ele?.debitCredit === "debit")
                        ?.map((item, i) => (
                          <option key={i} value={item.accountName}>
                            {item?.accountName?.toUpperCase()}
                          </option>
                        ))}
                    </CFormSelect>
                  </CCol>
                  <CCol xs={2} className="centr_align">
                    <CFormLabel>DR</CFormLabel>
                  </CCol>
                  <CCol xs={5}>
                    <CFormLabel>Enter Amount</CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Enter Amount"
                      required
                      name="debitAmount"
                      value={debitAmount}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow className="ad_row">
                  <CCol xs={5}>
                    <CFormLabel>Enter Account</CFormLabel>
                    <CFormSelect
                      type="text"
                      placeholder="Enter Account Name"
                      required
                      value={selectedCreditAccount?.accountName}
                      onChange={(e) => setCreditAccountValue(e)}
                    >
                      <option value="">Select Accounts</option>
                      {allAccountData?.data
                        ?.filter((ele) => ele?.debitCredit === "credit")
                        ?.map((item, i) => (
                          <option key={i} value={item?.accountName}>
                            {item?.accountName?.toUpperCase()}
                          </option>
                        ))}
                    </CFormSelect>
                  </CCol>
                  <CCol xs={2} className="centr_align">
                    <div>CR</div>
                  </CCol>
                  <CCol xs={5}>
                    <CFormLabel>Enter Amount</CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Enter Amount"
                      required
                      name="creditAmount"
                      value={creditAmount}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCardBody>
                    <CRow>
                      <CCol xs={10}>
                        <CFormLabel>Enter Narration</CFormLabel>
                        <CFormTextarea
                          type="text"
                          placeholder="Enter Narration"
                          value={narration}
                          name="narration"
                          onChange={handleChange}
                          required
                        />
                      </CCol>
                      <CCol xs={2} className="centr_align">
                        <CButton type="submit">Submit</CButton>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCardBody>
        {showData && (
          <CCard>
            <CCardBody>
              <CTable borderless>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="col-md-5">
                      Particulars
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">J.F</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Amount(Dr.)</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Amount(Cr.)</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell>{values?.data?.entryDate}</CTableDataCell>
                    <CTableDataCell>
                      <div className="entry_data">
                        <div className="draw">
                          <span>{values?.data?.debitAccount} A/c</span>
                          <span>Dr.</span>
                        </div>
                        <div className="to_cash">
                          <span>To </span>
                          <span> {values?.data?.creditAccount} A/c</span>
                        </div>
                        {/* <div className="to_cash">
                        <span>To </span>
                        <span> Purchases A/c</span>
                      </div> */}
                        <div>
                          <span>({values?.data?.narration})</span>
                        </div>
                      </div>
                    </CTableDataCell>
                    <CTableDataCell></CTableDataCell>
                    <CTableDataCell>
                      <div className="entry_data">
                        <div className="draw"></div>
                        <div className="to_pur">
                          <span>{values?.data?.debitAmount}</span>
                        </div>
                      </div>
                    </CTableDataCell>
                    <CTableDataCell>
                      <div className="entry_data">
                        <div className="draw"></div>

                        <div className="to_cas">
                          <span> {values?.data?.creditAmount}</span>
                        </div>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        )}
      </CCard>
    </div>
  );
};

export default RecieptEntryForm;
