import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-js-pagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllEmiByCustomerId,
  setCustomerId,
} from "src/redux/actions/emiAction";
import { getAllCustomers } from "src/api/allApis";
import { useReactToPrint } from "react-to-print";
import Vakilkaro from "src/assets/brand/vakilkaro";
import LoanEmiDetailsData from "./loanEmiDetails";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "src/services/const";

const EmiRecords = () => {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { emiData } = useSelector((state) => state?.emiReducer);
  const [allCustomerData, setAllCustomerData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const SchemaName = sessionStorage.getItem("schemaName");
  const [getAllEmis, setGetAllEmis] = useState(null);
  const [loanAccountNumber, setLoanAccountNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showEmiDetails, setShowEmiDetails] = useState(true);
  const [lastName, setLastName] = useState("");
  const componentPDF = useRef();
  const [printState, setPrintState] = useState(false);
  const [loanId, setLoanId] = useState("");

  useEffect(() => {
    getAllCompanyData();
  }, []);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const getAllCompanyData = () => {
    const payload = { schemaName: SchemaName };
    getAllCustomers(payload).then((res) => {
      setAllCustomerData(res?.data);
    });
  };

  const download_btn = () => {
    setPrintState(true);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const rowsPerPage = 10;

  const filteredEmis = getAllEmis?.filter((emi) => {
    if (selectedStatus === "all") return true;
    return emi?.emiStatus === selectedStatus;
  }).sort((item, item_) => {
    return new Date(item.dueDate) - new Date(item_.dueDate);
  });

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredEmis?.slice(indexOfFirstRow, indexOfLastRow);

  const handleSearch = () => {
    const filteredCustomer = allCustomerData?.filter(
      (customer) =>
        customer?.loanAccountNumber === String(loanAccountNumber) &&
        customer?.firstName?.toLowerCase() === firstName?.toLowerCase() &&
        customer?.lastName?.toLowerCase() === lastName?.toLowerCase()
    );

    if (filteredCustomer?.length > 0) {
      setCustomerData(filteredCustomer[0]);
      const obj = {
        schemaName: SchemaName,
        customerId: filteredCustomer[0]?.id,
      };
      console.log(filteredCustomer, "filtered emi record")
      console.log(obj, "emi rec")
      const customer = filteredCustomer[0];
      dispatch(setCustomerId(customer));
      dispatch(getAllEmiByCustomerId(obj));
      setShowDetails(true);
      setShowTable(true);
    } else {
      setCustomerData([]);
    }
  };

  return (
    <div className="content">
      <CCard>
        <CCardHeader>
          <div className=" flex justify-between">
            <strong>EMI Record</strong>
            <div>
              <CButton className="add-btn mr-10 w-auto" onClick={download_btn}>
                Download
              </CButton>
              {currentRows && (
                <CSVLink data={currentRows === undefined ? " " : currentRows} filename={"emiRecord-table.csv"}>
                  <CButton className="add-btn w-auto m-0">
                    CSV
                  </CButton>
                </CSVLink>
              )}
              {showEmiDetails === false ? (
                <CButton
                  className="add-btn m-0 w-auto"
                  onClick={() => setShowEmiDetails(true)}
                >
                  Back
                </CButton>
              ) : (
                ""
              )}
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <div>
            <CRow>
              <CCol xs={2}>
                <CFormInput
                  placeholder="Enter your Loan Account"
                  onChange={(e) => setLoanAccountNumber(e.target.value)}
                />
              </CCol>
              <CCol xs={2}>
                <CFormInput
                  placeholder="Enter your First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </CCol>
              <CCol xs={2}>
                <CFormInput
                  placeholder="Enter your Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </CCol>
              <CCol xs={2}>
                <CButton onClick={handleSearch}>Search</CButton>
              </CCol>
              {showEmiDetails === false ? (
                <CCol xs={2}>
                  <CFormSelect
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <option value="">Select EMI Status</option>
                    <option value="all">All</option>
                    <option value="paid">Paid</option>
                    <option value="pending">Pending</option>
                  </CFormSelect>
                </CCol>
              ) : (
                ""
              )}
            </CRow>
          </div>
          <div className="serch_bar"></div>

          {showDetails && (
            <div className="customer_details">
              <CRow>
                <CCol xs={4}>
                  <span className="lable-name">Full Name : </span>
                  <span className="content-name">{`${customerData?.firstName} ${customerData?.lastName}`}</span>
                </CCol>
                <CCol xs={4}>
                  <span className="lable-name">Aadhar Number : </span>
                  <span className="content-name">
                    {customerData?.aadharNumber}
                  </span>
                </CCol>
                <CCol xs={4}></CCol>
              </CRow>
              <CRow>
                <CCol xs={4}>
                  <span className="lable-name"> Email : </span>
                  <span className="content-name">{customerData?.email}</span>
                </CCol>
                <CCol xs={4}>
                  <span className="lable-name">Pan Number : </span>
                  <span className="content-name">
                    {customerData?.PanNumber}
                  </span>
                </CCol>
                <CCol xs={4}></CCol>
              </CRow>
              <CRow>
                <CCol xs={4}>
                  <span className="lable-name">Loan Account Number : </span>
                  <span className="content-name">
                    {customerData?.loanAccountNumber}
                  </span>
                </CCol>
              </CRow>
            </div>
          )}
          <div>
            <div
              ref={printState ? componentPDF : null}
              style={{ padding: printState ? "20px" : "0px" }}
            >
              {printState && (
                <div className="print-div">
                  <div>
                    <Vakilkaro />
                  </div>
                  <div>
                    <p>{COMPANY_NAME}</p>
                  </div>
                </div>
              )}

              {showTable && (
                <>
                  {showEmiDetails === true ? (
                    <LoanEmiDetailsData
                      customerData={customerData?.id}
                      setShowEmiDetails={setShowEmiDetails}
                      setGetAllEmis={setGetAllEmis}
                      setLoanId={setLoanId}
                    />
                  ) : (
                    <CTable ref={componentPDF} hover className="" responsive>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell scope="col" className="">
                            <span className="">Sr No.</span>
                          </CTableHeaderCell>
                          <CTableHeaderCell scope="col" className="">
                            <span className="">Due Date</span>
                          </CTableHeaderCell>
                          <CTableHeaderCell scope="col" className="">
                            <span className="">Loan Amount</span>
                          </CTableHeaderCell>

                          <CTableHeaderCell scope="col" className="">
                            <span className=""> EMI Amount</span>
                          </CTableHeaderCell>
                          <CTableHeaderCell scope="col" className="">
                            <span className="">EMI Status</span>
                          </CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {filteredEmis?.map((company, index) => (
                          <CTableRow key={index}>
                            <CTableDataCell>{index + 1}</CTableDataCell>
                            <CTableDataCell>
                              {company?.dueDate ? company?.dueDate : "-"}
                            </CTableDataCell>
                            <CTableDataCell>
                              {company?.loadAmount ? parseInt(company?.loadAmount) + parseInt(company?.interestAmount) : "-"}
                            </CTableDataCell>
                            <CTableDataCell>
                              {company?.emiAmount ? company?.emiAmount : "-"}
                            </CTableDataCell>
                            <CTableDataCell>
                              {company?.emiStatus ? company?.emiStatus : "-"}
                            </CTableDataCell>
                          </CTableRow>
                        ))}
                      </CTableBody>
                    </CTable>
                  )}{" "}
                </>
              )}

            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Showing {indexOfFirstRow + 1} to{" "}
                {Math?.min(indexOfLastRow, filteredEmis?.length)} of{" "}
                {filteredEmis?.length} entries
              </div>
              <div className="pagination-container">
                <ReactPaginate
                  activePage={currentPage}
                  itemsCountPerPage={rowsPerPage}
                  totalItemsCount={filteredEmis?.length}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  prevPageText="Previous"
                  nextPageText="Next"
                  firstPageText="First"
                  lastPageText="Last"
                  innerClass="pagination"
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default EmiRecords;
