import React, { useEffect, useState } from "react";
import {
 
  CContainer,
  CForm,
  CFormInput,

  CRow,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,

} from "@coreui/react";
import { useDispatch } from "react-redux";

import { setSchemaName } from "src/redux/actions/staffAction";
import { EmployeePorvider } from "../staffSection/AddStaffForm/provider";

const AddCompanyForm = (event) => {
  // event.preventDefault()
  const dispatch = useDispatch();
  const [schemaname, setSchemaname] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const schemaname = sessionStorage.getItem("schemaName");
    dispatch(setSchemaName(schemaname));
    setSchemaname(schemaname);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("isEdit") === "1") {
      setIsEdit(true);
    }
  }, []);

  const {
    employeeData,
    onChangeValue,
    handleSubmit,
  } = EmployeePorvider();

  const {
    email,
  } = employeeData;

  return (
    <>
      <div className="container">
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>
                  <h3> {isEdit ? "Edit Company Form" : "Add Company Form"}</h3>
                </strong>
              </CCardHeader>
              <CCardBody>
                <CContainer>
                  <CForm
                    onSubmit={(event) => handleSubmit(event, isEdit)}
                    autoComplete="off"
                  >
                    <div className="staffform_header">
                      Personal Information :
                    </div>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormInput
                          type="text"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Company Username"
                          placeholder="Company Username"
                          name="userName"
                          //   value={userName}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormInput
                          type="text"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Company Password"
                          placeholder="Company Password"
                          name="password"
                          //   value={password}
                          onChange={onChangeValue}
                        />
                      </CCol>
                    </CRow>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormInput
                          type="text"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Company Name"
                          placeholder="Company Name"
                          name="companyName"
                          //   value={companyName}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormInput
                          type="email"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Email address"
                          placeholder="name@example.com"
                          name="email"
                          value={email}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>

                    <div className="staffform_header">Residential Address:</div>
                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormInput
                          type="text"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Company Address"
                          placeholder="Company Address"
                          name="companyAddress"
                          //   value={companyAddress }
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormInput
                          type="text"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="City"
                          placeholder="City"
                          name="city"
                          //   value={city}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>
                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormInput
                          type="text"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="State"
                          placeholder="State"
                          name="state"
                          //   value={state}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormInput
                          type="number"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Zip Code"
                          placeholder="Zip Code"
                          name="zipCode"
                          //   value={zipCode}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol xs={6}>
                        <CFormInput
                          type="number"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Company Mobile Number"
                          placeholder="Company Mobile Number"
                          name="phoneNumber"
                          //   value={phoneNumber}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>

                    <div className="staffform_header">Owner Details :</div>
                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormInput
                          type="text"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Company Owner Name"
                          placeholder="Company Owner Name"
                          name="companyOwnerName"
                          //   value={companyOwnerName}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormInput
                          type="text"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Company Owner Address"
                          placeholder="Company Owner Address"
                          name="companyOwnerAddress"
                          //   value={companyOwnerAddress}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormInput
                          type="text"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Company Owner Phone Number"
                          placeholder="Company Owner Phone Number"
                          name="companyOwnerPhoneNumber"
                          //   value={companyOwnerPhoneNumber}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>
                    <div className="staffform_header">
                      Verification Details :
                    </div>
                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormInput
                          type="text"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="GST Number"
                          placeholder="GST Number"
                          name="gstNumber"
                          //   value={gstNumber}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormInput
                          type="number"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Tax Identification Number"
                          placeholder="Tax Identification Number"
                          name="taxIdentificationNumber"
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>
                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormInput
                          type="number"
                          id="floatingInput"
                          floatingClassName="mb-3"
                          floatingLabel="Number Of Employee"
                          placeholder="Number Of Employee"
                          name="numberOfEmployee"
                          //   value={numberOfEmployee}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormInput
                          type="file"
                          name="gstRegistrationImageUrl"
                          // ref={fileInputRef}
                          //   value={taxIdentificationNumber}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>
                  </CForm>
                </CContainer>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </>
  );
};

export default AddCompanyForm;
