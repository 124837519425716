import DataTable from "src/components/DataTable";

import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import Tooltip from "src/components/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompany } from "src/redux/actions/companyAction";
import ReactPaginate from "react-js-pagination";
import DeleteModal from "src/components/commonComponents/commonDeleteModal";
import { Button } from "react-bootstrap";
import AddForm from "./addForm";

import CustomerAllDetails from "./viewForm";

const CustomerOperations = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state?.companyReducer);

  const [model, setModel] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [updateForm, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [companyModel, setCompanyModel] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getAllCompany());
  }, []);
  const handleShow = () => setShowModal(true);
  const handleDelete = () => {
    setSelectedItemId(null);
  };

  const handleDeleteClick = (itemId) => {
    setSelectedItemId(itemId);

    setCompanyModel(true);
    handleShow();
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data?.data?.slice(indexOfFirstRow, indexOfLastRow);
  // const handleEditLoan = (loanData) => {
  //   setUpdate(true);
  //   // setEditCustomerData(loanData);
  //   setModel("update");
  // };
  const handleShowAddForm = () => {
    // When the user clicks the "Add" button, hide the button and show the form
    setShowAddButton(false);
    setShowSelectAndSearch(false);
    setShowAddForm(true);
  };
  const handleViewdetails = (company) => {
    setShowSelectAndSearch(false);
    setShowAccordion(true);
  };

  const roletype = sessionStorage.getItem("role");

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <div className="btn_add">
              {roletype !== "customer" && showAddButton && (
                <Button onClick={handleShowAddForm}>Add</Button>
              )}
            </div>

            <div className="search_bar">
              {showSelectAndSearch && (
                <div>
                  <CFormSelect
                    size="sm"
                    className="mb-3"
                    style={{ cursor: "pointer" }}
                    aria-label="Small select example"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </CFormSelect>
                </div>
              )}
              <div style={{ display: "flex" }}>
                {showSelectAndSearch && (
                  <>
                    <CFormInput
                      type="text"
                      size="sm"
                      placeholder="Search"
                      aria-label="sm input example"
                      style={{ margin: "0px 0px 16px 0px" }}
                    // onChange={handleSearchChange}
                    // value={searchInput}
                    />

                    <span
                      style={{
                        border: " 1px solid #d1c5c5",
                        borderRadius: "4px",
                        margin: "0px 5px 16px -1px",
                        padding: "1px 8px 0px 8px",
                        background: "#ebedef",
                        cursor: "pointer",
                      }}
                    >
                      <i className="fa fa-search"></i>
                    </span>
                  </>
                )}
              </div>
            </div>
            <CCardBody>
              <div
                // className={`table-container ${showAccordion ? "hidden" : ""}`}
                // className={`table-container ${showAddForm ? "hidden" : ""}`}
                className={`table-container ${showAccordion ? "hidden" : ""} ${showAddForm ? "hidden" : ""
                  }`}
              >
                <CTable color="dark" hover className="" responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col" className="col-md-1">
                        S. No.
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Customer Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Paid Emi
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Pending Emi
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Rate
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Total EMIs
                      </CTableHeaderCell>

                      <CTableHeaderCell scope="col" className="col-md-3">
                        Action
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {/* {data?.data && data?.data.length > 0 ? (
                      currentRows?.map((company, index) => ( */}
                    <CTableRow>
                      <CTableHeaderCell scope="row">1</CTableHeaderCell>
                      <CTableDataCell>{"--"}</CTableDataCell>
                      <CTableDataCell>{"--"}</CTableDataCell>
                      <CTableDataCell>{"--"}</CTableDataCell>
                      <CTableDataCell>1324</CTableDataCell>
                      <CTableDataCell>{"--"}</CTableDataCell>

                      <CTableDataCell className="col-md-3">
                        <>
                          <Tooltip text="View">
                            <FontAwesomeIcon
                              role="button"
                              icon={faEye}
                              size="1x"
                              className="viewIcon"
                              onClick={() => handleViewdetails()}
                            />
                          </Tooltip>

                          <Tooltip text="Update">
                            <FontAwesomeIcon
                              role="button"
                              icon={faPenToSquare}
                              size="1x"
                              onClick={() => handleShowAddForm()}
                              className="px-3 updateIcon"
                            />
                          </Tooltip>

                          <Tooltip text="Delete">
                            <FontAwesomeIcon
                              onClick={() => handleDeleteClick()}
                              role="button"
                              icon={faTrash}
                              size="1x"
                              className="deleteIcon"
                            />
                            {showModal ? (
                              <DeleteModal
                                showModal={showModal}
                                setShowModal={setShowModal}
                                onDelete={handleDelete}
                                selectedItemId={selectedItemId}
                                companyModel={companyModel}
                              />
                            ) : (
                              ""
                            )}
                          </Tooltip>
                        </>
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    Showing {indexOfFirstRow + 1} to{" "}
                    {Math?.min(indexOfLastRow, data?.data?.length)} of{" "}
                    {data?.data?.length} entries
                  </div>
                  <div className="pagination-container">
                    <ReactPaginate
                      activePage={currentPage}
                      itemsCountPerPage={rowsPerPage}
                      totalItemsCount={data?.data?.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      prevPageText="Previous"
                      nextPageText="Next"
                      firstPageText="First"
                      lastPageText="Last"
                      innerClass="pagination"
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </div>
              {showAccordion && (
                <CustomerAllDetails
                  selectedCompany={selectedCompany}
                  setShowAccordion={setShowAccordion}
                  setShowSelectAndSearch={setShowSelectAndSearch}
                />
              )}
              {showAddForm && (
                <AddForm
                  selectedCompany={selectedCompany}
                  setShowAddForm={setShowAddForm}
                  setShowAddButton={setShowAddButton}
                  setShowSelectAndSearch={setShowSelectAndSearch}
                  callBack={() => showAddForm(false)}
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <div
            // className={`accordion-container ${showAccordion ? "visible" : ""}`}
            // className={`accordion-container ${showAddForm ? "visible" : ""}`}
            className={`accordion-container ${showAccordion ? "visible" : ""} ${showAddForm ? "visible" : ""
              }`}
          ></div>
        </CCol>
      </CRow>
    </>
  );
};

export default CustomerOperations;
