import React, { useEffect } from "react";
import { CContainer } from "@coreui/react";
import { AppBreadcrumb } from "./index";
import { useNavigate } from "react-router-dom";

const AppHeader = () => {
  const navigate = useNavigate();

  let userData = sessionStorage.getItem("token");
  useEffect(() => {
    if (!userData) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <CContainer fluid style={{ padding: 0 }}>
        <AppBreadcrumb />
      </CContainer>
    </>
  );
};

export default AppHeader;
