import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-js-pagination";
import { getAllEmplyeesData } from "src/api/allApis";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllGroup } from "src/redux/actions/userAction";
import GroupDetailsById from "./groupDetails";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "src/services/const";

const GroupList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentPDF = useRef();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { AllGroups, groupCreated } = useSelector((state) => state?.userReducer);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showGroupDetails, setShowGroupDetails] = useState(false);
  const [groupList, setGroupList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [staffData, setStaffData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [printState, setPrintState] = useState(false);

  const SchemaName = sessionStorage.getItem("schemaName");
  const objData = {
    schemaName: SchemaName,
  };
  useEffect(() => {
    dispatch(getAllGroup(objData));
    getAllEmplyesData();
  }, [groupCreated]);
  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const getAllEmplyesData = () => {
    const payload = { schemaName: SchemaName };

    getAllEmplyeesData(payload).then((res) => {
      setStaffData(res?.data);
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = AllGroups?.data?.slice(indexOfFirstRow, indexOfLastRow);

  const handleViedDetails = (group) => {
    setGroupList(group);
    setShowGroupDetails(true);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const download_btn = () => {
    setPrintState(true);
  };
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  return (
    <CRow className="">
      <CCol xs={12}>
        {showGroupDetails ? (
          <GroupDetailsById
            showGroupDetails={showGroupDetails}
            setShowGroupDetails={setShowGroupDetails}
            groupList={groupList}
          />
        ) : (
          <>
            <CCard className="mb-4" style={{ marginTop: 102 }}>
              <CCardHeader>
                <strong>Manage Group</strong>
              </CCardHeader>
              <CRow>
                <CCol xs={12}>
                  <CCardBody>
                    <CCard>
                      <div className="search_bar">
                        <div className="search-inner">
                          {showSelectAndSearch && (
                            <div>
                              <CFormSelect
                                onChange={handleRowsPerPageChange}
                                size="sm"
                                className="mb-3"
                                style={{ cursor: "pointer" }}
                                aria-label="Small select example"
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                              </CFormSelect>
                            </div>
                          )}
                          <div style={{ display: "flex" }}>
                            {showSelectAndSearch && (
                              <>
                                <CFormInput
                                  type="text"
                                  size="sm"
                                  placeholder="Search"
                                  aria-label="sm input example"
                                  style={{ margin: "0px 0px 16px 0px" }}
                                  onChange={handleSearchChange}
                                  value={searchInput}
                                />

                                <span
                                  style={{
                                    border: " 1px solid #d1c5c5",
                                    borderRadius: "4px",
                                    margin: "0px 5px 16px -1px",
                                    padding: "1px 8px 0px 8px",
                                    background: "#ebedef",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fa fa-search"></i>
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="btn_add mt-0 mb-14">
                          <CButton
                            className="add-btn mr-10 w-auto"
                            onClick={download_btn}
                          >
                            Download
                          </CButton>
                          <CSVLink data={currentRows === undefined ? " " : currentRows} filename={"groupList-table.csv"}>
                            <CButton className="add-btn w-auto m-0">
                              CSV
                            </CButton>
                          </CSVLink>
                        </div>
                      </div>
                      <CCardBody>
                        <div
                          className={`table-container ${showAccordion ? "hidden" : ""
                            }`}
                        >
                          <div
                            ref={printState ? componentPDF : null}
                            style={{ padding: printState ? "20px" : "0px" }}
                          >
                            {printState && (
                              <div className="print-div">
                                <div>
                                  <Vakilkaro />
                                </div>
                                <div>
                                  <p>{COMPANY_NAME}</p>
                                </div>
                              </div>
                            )}
                            <CTable hover responsive >
                              <CTableHead>
                                <CTableRow>
                                  <CTableHeaderCell
                                    scope="col"
                                    className="col-md-2"
                                  >
                                    S. No.
                                  </CTableHeaderCell>
                                  <CTableHeaderCell
                                    scope="col"
                                    className="col-md-3 "
                                  >
                                    Group Name
                                  </CTableHeaderCell>
                                  <CTableHeaderCell
                                    scope="col"
                                    className="col-md-3"
                                  >
                                    Group Head Name
                                  </CTableHeaderCell>
                                  <CTableHeaderCell
                                    scope="col"
                                    className="col-md-3"
                                  >
                                    Group Code
                                  </CTableHeaderCell>

                                  {!printState && (
                                    <CTableHeaderCell
                                      scope="col"
                                      className="col-md-3"
                                    >
                                      Action
                                    </CTableHeaderCell>
                                  )}
                                </CTableRow>
                              </CTableHead>
                              <CTableBody>
                                {AllGroups && AllGroups?.data?.length > 0 ? (
                                  currentRows?.map((group, index) => (
                                    <CTableRow key={index}>
                                      <CTableDataCell>
                                        {indexOfFirstRow + index + 1}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {group?.groupName}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {group?.groupHeadName || "--"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {group?.groupCode || "--"}
                                      </CTableDataCell>

                                      {!printState && (
                                        <CTableDataCell className="col-md-1">
                                          <button
                                            style={{ gap: "2px" }}
                                            // style={{ padding: "0px 6px" }}
                                            className="view-button btn_view margin_auto"
                                            onClick={() =>
                                              handleViedDetails(group)
                                            }
                                          >
                                            <FontAwesomeIcon
                                              role="button"
                                              icon={faAdd}
                                              size="1x"
                                              className="viewIcon"
                                            />
                                            Add
                                          </button>
                                        </CTableDataCell>
                                      )}
                                      {/* <CTableDataCell></CTableDataCell> */}
                                    </CTableRow>
                                  ))
                                ) : (
                                  <div className="loading_data">Loading...</div>
                                )}
                              </CTableBody>
                            </CTable>
                          </div>

                          <div className="pagin-div">
                            <div
                              style={{ fontSize: "16px", fontWeight: "600" }}
                            >
                              Showing {indexOfFirstRow + 1} to{" "}
                              {Math?.min(
                                indexOfLastRow,
                                AllGroups?.data?.length
                              )}{" "}
                              of {AllGroups?.data?.length} entries
                            </div>

                            <div className="pagination-container">
                              <ReactPaginate
                                activePage={currentPage}
                                itemsCountPerPage={rowsPerPage}
                                totalItemsCount={AllGroups?.data?.length}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                                prevPageText="Previous"
                                nextPageText="Next"
                                firstPageText="First"
                                lastPageText="Last"
                                innerClass="pagination"
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div>
                          </div>
                        </div>
                      </CCardBody>
                    </CCard>
                  </CCardBody>
                </CCol>
              </CRow>
            </CCard>
          </>
        )}
      </CCol>
      <CCol xs={12}>
        <div
          className={`accordion-container ${showAccordion ? "visible" : ""}`}
        ></div>
      </CCol>
    </CRow>
  );
};

export default GroupList;
