import {
  GET_ALL_USER,
  ADD_USER_DATA,
  DELETE_USER_BY_ID,
  UPDATE_USER_BY_ID,
  UPDATE_CUSTOMER_STATUS,
  UPLOAD_SANCTION_LETTER,
  REFRESH_PAGE,
  GET_ALL_BRANCHES,
  CREATE_BRANCHES,
  GET_ALL_DIRECTORS,
  CREATE_DIRECTORS,
  GET_ALL_CAPITALS,
  CREATE_CAPITALS,
  UPDATE_CUSTOMER_BY_ID,
  CREATE_LOAN,
  UPDATE_LOAN,
  GET_LOAN_BY_ID,
  GET_ALL_LOAN,
  SET_DATA,
} from "../action_Types";

export const getAllUser = () => {
  return {
    type: GET_ALL_USER,
  };
};

export const addUser = (data) => {
  return {
    type: ADD_USER_DATA,
    payload: data,
  };
};
export const deleteUser = (id) => {
  return {
    type: DELETE_USER_BY_ID,
    payload: id,
  };
};

export const updateUser = (id) => {
  return {
    type: UPDATE_USER_BY_ID,
    payload: id,
  };
};

export const updateCustomerdata = (id) => {
  return {
    type: UPDATE_CUSTOMER_BY_ID,
    payload: id,
  };
};

export const updateCompanyStatus = (data) => {
  return {
    type: UPDATE_CUSTOMER_STATUS,
    payload: data,
  };
};
export const uploadSanctionLetter = (data) => {
  return {
    type: UPLOAD_SANCTION_LETTER,
    payload: data,
  };
};
export const refreshPage = () => {
  return {
    type: REFRESH_PAGE,
  };
};
export const getAllBranches = (data) => {
  return {
    type: GET_ALL_BRANCHES,
    payload: data,
  };
};
export const createBranches = (data) => {
  return {
    type: CREATE_BRANCHES,
    payload: data,
  };
};
export const createDirector = (data) => {
  return {
    type: CREATE_DIRECTORS,
    payload: data,
  };
};
export const getAllDirector = (data) => {
  return {
    type: GET_ALL_DIRECTORS,
    payload: data,
  };
};

export const getAllCapitals = (data) => {
  return {
    type: GET_ALL_CAPITALS,
    payload: data,
  };
};

export const createCapitals = (data) => {
  return {
    type: CREATE_CAPITALS,
    payload: data,
  };
};

export const createNewLoan = (data) => {
  return {
    type: CREATE_LOAN,
    payload: data,
  };
};

export const updateLoan = (data) => {
  return {
    type: UPDATE_LOAN,
    payload: data,
  };
};

export const getLoanId = (data) => {
  return {
    type: GET_LOAN_BY_ID,
    payload: data,
  };
};

export const getLoanData = (data) => {
  return {
    type: SET_DATA,
    payload: data,
  };
};

export const getAllLoan = (data) => {
  return {
    type: GET_ALL_LOAN,
    payload: data,
  };
};
