import React, { useEffect, } from "react";

import { CButton, CCardBody } from "@coreui/react";

import { useSelector } from "react-redux";
import { getStaffDetailsById } from "src/redux/actions/staffAction";
import { useDispatch } from "react-redux";
const ProfileDetails = () => {
  const dispatch = useDispatch();
  const SchemaName = sessionStorage.getItem("schemaName");
  const employee = sessionStorage.getItem("emplyeeId");
  const { staffOwnData } = useSelector((state) => state?.staffReducer);
  useEffect(() => {
    const obj = {
      id: employee,
      schemaName: SchemaName,
    };
    dispatch(getStaffDetailsById(obj));
  }, []);
  return (
    < div className="content">

      <CCardBody>
        <div style={{ width: "100%" }}>
          <div className="form-wrapper">
            <div
              className="statement-form statement-form2"
              id="my-print"
            >
              <hr></hr>

              <div className="maxima">
                <div className="fifty">
                </div>
                <div className="fifty"></div>
              </div>
              <div className="stmt-headbar">
                <div className="stmt-headbar2">
                </div>
              </div>
              <div className="maximacopy">
                <div style={{ width: "100%" }}>
                  <div className="monus">
                    <div style={{ width: "30%" }}>
                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>First Name</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {" "}
                            {staffOwnData?.data?.firstName}
                          </span>
                        </div>
                      </div>

                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>Middle Name</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {staffOwnData?.data?.middleName !== "undefined"
                              ? staffOwnData?.data?.middleName
                              : ""}
                          </span>
                        </div>
                      </div>

                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>Last Name</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {staffOwnData?.data?.lastName}
                          </span>
                        </div>
                      </div>

                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>Gender</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {staffOwnData?.data?.gender}
                          </span>
                        </div>
                      </div>

                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>{"Father's Name"}</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {staffOwnData?.data?.fatherName}
                          </span>
                        </div>
                      </div>

                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>Mobile Number</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {staffOwnData?.data?.phoneNumber}
                          </span>
                        </div>
                      </div>

                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>Email Address</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {staffOwnData?.data?.email}
                          </span>
                        </div>
                      </div>

                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>D.O.B.</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {staffOwnData?.data?.dob}
                          </span>
                        </div>
                      </div>

                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>Manager Name</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {staffOwnData?.data?.managerName}
                          </span>
                        </div>
                      </div>

                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>Employee Role</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {staffOwnData?.data?.employeeRole}
                          </span>
                        </div>
                      </div>

                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>Basic Salary</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {staffOwnData?.data?.basicSalary}
                          </span>
                        </div>
                      </div>

                      <div className="stmt-infocopy">
                        <span className="info-headcopy">
                          <p>Aadhar Number</p>
                        </span>
                        <div className="info-inputcopy">
                          <>:</>
                          <span className="input-span">
                            {staffOwnData?.data?.aadharNumber}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div style={{ width: "50%" }}>
                      <div className="stmt-headbarhalf">
                        <div className="stmt-headbar1">
                          <div className="info-head">
                            <p>Other Details</p>
                          </div>
                        </div>
                        <div className="stmt-headbar2"></div>
                      </div>

                      <div className="maxima50">
                        <div className="fiftycopyhalf">
                          <div className="stmt-infocopy">
                            <span className="info-headcopy info-headcopy-in">
                              <p>Address</p>
                            </span>
                            <div className="info-inputcopy">
                              <>:</>
                              <span className="input-span">
                                {staffOwnData?.data?.address}
                              </span>
                            </div>
                          </div>
                          <div className="stmt-infocopy">
                            <span className="info-headcopy info-headcopy-in">
                              <p>Dl Number</p>
                            </span>
                            <div className="info-inputcopy">
                              <>:</>
                              <span className="input-span">
                                {staffOwnData?.data?.dlNumber}
                              </span>
                            </div>
                          </div>
                          <div className="stmt-infocopy">
                            <span className="info-headcopy info-headcopy-in">
                              <p>Emergency Contact Information</p>
                            </span>
                            <div className="info-inputcopy">
                              <>:</>
                              <span className="input-span">
                                {staffOwnData?.data?.emergencyContactInformation}
                              </span>
                            </div>
                          </div>
                          <div className="stmt-infocopy">
                            <span className="info-headcopy info-headcopy-in">
                              <p>Emergency Contact Number</p>
                            </span>
                            <div className="info-inputcopy">
                              <>:</>
                              <span className="input-span">
                                {staffOwnData?.data?.emergencyContactNumber}
                              </span>
                            </div>
                          </div>

                          <div className="stmt-infocopy">
                            <span className="info-headcopy info-headcopy-in">
                              <p>passportNumber</p>
                            </span>
                            <div className="info-inputcopy">
                              <>:</>
                              <span className="input-span">
                                {staffOwnData?.data?.passportNumber}
                              </span>
                            </div>
                          </div>
                          <div className="stmt-infocopy">
                            <span className="info-headcopy info-headcopy-in">
                              <p>Previous Work Experience</p>
                            </span>
                            <div className="info-inputcopy">
                              <>:</>
                              <span className="input-span">
                                {staffOwnData?.data?.previousWorkExperience}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CCardBody>
    </div>
  );
};


export default ProfileDetails;
