import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CRow,
  CCol,
  CFormInput,
  CFormSelect,
  CFormLabel,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { createNewLoan, getAllLoan } from "src/redux/actions/customerAction";
import { getInterest } from "src/api/allApis";

const AddLoanForm = ({ visible, setVisible, customerData }) => {
  const dispatch = useDispatch();
  const [selectLoanTerm, setSelectLoanTerm] = useState("");
  const [selectLoanType, setSelectLoanType] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [interestData, setInterestData] = useState([]);
  const [SchemaName, setSchemaName] = useState(
    sessionStorage.getItem("schemaName")
  );

  useEffect(() => {
    const obj = {
      schemaName: SchemaName,
      customerId: customerData?.id
    };
    dispatch(getAllLoan(obj));
    getAllInterestList();
  }, []);

  const getAllInterestList = () => {
    const payload = { schemaName: SchemaName };
    getInterest(payload).then((res) => {
      setInterestData(res.data);
    });
  };

  const handleSubmit = () => {
    if (selectLoanTerm && loanAmount) {
      const obj = {
        schemaName: SchemaName,
        customerId: customerData?.id,
        interestId: selectLoanType,
        requestedAmount: loanAmount,
        numberOfEmis: selectLoanTerm,
        branchId: customerData?.branch?.id,
      };
      dispatch(createNewLoan(obj));
      setSelectLoanTerm("");
      setSelectLoanType("");
      setLoanAmount("");
      setVisible(!visible);
    }
  };
  return (
    <>
      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
      >
        <CModalHeader onClose={() => setVisible(false)}>
          <CModalTitle id="LiveDemoExampleLabel">All Loan Type</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol>
              <CFormLabel>Requested Amount
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="text"
                placeholder="Enter Requested Amount"
                value={loanAmount}
                onChange={(e) => setLoanAmount(e.target.value)}
              />
            </CCol>
            <CCol>
              <CFormLabel>Loan Term(in months)
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="text"
                placeholder="Enter Loan Term"
                value={selectLoanTerm}
                onChange={(e) => setSelectLoanTerm(e.target.value)}
              />
            </CCol>
          </CRow>
          <div className="mt-4"></div>
          <CRow>
            <CCol>
              <CFormSelect
                value={selectLoanType}
                onChange={(e) => setSelectLoanType(e.target.value)}
              >
                <option value="">Select Loan Type</option>
                {interestData?.map((ele, index) => (
                  <option key={index} value={ele?.id}>
                    {ele?.loanType}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={handleSubmit}>
            Add Loan
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

AddLoanForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  customerData: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default AddLoanForm;
