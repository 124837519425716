import React, { useState } from "react";
import { CButton, CCardBody, CCol, CContainer, CForm, CFormInput, CFormLabel, CRow } from "@coreui/react";
import * as Yup from "yup";
import PropTypes from 'prop-types';

const initialValues = {
  monthlyExpenses: "",
  income: "",
};

const Income = (props) => {

  const [cusIncome, setCusIncome] = useState(props?.formData || initialValues)

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setCusIncome({ ...cusIncome, [name]: value });
  };

  const validationSchema = Yup.object().shape({
    income: Yup.number().required("Monthly Income is required"),
    monthlyExpenses: Yup.number().required("Monthly Expenses is required"),
  });

  const handleSubmit = (values) => {
    // if (validationSchema) {
    props.updateFormData("income", cusIncome);
    props.setKey("Nomniee", values);
    // }
  };

  const {
    monthlyExpenses,
    income,
  } = cusIncome

  return (
    <CCardBody>
      <CContainer>
        <CForm
          onSubmit={(event) => handleSubmit(event)}
          autoComplete="off"
        >
          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="Income">Monthly Income
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="number"
                id="income"
                placeholder="Enter Your Monthly Income"
                name="income"
                value={income}
                onChange={onChangeValue}
                required
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="monthlyExpenses">Monthly Expenses
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="number"
                id="monthlyExpenses"
                placeholder="Monthly Expenses"
                name="monthlyExpenses"
                value={monthlyExpenses}
                onChange={onChangeValue}
                required
              />
            </CCol>
          </CRow>

          <div className="identification_btn">
            <CButton type="submit" className='next_btn'>Next</CButton>
          </div>
        </CForm>
      </CContainer>
    </CCardBody >
  );
};


Income.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
};
export default Income;
