import React from "react";
import { useReactToPrint } from 'react-to-print';
import PropTypes from "prop-types";
import { Page, Text, Document, View, StyleSheet } from "@react-pdf/renderer";
import { Table } from "react-bootstrap";



const Styles = StyleSheet.create({
  page: {
 
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  general_section: {
    display: "flex",
    justifyContent: 'space-between'
  },
  general_right: {
    display: "flex",
    flexDirection: 'column',
    fontSize: 14
  },
  form_heading: {
    paddingTop: '3%',
    fontSize: 14
  },
  body_text: {
    fontSize: 14,
    lineHeight: .5
  },
  accept: {
    fontSize: 14,
    fontWeight: 700,
    marginTop: '5%'
  },
  prepayment: {
    border: 1,
    borderWidth: 2,
    borderColor: '#000000'
  }
})

const PrintSantionLetterForm = ({ setPrintSantialLetter, custData, emiiData }) => {

  const add = custData.address;
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const createdAt = "2023-12-02T09:47:23.424Z";

  const datePart = createdAt.split('T')[0];
  const pendingemi = emiiData.data.filter(item => item.emiStatus === 'pending');
  const paidemi = emiiData.data.filter(item => item.emiStatus === 'paid');
  const firstPendingEMI = pendingemi[0];

  return (
    <>
      <button className='btn btn-primary' onClick={handlePrint}>Download</button>
      <div className="close_btn">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setPrintSantialLetter(false)}
        ></button>
      </div>
      <div ref={componentRef} className="mt-4" style={{ width: '95%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginRight: '2.5%', marginLeft: '2.5%' }}>
        <div className="sanction_latter">
          {/* <PDFViewer> */}
          <Document>
            <Page size="A4" style={Styles.page}>
              <View style={Styles.general_section}>
                <View style={Styles.general_right}>
                  <Text>Ref No. : </Text>
                  <Text>{custData.firstName}{" "}
                    {custData.middleName ? custData.middleName : ''}
                    {" "}{custData.lastName}</Text>
                  <Text>
                    {add.area}, {add.city}, {add.pincode}<br />
                    {add.district}, {add.state}<br />
                    {add.country}
                  </Text>
                  <Text>Email Id : {custData.email}</Text>
                  <Text>Phone No.: {custData.phoneNumber}</Text>
                  <Text>Date : {datePart}</Text>
                </View>
                <View>
                  Vakilkaro?? company_name from api
                </View>
              </View>
              <View style={Styles.general_section}>
                <Text style={Styles.form_heading}>Dear {custData.firstName},</Text>
              </View>
              <View style={Styles.body}>
                <Text style={Styles.body_text} >
                  {/* {CompanyName} */}
                  &nbsp; &nbsp;&nbsp;&nbsp;{` Hero Housing Finance Limited ("HHFL") is pleased to inform you that basis the Application submitted by you, HHFL approved your loan
                facility as per below mentioned terms and conditions:`}
                </Text>
              </View>
              <View>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th width="30%">Particulars</th>
                      <th className="text-center">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Loan Type</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Loan Amount Approved </td>
                      <td>{custData?.approvalAmount} </td>
                    </tr>
                    <tr>
                      <td >Tenor (in Months)</td>
                      <td>{custData?.loanTerm}</td>
                    </tr>
                    <tr>
                      <td >Rate Of Interest (ROI)</td>
                      {/* <td>{firstPendingEMI.interestAmount.toFixed(2)}</td> */}
                    </tr>
                    <tr>
                      <td >EMI (Installment Amount)</td>
                      <td>{firstPendingEMI?.emiAmount}</td>
                    </tr>
                    <tr>
                      <td >EMI Due Date</td>
                      <td>{firstPendingEMI?.dueDate}</td>
                    </tr>
                    <tr>
                      <td >Tenor (in Months)</td>
                      <td>{firstPendingEMI?.loadDurationInMonths}</td>
                    </tr>
                  </tbody>
                </Table>
              </View>
              <br />
              <View style={Styles.prepayment}>
                <Text style={Styles.accept}>Prepayment Charges</Text>
                <Text style={Styles.body_text} ><br />
                  {`For individual borrowers (Floating rate) : Nil `}< br />
                  {`For Non individual Borrowers (Floating rate) : 0.00%+ Applicable tax on amount prepaid and on all amounts tendered by the Borrower
                  towards Prepayment of the Facility during the last one year from the date of final prepayment. In case, prepayment of loan is from own
                  source of funds ( except borrowing from bank / HFC/ NBFC/ any Financial Institution ), no fee is applicable.
                  `}< br />
                  {`For Mixed Rates, Prepayment charges would be applicable as per fixed rate of interest in accordance to the schedule of charges , if the
                  loan is closed within the fixed tenure . There after prepayment to be charged as per the floating rate of interest charges.
                  `}
                </Text>
              </View>
              <View>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th colSpan={2}>Particular</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Required Copy of receipt issued by {"Bhairav Grah Nirman Sahkari Samiti Ltd"} Jaipur in favor of {custData.firstName}
                        {" "}{custData?.middleName ? custData?.middleName : ''}
                        {" "}{custData?.lastName}
                        {/* condition... */}
                        {/* w/o Mr. Babu Lal Jangid. */}
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        Required Original undertaking regarding JDA lease deed is not issued till data and in further lease deed is issued same is deposit in
                        HHFL and no any litigation pending on said property
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        Required Original society verification letter issued by Bhairav Grah Nirman sahakri samiti ltd.
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>FCU should be found positive</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Technical report should be found positive &amp; vet from internal team</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Electricity bill to be in name of Property owner.</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>SOA of Auto loan of Rs. 5.50 Lakhs running in the name of Mr. Satender Saini to be taken in record.</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Mr Satender Saini SPDC &amp; BSV to be taken in record.</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Repayment from Applicant Saving bank SBI a/c required and BSV required</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>OCR required via banking channel from Purchase to seller bank account &amp; proof to be taken in record before disbursal</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Seller KYC, Cibil seller Memo and banking to be taken in record.</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Cibil consent letter to be taken in record and Seller Cibil to be found positive.</td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Property Should be vacant before reg
                      </td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td> Seller Fcu to be documented
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>FCU OF COMPANY FINANCIAL TO BE DOCUMENTED</td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>Repayment to be taken from {custData?.firstName}{""}{custData?.lastName} SBI-STATE BANK OF INDIA Account 51109247299.</td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>Insurance amount mentioned in the sanction letter is towards property insurance and life insurance</td>
                    </tr>
                  </tbody>
                </Table>

              </View>

              {/* general work */}
              <div>
                <p className="text-center mt-2 fw-bold">GENERAL TERMS AND CONDITIONS</p>
                <p>1. This sanction letter (Sanction Letter) shall stand revoked/ cancelled and shall be rendered absolutely null and void if:</p>
                <div className="container-fluid">
                  <p>a. there are material changes in the proposal for which this Facility is sanctioned;</p>
                  <p>b. any material fact concerning Borrowers income or ability to repay or any other relevant fact is suppressed, concealed or not
                    made known to the Lender;</p>
                  <p>c. any statement made or documents submitted along with the Loan Application Form are found to be incorrect or false.</p>
                  <p>d. There is a default or breach of the terms and conditions of this or any other loan facility offered by HHFL or its group Company
                    to the Borrower or any of the Co-Borrower(s).</p>
                  <p>e. Legal/technical/valuation report is not satisfactory.</p>
                  <p>f. Sanction plans of the mortgaged property is not approved from the appropriate authorities.</p>
                  <p>g. Relevant documents are not executed by the Borrower as per the HHFLs policy and format.</p>
                </div>

                <p>2. Not withstanding the issuance of this Sanction Letter and the acceptance thereof, HHFL may decide to not disburse the Facility,
                  repudiate and rescind this Sanction Letter unilaterally without assigning any reasons</p>
                <p>3. This Sanction Letter intends to summarize certain basic terms of the Facility and Loan Agreement and does not reflect an agreement
                  between the HHFL and the Borrower in relation to the Facility. This Sanction Letter shall be</p>
                <p>4. All indirect taxes, duties and levies, including but not limited to interest tax, service tax, education cess, levied under the applicable
                  laws as may be amended from time to time would be additionally charged.</p>
                <p>5. HHFL adopts interest rate/ pricing methodology based on various risks associated with the type of loan, cost of funds, tenor of the loan,
                  type & value of security, loan amount etc. Further, HHFL periodically reviews the rate of interest and product features offered by it with
                  those offered by its competitors. For a borrower, an additional risk premium may be included in the pricing based on the credit risk
                  associated with the customer which is a function of the customers credit history, credit information, internal rating along with other
                  factors like the borrowers source of income, professional experience etc. Accordingly, the applicable rate of interest may be different
                  from a customer to customer and a loan to other loan.</p>
                <p>6. In case of Floating Rate of Interest, the rate of interest is linked to Hero Reference Rate which subject to revision as per HHFLs
                  internal policies based on its sole discretion.</p>
                <p>7. From the date of first disbursement, Borrower shall be required to pay Pre-EMI Interest (at the rate applicable to your facility) till the
                  time your Facility is fully disbursed, subsequent to which your EMI payments will begin.</p>
                <p>8. The Processing Fees is a non refundable fee and is collected by HHFL for the purpose of appraising the Loan Application form for the
                  Facility and the same is independent of the outcome/result of such appraisal.</p>
                <p>9. This Sanction Letter is confidential and the property of HHFL and neither this document nor the contents hereof shall be communicated
                  to or used without the HHFLs prior written consent.</p>
                <p>10. It will be your responsibility to ensure that the property is duly and properly insured against all risks such as earthquake, fire, explosion,
                  storm, cyclone, civil commotion, etc. during the pendency of the loan, with HHFL being the sole beneficiary under this policy/policies.</p>
                <p>11. Borrower shall immediately intimate HHFL in the event of any change in the repayment capacity of the Borrower or any information
                  stated in the Loan application form.</p>
                <p>12. No third party interest, including but not limited to license/tenancy rights havebeen created or will be created without prior written
                  permission of HHFL.</p>
                <p>13. The mortgaged property is free from any proceeding or adverse judgment or decree ina Court case involving breach of contract, tax
                  malfeasance or other serious misconduct.</p>
                <p>14. The mortgaged property is free from all disputes, charges, taxes, litigation, attachment anywhere in India.</p>
                <p>15. The Borrower shall not create any further charge, lien or encumbrance on theassets/properties funded by HHFL, without prior written
                  permission of HHFL.</p>
                <p>16. The Borrower shall not sell, assign, mortgage or otherwise dispose off any of the fixed assets charged with HHFL.</p>
                <p>17. HHFL is entitled to add to, delete or modify all or any of the aforesaid terms and conditions</p>
                <p>18. This Sanction Letter shall be governed by the laws of India and the Borrower and the Lender shall be subject to the exclusive
                  jurisdiction of New Delhi.</p>
                <p>19. In case of Home Loan, any amount can be prepaid, however no prepayment is allowed within 6 months from 1st disbursement.</p>
                <p>20. In case of Non Home Loan, = 25% of Principal Outstanding in a financial year. However, no part prepayment is allowed within 6
                  months from 1st disbursement</p>
                <p>21. Cheque/NACH bounce charges are INR 500/- plus Applicable tax per bounce</p>
                <p>22. Default & Penal Interest is 2% per month on overdue payment</p>
                <p>23. Pradhan Mantri Awas Yojna (PMAY-CLSS) subsidy scheme is applicable only if the property is constructed as per approved sanctioned
                  plan from competent authority as required in the PMAY- CLSS Scheme guidelines.</p>
                <p>24. If a change in terms and conditions is to the disadvantage of the customer , he/ she may within 60 days and without notice close his/
                  her account or switch the loan account without having to pay extra charges or interest.</p>
                <p>25. For All Home Loan Cases, Property should be vacant before execution of any transaction & release of funds to Seller.</p>
                <p>26. For Loan Against Property Cases, if the property is on Rent, either the property has to be vacated upfront or NOC from Tenant has to
                  be documented along with his KYC.</p>
                <p>27. Sanction is subject to the clearance of initial cheque provided at time of login.</p>
                <p>28. All Property Owners have to be the Applicant/Co-Applicant to the loan structure</p>
                <p>29. Client has to submit the Permission to Mortgage & has to Create the Charge of HHFL in ROC (wherever applicable) within 20 days of
                  loan disbursal.</p>
                <p>30. Notice of Intimation (applicable in Maharashtra) to be done within 15 days of loan disbursal.</p>
                <p>31. It is the customer or applicant sole responsibility to ensure that registration of the sale deed takes place as per due process of
                  applicable rules and byelaws</p>
                <p>32. The disbursal cheque shall be handed over to the customer or his or her representative (Seller) (if applicable) upon submission of
                  original sale deed in no less than the 15 days from the date of registration.</p>
                <p>33. Failure to submit the original sale deed and other document, may entitle Hero Housing to recall or cancel the disbursement of the loan.</p>
                <p>34. The applicant shall not have any legal recourse against Hero Housing, if recall or cancellation of the disbursement of the loan, takes
                  place due to non-submission of Original Sale Deed or on non-compliance of sanction conditions.</p>
                <p>35. The final loan amount for Auto Top-Up Loans will be subject to positive credit verifications and appraisal criteria as per norms of Hero
                  Housing Finance Limited.</p>
                <p>36. All the norms of ECLGS (emergency credit loan guarantee scheme) shall be governed by the operational guideline issued and
                  modification from time to time by National Credit Guarantee Trustee Company Limited (NCGTC).</p>
                <p>37. Any changes to the current structure of the Scheme, including but not limited to the eligibility criteria, guarantee fee, rate of interest and
                  tenor of ECLGS under the Scheme, can be modified based on the modification by NCGTC.</p>
                <p>38. You may refer to our Fair Practice Code (FPC) available on the HHFL website i.e. https://www.herohousingfinance.com/companypolicy.</p>
                <p>39. This Sanction letter is issued subject to positive checks of Seller/s (including and not limited to CIBIL, KYC and Banking vintage) along
                  with Property verification(including and not limited to onsite visit, documentation and other checks) to the satisfaction of Hero Housing
                  Finance Limited & confirmation from your end that all the required property papers will be provided upfront in original(as per type of
                  transaction) for vetting by Hero Housing Finance Limited and/or by its authorised representatives & there is no encumbrance (as per
                  type of transaction) on the property</p>
                <p>40. Reference rate is as on the date of login of the file at Hero Housing Finance Limited.</p>
              </div>

              <View>
                <Text style={Styles.accept}>
                  Agreed and Accepted by the Borrower & Co-Borrower(s)
                </Text><br />
                <Text style={Styles.body_text} >
                  {`I/We confirm that we understand and accept the terms and conditions of the Sanction Letter, as laid out in English/ have been explained to
                us in a vernacular language of our understanding and I/ We accept the Facility on the terms set above.I/We authorise you to collect
                information about is as may be required for evaluating the proposed financing Facility, including without limitation, gathering information for
                credit bureas like CIBIL. I/We also authorize you to share my/our credit related finformation, and my/our personal data at any point of time,
                with credit bureaus, statutory agency or any third party agency as you may deem fit.`}
                </Text>
              </View>

              {/* signature area */}
              <div className="container mt-5">
                <div className="row">
                  <div className="col">
                    <p className="border-top p-2">Name & Signature of the Borrower</p>
                  </div>
                  <div className="col">
                    <p className="border-top p-2">Name & Signature of the Borrower, if any</p>
                  </div>
                </div>
              </div>

              <div className="container mt-3">
                <div className="col-4">
                  <p className="border-top p-2">Name & Signature of the Borrower, if any</p>
                </div>
              </div>

              {/* ṃid line center */}
              <p className="text-center mt-3">For any query or clarification you can call on 1800-212-8800 or Email on customer,care@herohfl.com.</p>


            </Page>
          </Document>
          {/* </PDFViewer> */}
        </div>
      </div>
    </>
  );
};
PrintSantionLetterForm.propTypes = {
  setPrintSantialLetter: PropTypes.bool.isRequired,
  //cuData: PropTypes.object.isRequired,
  custData: PropTypes.object.isRequired,
  emiiData: PropTypes.object.isRequired,
};

export default PrintSantionLetterForm;
