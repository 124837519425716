import React, { useState } from "react";

import { CCard, CCol, CRow } from "@coreui/react";
import PropTypes from "prop-types";

import RegistrationForm from "../../components/EmiPageComponent/addForm";

const AddForm = ({
  selectedCompany,
  setShowAddForm,
  setShowAddButton,
  setShowSelectAndSearch,
  callBack
}) => {
  const [key, setKey] = useState("home");
  const [formData, setFormData] = useState({
    registration: {},
    address: {},
    identiFication: {},
    bankDetails: {},
    income: {},
    refrences: {},
  });
  const updateFormData = (formName, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: data,
    }));
  };
  const collectAllData = () => {
    const allData = {
      registration: formData.registration,
      address: formData.address,
      identiFication: formData.identiFication,
      bankDetails: formData.bankDetails,
      income: formData.income,
      refrences: formData.refrences,
    };

    const combinedData = Object.keys(allData).reduce((acc, key) => {
      return { ...acc, ...allData[key] };
    }, {});


    return combinedData;
  };
  const handleShowAddButton = () => {
    setShowSelectAndSearch(true);
    setShowAddButton(true);
    setShowAddForm(false);
  };
  return (
    <>
      <div className="close_btn">
        <div></div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => handleShowAddButton()}
        ></button>
      </div>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            {/* <CCardHeader>{selectedCompany?.companyName} </CCardHeader> */}
            <RegistrationForm
              formData={formData.registration}
              updateFormData={updateFormData}
              setKey={setKey}
              key={key}
              callBack={callBack}
            />
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

AddForm.propTypes = {
  setShowAddForm: PropTypes.func,
  setShowAddButton: PropTypes.func,
  setShowSelectAndSearch: PropTypes.func,
  selectedCompany: PropTypes.object,
  callBack: PropTypes.func
};

export default AddForm;
