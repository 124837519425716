import {
  CButton,
  CCard,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAllAccount, getAllLedger } from "src/redux/accounting/action";
const Ledger = () => {
  const dispatch = useDispatch();
  const { newJournalData, allAccountData, allLedgerData } = useSelector(
    (state) => state?.acountingReducer
  );
  const [values, setValues] = useState();
  const schemaName = sessionStorage.getItem("schemaName");
  const initalState = {
    schemaName: schemaName,
    startDate: "",
    ledgerName: "",
    lastDate: "",
    debitAccount: "",
  };
  const [showData, setShowData] = useState(false);
 
  const [formData, setFormData] = useState(initalState);

  useEffect(() => {
    if (newJournalData != null) {
      setValues(newJournalData);
    }
  }, [newJournalData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  useEffect(() => {
    const obj = {
      schemaName: schemaName,
    };
    dispatch(getAllAccount(obj));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getAllLedger(formData));
    setShowData(true);
  };

  const { startDate, lastDate, debitAccount } = formData;

  const calculateTotalAmount = (data, key) => {
    const countedData = allLedgerData?.data?.map((e) => e[key]) || [];
    const totalAmount = countedData.reduce(
      (acc, amount) => acc + (amount || 0),
      0
    );

    return totalAmount;
  };

  const totalCreditAmount = calculateTotalAmount(allLedgerData, "creditAmount");
  const totalDebitAmount = calculateTotalAmount(allLedgerData, "debitAmount");

  return (
    <div className="content">
      <div>
        <CForm onSubmit={(e) => handleSubmit(e)}>
          <CRow>
            <CCol xs={3}>
              <CFormInput
                type="date"
                placeholder="Enter Start Date"
                value={startDate}
                onChange={handleChange}
                name="startDate"
                required
              />
            </CCol>
            <CCol xs={3}>
              <CFormInput
                type="date"
                placeholder="Enter Last Date"
                value={lastDate}
                name="lastDate"
                onChange={handleChange}
                required
              />
            </CCol>
            <CCol xs={3}>
              <CFormSelect
                type="text"
                placeholder="Enter Account Name"
                required
                name="debitAccount"
                value={debitAccount}
                onChange={handleChange}
              >
                <option value="">Select Accounts</option>
                {allAccountData?.data?.map((item, i) => (
                  <option key={i} value={item?.accountName}>
                    {item?.accountName?.toUpperCase()}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
            <CCol xs={3}>
              <CButton type="submit">Search</CButton>
            </CCol>
          </CRow>
        </CForm>
      </div>
      {showData && (
        <div>
          <div className="ledger_data">
            <span>Dr.</span>
            <span>{debitAccount}</span>
            <span>Cr.</span>
          </div>
          <CCard>
            <CTable striped hover>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Particulars</CTableHeaderCell>
                  <CTableHeaderCell scope="col">J.F.</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Particulars</CTableHeaderCell>
                  <CTableHeaderCell scope="col">J.F.</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {allLedgerData?.data?.map((ele, i) => (
                  <CTableRow key={i}>
                    <CTableDataCell></CTableDataCell>
                    <CTableDataCell>To {ele?.creditAccount}</CTableDataCell>
                    <CTableDataCell></CTableDataCell>
                    <CTableDataCell>{ele?.creditAmount}</CTableDataCell>
                    <CTableDataCell></CTableDataCell>
                    <CTableDataCell>By {ele?.debitAccount}</CTableDataCell>
                    <CTableDataCell></CTableDataCell>
                    <CTableDataCell>{ele?.debitAmount}</CTableDataCell>
                  </CTableRow>
                ))}
                <CTableRow>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>
                    {totalDebitAmount > totalCreditAmount ? "To" : ""}
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>
                    {totalDebitAmount > totalCreditAmount
                      ? totalDebitAmount - totalCreditAmount
                      : ""}
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>
                    {totalCreditAmount > totalDebitAmount ? "By" : ""}
                  </CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>
                    {totalCreditAmount > totalDebitAmount
                      ? totalCreditAmount - totalDebitAmount
                      : ""}
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>Total</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>{totalCreditAmount}</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>Total</CTableDataCell>
                  <CTableDataCell></CTableDataCell>
                  <CTableDataCell>{totalDebitAmount}</CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </CCard>
        </div>
      )}
    </div>
  );
};

export default Ledger;
