import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CButton, CFormInput, CForm, CInputGroup, CFormLabel, CFormSelect, CContainer, CCard, CCardHeader, CRow, CCol, CCardBody } from "@coreui/react";
import { getInterest } from "src/api/allApis";
import _ from "lodash";
import Emi from "../emi";

const CalEMi = () => {
  const [formData, setFormData] = useState({
    amount: "",
    term: "",
    rate: "",
  });

  const [interest, setInterest] = useState([]);

  const fetchInterest = async () => {
    const res = await getInterest({ schemaName: sessionStorage.getItem("schemaName") });
    setInterest(res?.data || []);
  };
  const [showEmi, setShowEmi] = useState(false);
  const [emiList, setEmiList] = useState([]);
  const [totalAmountWithInterest, setTotalAmountWithInterest] = useState(0);

  useEffect(() => {
    fetchInterest();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const calculateEMI = (loanAmount, loanTerm, interestRate) => {
    const monthlyInterestRate = interestRate / 100 / 12;
    const totalMonths = loanTerm;

    const emiArray = [];
    let outstandingBalance = loanAmount;

    for (let month = 1; month <= totalMonths; month++) {
      const interestPayment = outstandingBalance * monthlyInterestRate;
      const principalPayment = loanAmount / totalMonths;
      const emi = principalPayment + interestPayment;
      outstandingBalance -= principalPayment;

      const totalAmountWithInterest = emi + principalPayment + interestPayment;
      emiArray.push({
        month,
        emi,
        principalPayment,
        interestPayment,
        totalAmountWithInterest,
      });
    }

    return emiArray;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const emiArray = calculateEMI(formData.amount, formData.term, formData.rate);
    const totalAmount = emiArray[0].emi + emiArray[0].principalPayment + emiArray[0].interestPayment;
    setTotalAmountWithInterest(totalAmount);
    setEmiList(emiArray);
    setShowEmi(true);
  };

  return (
    <>
      <CRow className="content">
        <CCol xs={12}>
          <CCard>
            <CCardHeader>
              <strong>
                EMI Calculation
              </strong>
            </CCardHeader>
            <CCardBody>
              <CCard>
                <CCardBody>
                  <CForm onSubmit={handleSubmit} >
                    <CRow >
                      <CCol xs={12} md={6} lg={6} className="mb-2">
                        <CFormLabel htmlFor="amount"> Amount</CFormLabel>
                        <CFormInput
                          type="number"
                          id="amount"
                          name="amount"
                          value={formData.amount}
                          onChange={handleChange} 
                          required
                          />
                          
                      </CCol>

                      <CCol xs={12} md={6} lg={6}className="mb-2">
                        <CFormLabel htmlFor="term">Term</CFormLabel>
                        <CFormInput
                          type="number"
                          id="term"
                          name="term"
                          value={formData.term}
                          onChange={handleChange}
                          required
                        />
                      </CCol>
                      </CRow>
                      <CRow>

                      <CCol xs={12} md={6} lg={6}className="mb-2">
                        <CFormLabel htmlFor="rate"> Rate </CFormLabel>
                        <CFormSelect
                          id="rate"
                          name="rate"
                          value={formData.rate}
                          onChange={handleChange}
                          required
                        >
                          <option value={""} selected>Select an Option</option>
                          {interest?.map((item, i) => (
                            <option key={i + 1} value={item?.rate}>
                              {`${item?.rate} (${item?.loanType?.toUpperCase()})`}
                            </option>
                          ))}
                        </CFormSelect>
                      </CCol>
                    </CRow>
                    <div className="branch_btn mt-4">
                      <CButton
                        className="next_btn"
                        type="submit"
                        disabled={_.some(formData, _.isEmpty)}
                      >
                        Calculate EMI
                      </CButton>
                    </div>
                  </CForm>
                  {showEmi && <Emi
                    showEmi={showEmi}
                    setShowEmi={setShowEmi}
                    emiList={emiList}
                    totalAmountWithInterest={totalAmountWithInterest}
                  />}
                </CCardBody>
              </CCard>
            </CCardBody>
          </CCard>
        </CCol>

      </CRow>

    </>
  );
};
Emi.propTypes = {
  emiList: PropTypes.array.isRequired,
  totalAmountWithInterest: PropTypes.array.isRequired,
  setShowEmi: PropTypes.func.isRequired,
};

export default CalEMi;
// pan, adhar, name, address, 