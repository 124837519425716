import React, { useEffect, useState } from "react";
import {
  
  CCol,
  CContainer,
  CRow,
  CCardBody,
  CCard,
  CCardHeader,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { getLoanId } from "src/redux/actions/customerAction";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "src/components/Tooltip";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const EmiDetailsData = ({
  customerData,
  emiData,
  setLoanId,
  setApprovedLoan,
  setKey,
  setShowEmiDetails,
  setGetAllEmis,
}) => {
  const dispatch = useDispatch();

  const { loans, loandetails } = useSelector((state) => state?.customerReducer);
  const SchemaName = sessionStorage.getItem("schemaName");

  const newData = (data, id,datas) => {
    setApprovedLoan(datas)
    setLoanId(id)
    setGetAllEmis(data);
    setShowEmiDetails(false);
  };

  useEffect(() => {
    const obj = {
      customerId: customerData,
      schemaName: SchemaName,
    };

    dispatch(getLoanId(obj));
  }, [customerData]);

  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCardBody>
            <CContainer>
              <CCard className="mb-4">
                <CCardHeader>
                  <strong>Your Loans</strong>
                </CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol xs={12}>
                      <CCard className="mb-2">
                        <div className="search_bar">
                          <div className="search-inner"></div>
                        </div>

                        <div className="btn_add mt-0 mb-14"></div>

                        <CCardBody>
                          <div>
                            <div>
                              <CTable color="dark" hover responsive>
                                <CTableHead className="tabl-head">
                                  <CTableRow className="table-row">
                                    <CTableHeaderCell scope="col">
                                      Sr No.
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Requested Amount
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Approved Amount
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Approval Date
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Status
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Total EMIs
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Action
                                    </CTableHeaderCell>
                                  </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                  {loandetails?.data?.map((datas, index) => (
                                    <CTableRow key={index} className="cursor">
                                      <CTableDataCell>
                                        {index + 1}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {datas?.requestedAmount
                                          ? datas?.requestedAmount
                                          : "--"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {datas?.approvalAmount
                                          ? datas?.approvalAmount
                                          : "--"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {datas?.approvalDate
                                          ? datas?.approvalDate.split('T')[0]
                                          : "--"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {datas?.status ? datas?.status : "--"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        {datas?.numberOfEmis
                                          ? datas?.numberOfEmis
                                          : "--"}
                                      </CTableDataCell>
                                      <CTableDataCell>
                                        <Tooltip text="View">
                                          <FontAwesomeIcon
                                            role="button"
                                            icon={faEye}
                                            size="1x"
                                            className="viewIcon"
                                            onClick={() => newData(datas?.emis, datas?.id,datas)}
                                          />
                                        </Tooltip>
                                      </CTableDataCell>
                                    </CTableRow>
                                  ))}
                                </CTableBody>
                              </CTable>
                            </div>
                          </div>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CContainer>
          </CCardBody>
        </CCol>
      </CRow>
    </>
  );
};

EmiDetailsData.propTypes = {
  customerData: PropTypes.object.isRequired,
  setApprovedLoan:PropTypes.func,
  setLoanId: PropTypes.number.isRequired,
  emiData: PropTypes.array.isRequired,
  setKey: PropTypes.func.isRequired,
  setShowEmiDetails: PropTypes.func.isRequired,
  setGetAllEmis: PropTypes.func.isRequired,
};

export default EmiDetailsData;
