import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { deletePlan, getAllPlan } from "src/redux/actions/planAction";
import {
  deleteCompany,
  getAllCompany,
  deleteLoan,
} from "src/redux/actions/companyAction";

const DeleteModal = ({
  showModal,
  setShowModal,
  onDelete,
  selectedItemId,
  companyModel,
  setCompanyModel,
  type,
  deleteCallBack,
}) => {
  const dispatch = useDispatch();

  const SchemaName = sessionStorage.getItem("schemaName");

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDelete = async () => {
    if (type === "loan") {
      const payload = { schemaName: SchemaName, selectedItemId };
      dispatch(deleteLoan(payload));
      deleteCallBack && deleteCallBack();
      onDelete();
      handleClose();
      return;
    }
    if (companyModel === true) {
      // const payload = { schemaName: SchemaName, selectedItemId }
      dispatch(deleteCompany(selectedItemId));
      onDelete();
      dispatch(getAllCompany());
      handleClose();
    } else {
      dispatch(deletePlan(selectedItemId));
      onDelete();
      dispatch(getAllPlan());
      handleClose();
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
    // backdrop="static"
    // keyboard={false}
    >
      {/* <Modal.Header closeButton> */}
      <Modal.Header>
        <Modal.Title>
          {" Delete Confirmation "}
          {/* {companyModel === true
            ? " Delete Confirmation "
            : " Delete Confirmation"} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this item ?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  companyModel: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setCompanyModel: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedItemId: PropTypes.number,
  type: PropTypes.string,
  deleteCallBack: PropTypes.func,
};

export default DeleteModal;
