import {
  GET_ALL_EMI,
  ADD_EMI_DATA,
  DELETE_EMI_BY_ID,
  UPDATE_EMI_BY_ID,
  CREATE_EMI,
  SHOW_LOAN_SUMMARY,
  CREATE_DISBURSEMENT_PAYMENT,
  GET_ALL_EMI_BY_CUSTOMER_ID,
  SET_PAYMENT_FALSE,
  SET_CUSTOMER_DATA,
  GET_ALL_EMI_BY_EMI_STATUS,
} from "../action_Types";

export const getAllEmi = () => {
  return {
    type: GET_ALL_EMI,
  };
};

export const getAllEmiByCustomerId = (data) => {
  return {
    type: GET_ALL_EMI_BY_CUSTOMER_ID,
    payload: data,
  };
};

export const setCustomerId = (data) => {
  return {
    type: SET_CUSTOMER_DATA,
    payload: data,
  };
};

export const addEmi = (data) => {
  return {
    type: ADD_EMI_DATA,
    payload: data,
  };
};
export const deleteEmi = (id) => {
  return {
    type: DELETE_EMI_BY_ID,
    payload: id,
  };
};

export const updateEmi = (data) => {
  return {
    type: UPDATE_EMI_BY_ID,
    payload: data,
  };
};

export const createEmi = (data) => {
  return {
    type: CREATE_EMI,
    payload: data,
  };
};

export const createDisbursementPayment = (data) => {
  return {
    type: CREATE_DISBURSEMENT_PAYMENT,
    payload: data,
  };
};

export const setPaymentFalse = () => {
  return {
    type: SET_PAYMENT_FALSE,
  };
};

export const showLoanSummaryWithCustomerDetails = (data) => {
  return {
    type: SHOW_LOAN_SUMMARY,
    payload: data,
  };
};

export const getAllEmisByEmiStatus = (data) => {
  return {
    type: GET_ALL_EMI_BY_EMI_STATUS,
    payload: data,
  };
};
