import {
  CButton,
  CCard,
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React from "react";
const JournalEntries = () => {
  return (
    <div className="content">
      <CCard>
        <div className="ac_header">
          <CRow>
            <CCol xs={4}>
              <span> New Journal Entry</span>
            </CCol>
            <CCol xs={4}></CCol>
            <CCol xs={4}>
              <CRow>
                <CCol xs={2}></CCol>

                <CCol xs={3}>
                  <CButton>{`Save & New`}</CButton>
                </CCol>
                <CCol xs={2}>
                  <CButton>Save</CButton>
                </CCol>
                <CCol xs={2}>
                  <CButton>Cancel</CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
        <div className="border_bottom"></div>
        <div className="ac_date">
          <CRow>
            <CCol xs={3}>
              <CFormLabel>Date</CFormLabel>
              <CFormInput type="date" placeholder="12-12-2022" />
            </CCol>
          </CRow>
        </div>

        <div className="ac_list">
          <CTable striped>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">Sr No .</CTableHeaderCell>
                <CTableHeaderCell scope="col">Account</CTableHeaderCell>
                <CTableHeaderCell scope="col">Contact</CTableHeaderCell>
                <CTableHeaderCell scope="col">Debit</CTableHeaderCell>
                <CTableHeaderCell scope="col">Credit</CTableHeaderCell>
                <CTableHeaderCell scope="col">Description</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow>
                <CTableHeaderCell scope="row">1</CTableHeaderCell>
                <CTableDataCell>Mark</CTableDataCell>
                <CTableDataCell>Otto</CTableDataCell>
                <CTableDataCell>@mdo</CTableDataCell>
                <CTableDataCell>@mdo</CTableDataCell>
                <CTableDataCell>@mdo</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">2</CTableHeaderCell>
                <CTableDataCell>Jacob</CTableDataCell>
                <CTableDataCell>Thornton</CTableDataCell>
                <CTableDataCell>Thornton</CTableDataCell>
                <CTableDataCell>Thornton</CTableDataCell>
                <CTableDataCell>@fat</CTableDataCell>
              </CTableRow>
              <CTableRow>
                <CTableHeaderCell scope="row">3</CTableHeaderCell>
                <CTableDataCell colSpan={2}>Larry the Bird</CTableDataCell>
                <CTableDataCell>@twitter</CTableDataCell>
                <CTableDataCell>@twitter</CTableDataCell>
                <CTableDataCell>@twitter</CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        </div>
        <div className="ac_textarea">
          <CRow>
            <CCol xs={4}>
              <CFormLabel>Notes</CFormLabel>
              <CFormTextarea
                id="floatingTextarea"
                floatingLabel="Comments"
                placeholder="Leave a comment here"
              ></CFormTextarea>
            </CCol>
          </CRow>
        </div>
      </CCard>
    </div>
  );
};

export default JournalEntries;
