import React from "react";
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  cilAccountLogout,
  cilLockLocked,
  cilPeople,
  cilUser,
  cilUserFemale,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
// import { persistor } from "../../redux/store";
import avatar8 from "./../../assets/images/avatars/8.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { emptyStore, getStaffDetailsById } from "src/redux/actions/staffAction";
import ProfileDetails from "../profile/profile";

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SchemaName = sessionStorage.getItem("schemaName");
  const rolety = sessionStorage.getItem("role");
  const handleLogout = async () => {
    await sessionStorage.clear();
    dispatch(emptyStore());
    await localStorage.removeItem("persist:root");
    //  await persistor.flush();
    //  await  persistor.purge();
    navigate("/login");
  };

  const handleViewDetail = () => {
    // const obj = {
    //   id: employee,
    //   schemaName: SchemaName,
    // };
    // dispatch(getStaffDetailsById(obj));
    navigate("/profile");
  };

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={avatar8} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">
          Account
        </CDropdownHeader>
        <CDropdownDivider />

        <CDropdownItem onClick={handleLogout}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Logout
        </CDropdownItem>
        <CDropdownDivider />

        {rolety === "company" ? (
          ""
        ) : rolety === "admin" ? (
          ""
        ) : (
          <CDropdownItem onClick={handleViewDetail}>
            <CIcon icon={cilLockLocked} className="me-2" />
            Profile
          </CDropdownItem>
        )}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default ProfileDropdown;
