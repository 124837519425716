import React from "react";

import {

  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
const CustomerAllDetails = ({
  selectedCompany,
  setShowAccordion,
  setShowSelectAndSearch,
}) => {
  const handleCloseAccordian = () => {
    setShowSelectAndSearch(true);
    setShowAccordion(false);
  };
  const role = sessionStorage.getItem("role");
  return (
    <>
      <div className="close_btn">
        <div></div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => handleCloseAccordian()}
        ></button>
      </div>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>Customer Data </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol xs={6}>
                  <label className="label">Customer Name</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.firstName} </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>Customer Mobile Number</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.phoneNumber}{" "}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Customer Mail Id</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.email} </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>ssn</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.ssn} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Customer Gender</label>
                  <CCard>
                    <CCardBody>
                      {/* {selectedCompany?.companyPurchasedOn}{" "} */}
                      male
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>Father Name </label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.fatherName}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Date of Birth</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.dob} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>

              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Monthly Expenses</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.monthlyExpenses} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Nominee Name</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.nominee}</CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>propertyAndVehicle</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.propertyAndVehicle}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>

              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Adress</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.address}
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>loanTerm</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.loanTerm} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Credit Score</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.creditScore}
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>AadharNumber</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.aadharNumber} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Bank Account No</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.bankAccountNo}
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>Bank Name</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.bankName} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Disbursement
                  </label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.disbursement}
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>disbursementDate </label>
                  <CCard>
                    <CCardBody>{selectedCompany?.disbursementDate} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>empStartDate</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.empStartDate}
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>employeeVerification</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.employeeVerification} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>ifscCode</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.ifscCode}
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>income</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.income} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>martialStatus</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.martialStatus}
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>loanAmountRequestedr</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.loanAmountRequested} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>monthlyExpenses</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.monthlyExpenses}
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>occupation</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.occupation} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>propertyAndVehicle</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.propertyAndVehicle}
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>loanTerm</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.loanTerm} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>

            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
CustomerAllDetails.propTypes = {
  setShowAccordion: PropTypes.func,
  setShowSelectAndSearch: PropTypes.func,
  selectedCompany: PropTypes.object,
};

export default CustomerAllDetails;
