import {
  ADD_COMPANY_DATA,
  DELETE_COMPANY_BY_ID,
  GET_ALL_COMPANY,
  UPDATE_COMPANY_BY_ID,
  DELETE_LOAN_BY_ID,
  CREATE_AADHAR_VERIFICATION,
  AADHAR_VERIFICATION_OTP,
  RESET_VERIFICATION,
  UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID
} from "../action_Types";

export const getAllCompany = () => {
  return {
    type: GET_ALL_COMPANY,
  };
};

export const deleteCompany = (id) => {
  return {
    type: DELETE_COMPANY_BY_ID,
    payload: id,
  };
};

export const deleteLoan = (id) => {
  return {
    type: DELETE_LOAN_BY_ID,
    payload: id,
  };
};

export const addCompany = (data) => {
  return {
    type: ADD_COMPANY_DATA,
    payload: data,
  };
};

export const updateCompany = (id) => {
  return {
    type: UPDATE_COMPANY_BY_ID,
    payload: id,
  };
};

export const updateCompanyAaddharTokenLimit = (id) => {
  return {
    type: UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID,
    payload: id,
  };
};

export const aadharVerification = (data) => {
  return {
    type: CREATE_AADHAR_VERIFICATION,
    payload: data,
  };
};

export const aadharOtpVerification = (data) => {
  return {
    type: AADHAR_VERIFICATION_OTP,
    payload: data,
  };
};

export const resetVerification = () => {
  return {
    type: RESET_VERIFICATION,
  };
};

export const restver = ()=>{
  return{
    type : "reseeeet"
  }
}

export const aadharNumber = (data)=>{
  return{
    type : "aadharNumber",
    payload : data
  }
}