import React from 'react'
import { CFooter } from '@coreui/react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <CFooter>
            {/* <div>
                <Link to="/users" target="_self" rel="noopener noreferrer">
                    Vakilkaro
                </Link>
            </div>
            <div className="ms-auto">
                <span className="me-1">Powered by</span>
                <Link to="/users" target="_self" rel="noopener noreferrer">
                    Vakilkaro
                </Link>
            </div> */}
        </CFooter>
    )
}

export default React.memo(Footer)
