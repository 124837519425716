import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Protected({ Cmp }) {
  const navigate = useNavigate();
  let userData = sessionStorage.getItem("token");
  useEffect(() => {
    if (!userData) {
      navigate("/login");
    }
  }, []);
  return (
    <div>
      {Cmp && <Cmp />}
    </div>
  );
}
Protected.propTypes = {
  Cmp: PropTypes.func.isRequired,
};

export default Protected;
