import DataTable from "src/components/DataTable";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import Tooltip from "src/components/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompany } from "src/redux/actions/companyAction";

import ReactPaginate from "react-js-pagination";
import DeleteModal from "src/components/commonComponents/commonDeleteModal";
import CompanyAddAndUpdateForm from "src/components/Modal/editAndAddForm";
import StaffDetailsById from "../staffSection/staffDetailById";
import { Button } from "react-bootstrap";
import { getAllEmi } from "src/redux/actions/emiAction";

const Emi = ({ emiList, setShowEmi, totalAmountWithInterest }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state?.companyReducer);
  const [model, setModel] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editLoanData, setEditLoanData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [updateForm, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [companyModel, setCompanyModel] = useState(false);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const rowsPerPage = 10;

  useEffect(() => {
    dispatch(getAllEmi());
  }, []);
  const handleShow = () => setShowModal(true);
  const handleDelete = () => {
    setSelectedItemId(null);
  };

  const handleDeleteClick = (itemId) => {
    setSelectedItemId(itemId);

    setCompanyModel(true);
    handleShow();
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data?.data?.slice(indexOfFirstRow, indexOfLastRow);
  const handleEditLoan = (loanData) => {
    setUpdate(true);
    setEditLoanData(loanData);
    setModel("update");
  };
  const handleViedDetails = (company) => {
    setShowSelectAndSearch(false);
    setSelectedCompany(company);
    setShowAccordion(true);
  };

  const datas = [
    {
      id: 1,
      date: "12/09/2022",
      Base: 680,
      interest: 221,
      emi: 700,
      balance: 900,
    },
    {
      id: 2,
      date: "13/09/2022",
      Base: 700,
      interest: 230,
      emi: 720,
      balance: 880,
    },
    {
      id: 3,
      date: "14/09/2022",
      Base: 720,
      interest: 240,
      emi: 740,
      balance: 860,
    },
    {
      id: 4,
      date: "15/09/2022",
      Base: 740,
      interest: 250,
      emi: 760,
      balance: 840,
    },
    {
      id: 5,
      date: "16/09/2022",
      Base: 760,
      interest: 260,
      emi: 780,
      balance: 820,
    },
    {
      id: 6,
      date: "17/09/2022",
      Base: 780,
      interest: 270,
      emi: 800,
      balance: 800,
    },
    {
      id: 7,
      date: "18/09/2022",
      Base: 800,
      interest: 280,
      emi: 820,
      balance: 780,
    },
    {
      id: 8,
      date: "19/09/2022",
      Base: 820,
      interest: 290,
      emi: 840,
      balance: 760,
    },
    {
      id: 9,
      date: "20/09/2022",
      Base: 840,
      interest: 300,
      emi: 860,
      balance: 740,
    },
    {
      id: 10,
      date: "21/09/2022",
      Base: 860,
      interest: 310,
      emi: 880,
      balance: 720,
    },
    {
      id: 11,
      date: "22/09/2022",
      Base: 880,
      interest: 320,
      emi: 900,
      balance: 700,
    },
    {
      id: 12,
      date: "23/09/2022",
      Base: 900,
      interest: 330,
      emi: 920,
      balance: 680,
    },
    {
      id: 13,
      date: "24/09/2022",
      Base: 920,
      interest: 340,
      emi: 940,
      balance: 660,
    },
    {
      id: 14,
      date: "25/09/2022",
      Base: 940,
      interest: 350,
      emi: 960,
      balance: 640,
    },
    {
      id: 15,
      date: "26/09/2022",
      Base: 960,
      interest: 360,
      emi: 980,
      balance: 620,
    },
    {
      id: 16,
      date: "27/09/2022",
      Base: 980,
      interest: 370,
      emi: 1000,
      balance: 600,
    },
    {
      id: 17,
      date: "28/09/2022",
      Base: 1000,
      interest: 380,
      emi: 1020,
      balance: 580,
    },
    {
      id: 18,
      date: "29/09/2022",
      Base: 1020,
      interest: 390,
      emi: 1040,
      balance: 560,
    },
    {
      id: 19,
      date: "30/09/2022",
      Base: 100,
      interest: 400,
      emi: 160,
      balance: 540,
    },
    {
      id: 20,
      date: "01/10/2022",
      Base: 60,
      interest: 40,
      emi: 100,
      balance: 520,
    },
  ];
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (field) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };
  const handleCloseAccordian = () => {
    setShowEmi(false);
  };
  return (
    <>
      {/* <div className="close_btn">
        <div></div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => handleCloseAccordian()}
        ></button>
      </div> */}
      {/* <div
        style={{
          border: "0.5px solid rgb(195 181 181)",
          minHeight: "40px",
          display: "grid",
          alignItems: "center",
        }}
      >
        <CRow>
          <CCol
            style={{ fontSize: "16px", fontWeight: "700", paddingLeft: "20px" }}
            xs={3}
          >
            {/*   <CTableRow>
      <CTableHeaderCell scope="row" > */}
      {/* Number Of Payment : 15 */}
      {/*    </CTableHeaderCell>      </CTableRow> */}
      {/* </CCol>
          <CCol style={{ fontSize: "16px", fontWeight: "700" }} xs={3}> */}
      {/*   <CTableRow>
      <CTableHeaderCell scope="row" > */}
      {/* Total Amount To Pay : 37015 */}
      {/*    </CTableHeaderCell> </CTableRow> */}
      {/* </CCol>
          <CCol style={{ fontSize: "16px", fontWeight: "700" }} xs={3}> */}
      {/*   <CTableRow>
      <CTableHeaderCell scope="row" > */}
      {/* Pre Loan EMI Interest : 0 */}
      {/*    </CTableHeaderCell>
            </CTableRow> */}
      {/* </CCol>
          <CCol style={{ fontSize: "16px", fontWeight: "700" }} xs={3}> */}
      {/*   <CTableRow>
      <CTableHeaderCell scope="row" > */}
      {/* Pricipal Amount : 10000 */}
      {/*    </CTableHeaderCell>
            </CTableRow> */}
      {/* </CCol>
        </CRow>
      </div> */}
      <CRow className="pt-2">
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardBody className="table_emi_section">
              <div
                className={`table-container ${showAccordion ? "hidden" : ""}`}
              >
                <CTable hover className="" responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell
                        scope="col"
                        className="col-md-1"
                        onClick={() => handleSort("id")}
                      >
                        S. No.
                      </CTableHeaderCell>
                      <CTableHeaderCell
                        scope="col"
                        className="col-md-3"
                        onClick={() => handleSort("date")}
                      >
                        Date
                      </CTableHeaderCell>
                      <CTableHeaderCell
                        scope="col"
                        className="col-md-2"
                        onClick={() => handleSort("Base")}
                      >
                        Base
                      </CTableHeaderCell>
                      <CTableHeaderCell
                        scope="col"
                        className="col-md-2"
                        onClick={() => handleSort("interest")}
                      >
                        Interest
                      </CTableHeaderCell>
                      <CTableHeaderCell
                        scope="col"
                        className="col-md-2"
                        onClick={() => handleSort("emi")}
                      >
                        EMI
                      </CTableHeaderCell>
                      <CTableHeaderCell
                        scope="col"
                        className="col-md-3"
                        onClick={() => handleSort("balance")}
                      >
                        Per Month Pay
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {/* {data?.data && data?.data.length > 0 ? ( */}
                    {emiList
                      ?.sort((a, b) => {
                        if (sortOrder === "asc") {
                          return a[sortField] > b[sortField] ? 1 : -1;
                        } else {
                          return a[sortField] < b[sortField] ? 1 : -1;
                        }
                      })
                      ?.map((company, index) => (
                        <CTableRow key={index}>
                          <CTableHeaderCell scope="row">
                            {indexOfFirstRow + index + 1}
                          </CTableHeaderCell>
                          <CTableDataCell>01/01/2022</CTableDataCell>
                          <CTableDataCell>
                            {/*680 */}
                            {company?.principalPayment}
                          </CTableDataCell>
                          <CTableDataCell>
                            {/* 221  */}
                            {company?.interestPayment}
                          </CTableDataCell>
                          <CTableDataCell>
                            {/*  901 */}
                            {company?.emi}
                          </CTableDataCell>
                          <CTableDataCell>
                            {company?.interestPayment +
                              company?.principalPayment +
                              company?.emi}
                            {/* 9520 */}
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    {/*  ) : (
                      // Display a loading message or handle the case where data is not available
                      <div className="loading_data">Loading...</div>
                    )} */}
                  </CTableBody>
                </CTable>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "20px",
                    margin: "16px 0px 0px 10px",
                  }}
                >
                  {/* <div>
                    Showing {indexOfFirstRow + 1} to{" "}
                    {Math?.min(indexOfLastRow, data?.data?.length)} of{" "}
                    {data?.data?.length} entries
                  </div> */}
                  {/* <CButton color="info">Back</CButton>
                  <div className="pagination-container">
                    <CButton color="danger">Cancel</CButton>
                  </div> */}
                </div>
              </div>
              {showAccordion && (
                <StaffDetailsById
                  selectedCompany={selectedCompany}
                  setShowAccordion={setShowAccordion}
                  setShowSelectAndSearch={setShowSelectAndSearch}
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <div
            className={`accordion-container ${showAccordion ? "visible" : ""}`}
          ></div>
        </CCol>
      </CRow>
    </>
  );
};
Emi.propTypes = {
  emiList: PropTypes.array.isRequired,
  totalAmountWithInterest: PropTypes.array.isRequired,
  setShowEmi: PropTypes.func.isRequired,
};

export default Emi;
