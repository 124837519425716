import React from "react";
import PropTypes from "prop-types";
import { Page, Text, Document, View, StyleSheet } from "@react-pdf/renderer";
import { Table } from "react-bootstrap";

const Styles = StyleSheet.create({
  page: {

  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  general_section: {
    display: "flex",
    justifyContent: 'space-between'
  },
  general_right: {
    display: "flex",
    flexDirection: 'column',
    fontSize: 12
  },
  form_heading: {
    paddingTop: '3%',
    fontSize: 14
  },
  body_text: {
    fontSize: 12,
    lineHeight: .5
  },
  accept: {
    fontSize: 12,
    fontWeight: 700,
    marginTop: '5%'
  },
  prepayment: {
    border: 1,
    borderWidth: 2,
    borderColor: '#000000'
  }
})

const PrintSantionLetterForm = ({ setPrintSantialLetter, cuData }) => {
  return (
    <div>
      <div className="close_btn">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setPrintSantialLetter(false)}
        ></button>
      </div>
      <div className="sanction_latter">
        {/* <PDFViewer> */}
        <Document>
          <Page size="A4" style={Styles.page}>
            <View style={Styles.general_section}>
              <View style={Styles.general_right}>
                <Text>Ref No. : {cuData.loanAccount}</Text>
                <Text>{cuData.firstName} {cuData.middleName} {cuData.lastName}</Text>
                <Text>18 MUKHYA NAGAR AJMER ROAD<br />
                  MADARAMPURA JAIPUR, JAIPUR,Rajasthan,302006
                </Text>
                <Text>Email Id : satendersaini11@gmail.com</Text>
                <Text>Mobile No.: 9828124053</Text>
                <Text>Date : 14-Dec-2022</Text>
              </View>
              <View>
                Vakilkaro
              </View>
            </View>
            <View style={Styles.general_section}>
              <Text style={Styles.form_heading}>Dear Customer,</Text>
            </View>
            <View style={Styles.body}>
              <Text style={Styles.body_text} >
                {/* {CompanyName} */}
                &nbsp; &nbsp;&nbsp;&nbsp;{` Hero Housing Finance Limited ("HHFL") is pleased to inform you that basis the Application submitted by you, HHFL approved your loan
                facility as per below mentioned terms and conditions:`}
              </Text>
            </View>
            <View>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th width="30%">Particular</th>
                    <th >Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Loan Type</td>
                    <td>Home Loan</td>
                  </tr>
                  <tr>
                    <td>Loan Amount Approved </td>
                    <td>40,80,000/- </td>
                  </tr>
                  <tr>
                    <td >Tenor (in Months)</td>
                    <td>240</td>
                  </tr>
                  <tr>
                    <td >Rate Of Interest (ROI)</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td >EMI (Installment Amount)</td>
                    <td>483</td>
                  </tr>
                  <tr>
                    <td >EMI Due Date</td>
                    <td>22/11/2023</td>
                  </tr>
                  <tr>
                    <td >Tenor (in Months)</td>
                    <td>240</td>
                  </tr>
                  <tr>
                    <td >Processing Charges</td>
                    <td>12000</td>
                  </tr>
                </tbody>
              </Table>
            </View>
            <View>
              <Text style={Styles.accept}>
                Agreed and Accepted by the Borrower
              </Text><br />
              <Text style={Styles.body_text} >
                {`I/We confirm that we understand and accept the terms and conditions of the Sanction Letter, as laid out in English/ have been explained to
                  us in a vernacular language of our understanding and I/ We accept the Facility on the terms set above`}
              </Text>
            </View><br />
            <View style={Styles.prepayment}>
              <Text style={Styles.accept}>Prepayment Charges</Text>
              <Text style={Styles.body_text} ><br />
                {`For individual borrowers (Floating rate) : Nil `}< br />
                {`For Non individual Borrowers (Floating rate) : 0.00%+ Applicable tax on amount prepaid and on all amounts tendered by the Borrower
                  towards Prepayment of the Facility during the last one year from the date of final prepayment. In case, prepayment of loan is from own
                  source of funds ( except borrowing from bank / HFC/ NBFC/ any Financial Institution ), no fee is applicable.
                  `}< br />
                {`For Mixed Rates, Prepayment charges would be applicable as per fixed rate of interest in accordance to the schedule of charges , if the
                  loan is closed within the fixed tenure . There after prepayment to be charged as per the floating rate of interest charges.
                  `}
              </Text>
            </View>
            <View>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th colSpan={2}>Particular</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Required Copy of receipt issued by Bhairav Grah Nirman Sahkari Samiti Ltd Jaipur in favor of Mrs. Choti Devi w/o Mr. Babu Lal
                      Jangid.</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      Required Original undertaking regarding JDA lease deed is not issued till data and in further lease deed is issued same is deposit in
                      HHFL and no any litigation pending on said property
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      Required Original society verification letter issued by Bhairav Grah Nirman sahakri samiti ltd.
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>FCU should be found positive</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Technical report should be found positive &amp; vet from internal team</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Electricity bill to be in name of Property owner.</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>SOA of Auto loan of Rs. 5.50 Lakhs running in the name of Mr. Satender Saini to be taken in record.</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Mr Satender Saini SPDC &amp; BSV to be taken in record.</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>Repayment from Applicant Saving bank SBI a/c required and BSV required</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>OCR required via banking channel from Purchase to seller bank account &amp; proof to be taken in record before disbursal</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>Seller KYC, Cibil seller Memo and banking to be taken in record.</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>Cibil consent letter to be taken in record and Seller Cibil to be found positive.</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>Property Should be vacant before reg
                    </td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td> Seller Fcu to be documented
                    </td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>FCU OF COMPANY FINANCIAL TO BE DOCUMENTED</td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td>Repayment to be taken from JYOTI SAINI SBI-STATE BANK OF INDIA Account 51109247299.</td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td>Insurance amount mentioned in the sanction letter is towards property insurance and life insurance</td>
                  </tr>
                </tbody>
              </Table>

            </View>

          </Page>
        </Document>
        {/* </PDFViewer> */}
      </div>
    </div>
  );
};
PrintSantionLetterForm.propTypes = {
  setPrintSantialLetter: PropTypes.bool.isRequired,
  cuData: PropTypes.object.isRequired,
};

export default PrintSantionLetterForm;
