import React, { useEffect } from "react";
import './scss/main.scss'
import Route from "./route.js";
import DisableDevtool from 'disable-devtool';

const App = () => {

  useEffect(() => {
    DisableDevtool();
  }, [])

  return <Route />;
};

export default App;
