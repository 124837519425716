import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { addAccount, getAllAccount } from "src/redux/accounting/action";
import { useSelector } from "react-redux";
import { errorAlert, successAlert } from "src/utils/Swal";

const AddMasterData = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const { newAccountData, error } = useSelector((state) => state?.acountingReducer);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedEntryValue, setSelectedEntryValue] = useState("");
  const [selectedDCValue, setSelectedDCValue] = useState("");
  const [accountName, setAccountName] = useState("");
  const schemaName = sessionStorage.getItem("schemaName");

  const handleCheckboxChange = (value) => {
    if (selectedValue === value) {
      setSelectedValue("");
    } else {
      setSelectedValue(value);
    }
  };
  const handleEntryCheckboxChange = (value) => {
    if (selectedEntryValue === value) {
      setSelectedEntryValue("");
    } else {
      setSelectedEntryValue(value);
    }
  };
  const handleDCCheckboxChange = (value) => {
    if (selectedDCValue === value) {
      setSelectedDCValue("");
    } else {
      setSelectedDCValue(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = {
      schemaName: schemaName,
      accountName: accountName,
      accountType: "real",
      accountEntryType: selectedEntryValue,
      debitCredit: selectedDCValue,
    };
    dispatch(addAccount(obj));
    setAccountName('');
    setSelectedEntryValue("");
    setSelectedDCValue('')
  };

  useEffect(() => {
    if (error === false) {
      if (newAccountData?.status === "success") {
        successAlert("Success", "New Account has been Created");
        setVisible(false);
      } else if (newAccountData?.status === "failed") {
        errorAlert("Failed");
      }
    }
  }, [newAccountData]);

  return (
    <CModal
      backdrop="static"
      visible={visible}
      onClose={() => setVisible(false)}
      aria-labelledby="StaticBackdropExampleLabel"
    >
      <CForm onSubmit={(e) => handleSubmit(e)}>
        <CModalHeader>
          <CModalTitle id="StaticBackdropExampleLabel">Add Data</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs={8}>
              <CFormLabel className="f_lable">A/C Name</CFormLabel>
              <CFormInput
                placeholder="A/C name"
                type="text"
                required
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
              />
            </CCol>
          </CRow>
          {/* <CRow> */}
          {/* <CCol xs={8}>
              <CFormLabel className="f_lable">A/C Type</CFormLabel>
              <div className="ac_type">
                {["real", "nominal", "personal"].map((type) => (
                  <CFormCheck
                    key={type}
                    type="checkbox"
                    id={type}
                    label={type.charAt(0).toUpperCase() + type.slice(1)}
                    onChange={() => handleCheckboxChange(type)}
                    checked={selectedValue === type}
                  />
                ))}
              </div>
            </CCol> */}
          {/* </CRow> */}
          <CRow>
            <CCol xs={8}>
              <CFormLabel className="f_lable">A/C Entry Type</CFormLabel>
              <div className="ac_type">
                {["trading", "profit&Loss", "balanceSheet"].map((type) => (
                  <CFormCheck
                    key={type}
                    type="checkbox"
                    id={type}
                    label={type.charAt(0).toUpperCase() + type.slice(1)}
                    onChange={() => handleEntryCheckboxChange(type)}
                    checked={selectedEntryValue === type}
                  />
                ))}
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={8}>
              <CFormLabel className="f_lable">Debit/Credit</CFormLabel>
              <div className="ac_type">
                {["debit", "credit"].map((type) => (
                  <CFormCheck
                    key={type}
                    type="checkbox"
                    id={type}
                    label={type.charAt(0).toUpperCase() + type.slice(1)}
                    onChange={() => handleDCCheckboxChange(type)}
                    checked={selectedDCValue === type}
                  />
                ))}
              </div>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" type="submit">
            Submit
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
};

AddMasterData.propTypes = {
  setVisible: PropTypes.func,
  visible: PropTypes.bool,
};

export default AddMasterData;
