import React, { useEffect } from "react";
import {
  CModal,
  CButton,
  CModalBody,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";


const CustomerEditForm = ({ visible, setVisible, customerData, updateForm }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = (data) => {
    if (updateForm) {
      const updatedData = {
        id: customerData.id,
        data: {
          firstName: data.planAmount,
          email: data.email,
          mobile: data.planType,
        },
      };
    }
  };

  useEffect(() => {
    if (customerData) {
      setValue("planName", customerData.planName);
      setValue("planAmount", customerData.planAmount);
      setValue("recovery", customerData.recovery);
      setValue("planType", customerData.planType);
      setValue("planStatus", customerData.planStatus);
    }
  }, [customerData]);

  return (
    <>
      <CModal
        backdrop="static"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="StaticBackdropExampleLabel"
      >
        <CModalHeader>
          <CModalTitle id="StaticBackdropExampleLabel">
            Edit Customer data
          </CModalTitle>
        </CModalHeader>

        <CModalBody>
          <CContainer>
            <CForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <label htmlFor="fname" className="mb-1">
                Customer Name
              </label>
              <CInputGroup className="mb-1 d-flex col-1">
                <CFormInput
                  type="text"

                  name="planName"
                  required
                  defaultValue={customerData ? customerData.planName : ""}
                  {...register("name", { required: true })}
                />
              </CInputGroup>
              <label htmlFor="fname" className="mb-1">
                Email
              </label>
              <CInputGroup className="mb-1 d-flex col-1">
                <CFormInput
                  type="text"

                  name="planName"
                  required
                  defaultValue={customerData ? customerData.planName : ""}
                  {...register("email", { required: true })}
                />
              </CInputGroup>
              <label htmlFor="planAmount" className="mb-1">
                Mobile Number
              </label>
              <CInputGroup className="mb-1">
                <CFormInput
                  type="number"
                  autoComplete="on"
                  name="planAmount"
                  required
                  defaultValue={customerData ? customerData.planAmount : ""}
                  {...register("mobile", { required: true })}
                />
              </CInputGroup>

              <CButton type="submit" color="light">Save</CButton>
            </CForm>
          </CContainer>
        </CModalBody>
      </CModal>
    </>
  );
};

CustomerEditForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  updateForm: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  customerData: PropTypes.object,
  onSubmit: PropTypes.func
};

export default CustomerEditForm;
