import {
  GET_ALL_INTERESTS_SUCCESS,
  ERROR,
  ADD_INTERESTS_DATA_SUCCESS,
  DELETE_INTERESTS_BY_ID_SUCCESS,
  EDIT_INTERESTS_DATA_SUCCESS,
  REFRESH_INTERESTLIST,
} from "../action_Types";

const initialState = {
  data: null,
  message: "",
  error: false,
  addData: null,
  updateData: false,
  productUpdatedData: null,
};

const interestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_INTERESTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        update: true,
      };

    case ADD_INTERESTS_DATA_SUCCESS:
      return {
        ...state,
        addData: action.payload,
        error: false,
      };

    case REFRESH_INTERESTLIST:
      return {
        ...state,
        addData: null,
        error: false,
      };
    case EDIT_INTERESTS_DATA_SUCCESS:
      return {
        ...state,
        updateData: true,
        productUpdatedData: action.payload,
        error: false,
      };
    case DELETE_INTERESTS_BY_ID_SUCCESS:
      return {
        ...state,
        addData: true,
        error: false,
      };
    case ERROR:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default interestsReducer;
