import {
  CCard,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React from "react";
const ProfitAndLossList = () => {
  return (
    <div className="content">
      <CCard>
      <CTable striped hover>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">
              Particulars\Expenses
            </CTableHeaderCell>
            <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
            <CTableHeaderCell scope="col">Particular\Revenues</CTableHeaderCell>
            <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow>
            <CTableDataCell>
              <div className="p_l_data">
                <span>to gross loss b/d</span>
                <span>hello</span>
                <span>hello</span>
                <span>hello</span>
              </div>
            </CTableDataCell>
            <CTableDataCell>
              <div className="p_l_data">
                <span>*****</span>
                <span>*****</span>
                <span>*****</span>
                <span>*****</span>
              </div>
            </CTableDataCell>
            <CTableDataCell>
              <div className="p_l_data">
                <span>by Gross profit b/d</span>
                <span>hello</span>
                <span>hello</span>
                <span>hello</span>
              </div>
            </CTableDataCell>
            <CTableDataCell>
              <div className="p_l_data">
                <span>*****</span>
                <span>*****</span>
                <span>*****</span>
                <span>*****</span>
              </div>
            </CTableDataCell>
          </CTableRow>
          <CTableRow>
            <CTableDataCell></CTableDataCell>
            <CTableDataCell>
              <div className="p_l_data"></div>
            </CTableDataCell>
            <CTableDataCell></CTableDataCell>
            <CTableDataCell>
              <div className="p_l_data"></div>
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
      </CCard>
     
    </div>
  );
};

export default ProfitAndLossList;
