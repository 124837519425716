import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CModal,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFile, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-js-pagination";
import ViewDetails from "../loanDetails";
import { getAllCustomers } from "src/api/allApis";
import AddForm from "src/pages/customer/addForm";
import { useReactToPrint } from "react-to-print";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { CSVLink } from "react-csv";
import { Tooltip } from "react-bootstrap";
import AddCustomerGroup from "./updateCustomerGroup";
import { getAllGroup } from "src/redux/actions/userAction";
import { COMPANY_NAME } from "src/services/const";

const CustomerData = () => {
  const dispatch = useDispatch();
  const { AllGroups } = useSelector((state) => state?.userReducer);

  const [currentPage, setCurrentPage] = useState(1);
  const [showLoanSummary, setShowLoanSummary] = useState(false);
  const [showCustomerAddForm, setShowCustomerAddForm] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customersPendingStatus, setCustomersPendingStatus] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [customerFilter, setCustomerFilter] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchloanAccunt, setSearchloanAccunt] = useState("");
  const [printState, setPrintState] = useState(false);
  const SchemaName = sessionStorage.getItem("schemaName");
  const getEmployeeId = sessionStorage.getItem("emplyeeId");
  const employeeRole = sessionStorage.getItem("role");
  const [filteredRows, setFilteredRows] = useState([]);
  const [visibleCustomerGroup, setVisibleCustomerGroup] = useState(false);
  const [activeCustomerid, setActiveCustomerid] = useState(0);

  const getAllCompanyData = () => {
    const payload = { schemaName: SchemaName };
    getAllCustomers(payload).then((res) => {
      setCustomersPendingStatus(res?.data);
    });
  };

  useEffect(() => {
    getAllCompanyData();
  }, []);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  useEffect(() => {
    if (employeeRole == "agent") {
      const CustomerFilterData = customersPendingStatus?.filter(
        (m) => m?.employeeId === Number(getEmployeeId)
      );
      setCustomerFilter(CustomerFilterData);
    } else {
      setCustomerFilter(customersPendingStatus);
    }
  }, [customersPendingStatus]);
  useEffect(() => {
    const filterRows = () => {
      if (Array.isArray(customerFilter)) {
        const filteredData = customerFilter?.filter((customer) => {
          const aadharNumber = customer?.aadharNumber?.toString().toLowerCase();
          const loanAccountNumber = customer?.loanAccountNumber
            ?.toString()
            .toLowerCase();
          const searchTerm = searchInput.toLowerCase();
          const searchLoanAccount = searchloanAccunt.toLowerCase();
          return (
            (aadharNumber.includes(searchTerm) && !searchLoanAccount) ||
            (loanAccountNumber.includes(searchLoanAccount) && !searchTerm) ||
            (aadharNumber.includes(searchTerm) &&
              loanAccountNumber.includes(searchLoanAccount))
          );
        });
        setFilteredRows(filteredData);
      } else {
        setFilteredRows([]);
      }
    };

    if (searchInput || searchloanAccunt) {
      filterRows();
    } else {
      setFilteredRows(customerFilter);
    }

    return () => {
      setFilteredRows([]);
    };
  }, [customerFilter, searchInput, searchloanAccunt]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredRows?.slice(indexOfFirstRow, indexOfLastRow);
  const handleViewForm = (customerdata) => {
    setCustomerData(customerdata);
    const getData = {
      schemaName: SchemaName,
    };
    // dispatch(showLoanSummaryWithCustomerDetails(getData));
    setShowLoanSummary(true);
  };
  const handleAddForm = (customerdata) => {
    setCustomerData(customerdata);
    setShowCustomerAddForm(true);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchLoanAccount = (e) => {
    setSearchloanAccunt(e.target.value);
  };

  const componentPDF = useRef();

  const download_btn = () => {
    setPrintState(true);
  };
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const handleEdit = () => {
    setShowCustomerAddForm(true);
  }
  useEffect(() => {
    const objData = {
      schemaName: SchemaName,
    };
    dispatch(getAllGroup(objData));
  }, [SchemaName, dispatch]);
  return (
    <>
      <CRow className="content">
        <CCol xs={12}>
          {showCustomerAddForm && (
            <>
              <CModal
                className="fullPageModal"
                backdrop="static"
                visible={showCustomerAddForm}
                onClose={() => setShowCustomerAddForm(false)}
                aria-labelledby="StaticBackdropExampleLabel"
                size="xl"
              >
                <AddForm
                  customerData={customerData}
                  showCustomerAddForm={showCustomerAddForm}
                  setShowCustomerAddForm={setShowCustomerAddForm}
                  getAllCompanyData={getAllCompanyData}
                />
              </CModal>
            </>)}
          {showLoanSummary ? (
            <ViewDetails
              customerData={customerData}
              setShowLoanSummary={setShowLoanSummary}
              showLoanSummary={showLoanSummary}
            />
          ) : (
            <>
              <CRow>
                <CCol xs={12}>
                  <CCard>
                    <CCardHeader>
                      <strong>Customer Details</strong>
                    </CCardHeader>
                    <CCardBody>
                      <CRow>
                        <CCol xs={12}>
                          <CCard>
                            <div className="search_bar">
                              <div className="search-inner">
                                {showSelectAndSearch && (
                                  <div>
                                    <CFormSelect
                                      onChange={handleRowsPerPageChange}
                                      size="sm"
                                      className="mb-3"
                                      style={{ cursor: "pointer" }}
                                      aria-label="Small select example"
                                    >
                                      <option value="10">10</option>
                                      <option value="20">20</option>
                                      <option value="30">30</option>
                                    </CFormSelect>
                                  </div>
                                )}

                                <div style={{ display: "flex" }}>
                                  {showSelectAndSearch && (
                                    <>
                                      <CFormInput
                                        type="text"
                                        size="sm"
                                        placeholder="Enter Aadhar Number"
                                        aria-label="sm input example"
                                        style={{ margin: "0px 0px 16px 0px" }}
                                        onChange={handleSearchChange}
                                        value={searchInput}
                                      />
                                      <span
                                        style={{
                                          border: " 1px solid #d1c5c5",
                                          borderRadius: "4px",
                                          margin: "0px 5px 16px -1px",
                                          padding: "1px 8px 0px 8px",
                                          background: "#ebedef",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <i className="fa fa-search"></i>
                                      </span>
                                    </>
                                  )}
                                  {showSelectAndSearch && (
                                    <>
                                      <CFormInput
                                        type="text"
                                        size="sm"
                                        placeholder="Enter Loan Account Number"
                                        aria-label="sm input example"
                                        style={{ margin: "0px 0px 16px 0px" }}
                                        onChange={handleSearchLoanAccount}
                                        value={searchloanAccunt}
                                      />
                                      <span
                                        style={{
                                          border: " 1px solid #d1c5c5",
                                          borderRadius: "4px",
                                          margin: "0px 5px 16px -1px",
                                          padding: "1px 8px 0px 8px",
                                          background: "#ebedef",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <i className="fa fa-search"></i>
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                              {/* //btn div */}
                              <div className="btn_add mt-0 mb-14">
                                <CButton
                                  className="add-btn mr-10 w-auto"
                                  onClick={download_btn}
                                >
                                  Download
                                </CButton>
                                <CSVLink data={currentRows === undefined ? " " : currentRows} filename={"customer-details.csv"}>
                                  <CButton className="add-btn w-auto m-0">
                                    CSV
                                  </CButton>
                                </CSVLink>
                              </div>
                            </div>
                            <CCardBody>
                              <div>
                                <div
                                  ref={printState ? componentPDF : null}
                                  style={{
                                    padding: printState ? "20px" : "0px",
                                  }}
                                >
                                  {printState && (
                                    <div className="print-div">
                                      <div>
                                        <Vakilkaro />
                                      </div>
                                      <div>
                                        <p>{COMPANY_NAME}</p>
                                      </div>
                                    </div>
                                  )}
                                  <CTable
                                    color="dark"
                                    hover
                                    className=""
                                    responsive
                                  >
                                    <CTableHead>
                                      <CTableRow>
                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-1"
                                        >
                                          S. No.
                                        </CTableHeaderCell>
                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-1"
                                        >
                                          Loan ID
                                        </CTableHeaderCell>
                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-2"
                                        >
                                          Member Name
                                        </CTableHeaderCell>

                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-2"
                                        >
                                          Branch Name
                                        </CTableHeaderCell>

                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-2"
                                        >
                                          Mobile
                                        </CTableHeaderCell>
                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-2"
                                        >
                                          Amount
                                        </CTableHeaderCell>
                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-1"
                                        >
                                          Add in Group Loan
                                        </CTableHeaderCell>
                                        {!printState && (
                                          <CTableHeaderCell
                                            scope="col"
                                            className="col-md-1"
                                          >
                                            Action
                                          </CTableHeaderCell>
                                        )}
                                        {!printState && (
                                          <CTableHeaderCell
                                            scope="col"
                                            className="col-md-2"
                                          >
                                            Edit
                                          </CTableHeaderCell>
                                        )}
                                      </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                      {Array.isArray(filteredRows) &&
                                        currentRows?.map((company, index) => (
                                          <CTableRow key={index}>
                                            <CTableDataCell>
                                              {indexOfFirstRow + index + 1}
                                            </CTableDataCell>
                                            <CTableDataCell>
                                              {company?.loanAccountNumber}
                                            </CTableDataCell>
                                            <CTableDataCell>
                                              {company?.firstName}{" "}
                                              {company?.middleName ===
                                                "undefined"
                                                ? ""
                                                : company?.middleName}{" "}
                                              {company?.lastName}
                                            </CTableDataCell>
                                            <CTableDataCell>
                                              {company?.branch?.branchName}
                                            </CTableDataCell>
                                            <CTableDataCell>
                                              {company?.phoneNumber}
                                            </CTableDataCell>
                                            <CTableDataCell>
                                              {company?.loanAmountRequested}
                                            </CTableDataCell>
                                            <CTableDataCell className="col-md-1"
                                              onClick={() => {
                                                setVisibleCustomerGroup(true);
                                                setActiveCustomerid(company?.id);
                                              }}
                                            >
                                              <span style={{
                                                display: "flex", alignItems: "center",
                                                justifyContent: "center", cursor: "pointer"
                                              }}>
                                                {company?.groupid || "-"}
                                                <Tooltip text="Add Aadhaar Token Update">
                                                  <FontAwesomeIcon
                                                    role="button"
                                                    icon={faPenToSquare}
                                                    size="1x"
                                                    className="px-3 updateIcon"
                                                  />
                                                </Tooltip>
                                              </span>
                                            </CTableDataCell>
                                            {!printState && (
                                              <CTableDataCell className="col-md-1">
                                                <button
                                                  style={{ gap: "2px" }}
                                                  className="view-button btn_view margin_auto"
                                                  onClick={() =>
                                                    handleViewForm(company)
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    role="button"
                                                    icon={faEye}
                                                    size="1x"
                                                    className="viewIcon "
                                                  />
                                                  Proceed
                                                </button>
                                              </CTableDataCell>
                                            )}
                                            {!printState && (
                                              <CTableDataCell className="col-md-2">
                                                <button
                                                  style={{ gap: "2px" }}
                                                  className="view-button btn_view margin_auto"
                                                  onClick={() =>
                                                    handleAddForm(company)
                                                  }

                                                >
                                                  <FontAwesomeIcon
                                                    role="button"
                                                    icon={faFile}
                                                    size="1x"
                                                    className="viewIcon "
                                                  // onClick={()=>handleEdit(company)}
                                                  />
                                                  Edit
                                                </button>
                                              </CTableDataCell>
                                            )}
                                          </CTableRow>
                                        ))}
                                    </CTableBody>
                                  </CTable>
                                </div>

                                <div className="pagin-div">
                                  <div
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Showing {indexOfFirstRow + 1} to{" "}
                                    {Math?.min(
                                      indexOfLastRow,
                                      customerFilter?.length
                                    )}{" "}
                                    of {customerFilter?.length} entries
                                  </div>
                                  <div className="pagination-container">
                                    <ReactPaginate
                                      activePage={currentPage}
                                      itemsCountPerPage={rowsPerPage}
                                      totalItemsCount={filteredRows?.length}
                                      pageRangeDisplayed={5}
                                      onChange={handlePageChange}
                                      prevPageText="Previous"
                                      nextPageText="Next"
                                      firstPageText="First"
                                      lastPageText="Last"
                                      innerClass="pagination"
                                      itemClass="page-item"
                                      linkClass="page-link"
                                    />
                                  </div>
                                </div>
                              </div>
                            </CCardBody>
                          </CCard>
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </>
          )}
        </CCol>
        <CCol xs={12}></CCol>
        {
          visibleCustomerGroup &&
          <AddCustomerGroup
            setVisibleCustomerGroup={setVisibleCustomerGroup}
            visibleCustomerGroup={visibleCustomerGroup}
            activeCustomerid={activeCustomerid}
            setActiveCustomerid={setActiveCustomerid}
            AllGroupsLoan={AllGroups?.data}
            getAllCompanyData={getAllCompanyData}
          />
        }
      </CRow>
    </>
  );
};

export default CustomerData;
