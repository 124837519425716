import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
const AadharPanVerify = ({
  showTabs,
  showPan,
  showPanOtp,
  handlePanOtpVerify,
  handlePanVerify,
  handleOtpVerify,
  handleAadharVerify,
  setAadhar,
  aadhar,
  aadharOtp,
  setAadharOtp,
}) => {
  return (
    <>
      {
        // !showTabs && (
        <div >
          <CCard className="d-flex justify-content-center">
            <CCardBody>
              {/* <CCard style={{backgroundColor:'red',  }}> */}
              {/* <CCardBody> */}
              <CForm
                autoComplete="off"
                onSubmit={(e) => handleAadharVerify(e)}
              >
                <CRow className="pt-20 centr_align ">
                  {/* <CCol xs={3}></CCol> */}
                  <CCol xs={6}
                  // className="position-relative"
                  >
                    <div className="mb-3 rectangle-outline">
                      AADHAR FINGERPRINT
                    </div>

                    <div className="mb-2">
                      <img
                        src="/fingerprint.png"
                        alt="aadharimage"
                        height="50px"
                      />
                    </div>
                    <CFormLabel htmlFor="aadhar">
                      Aadhaar Number{" "}
                      <span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      id="aadhar"
                      placeholder="Enter Aadhar Number"
                      name="aadhar"
                      maxLength={12}
                      minLength={12}
                      value={aadhar}
                      onChange={(e) => setAadhar(e.target.value)}
                      required
                    />
                  </CCol>
                </CRow>
                {!showPan && (
                  <CRow className="pt-20 centr_align">
                    {/* <CCol xs={6}></CCol> */}
                    <CCol xs={12} className="centr_align">
                      <CButton type="submit">
                        Verify
                      </CButton>
                    </CCol>
                  </CRow>
                )}
              </CForm>

              {showPan && (
                <CForm onSubmit={(e) => handleOtpVerify(e)}>
                  <CRow className="centr_align">
                    <CCol
                      xs={6}

                      style={{ margin: "23px 0px 0px 0px" }}
                    >
                      <CFormLabel htmlFor="userName">
                        OTP
                        <span className="red-star">{` `}*</span>
                      </CFormLabel>
                      <CFormInput
                        type="number"
                        value={aadharOtp}
                        id="aadhar"
                        placeholder=" Enter OTP "
                        onChange={(e) => setAadharOtp(e.target.value)}
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="pt-20 centr_align">
                    <CCol xs={12} className="mb-3 centr_align">
                      <CButton
                        //  style={{ width: "50%" }} 
                        type="submit">
                        Verify
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              )}

              {/* </CCardBody> */}
              {/* </CCard> */}
            </CCardBody>
          </CCard></div>

        // <CCard>
        //   <CCardBody>
        //     <CForm autoCompl ete="off" onSubmit={(e) => handlePanVerify(e)}>
        //       <CRow className="pt-20 ">
        //         <CCol xs={3}></CCol>
        //         <CCol xs={6}>
        //           <div className="mb-3">
        //             <div className="mb-3 rectangle-outline">PAN NUMBER </div>
        //           </div>
        //           <div className="mb-2">
        //             <img src="/pan.jpg" alt="panimage" height="50px" />
        //           </div>
        //           <CFormLabel htmlFor="password">
        //             Enter PAN Number
        //             <span className="red-star">{` `}*</span>
        //           </CFormLabel>
        //           <CFormInput
        //             type="text"
        //             id="PanNumber"
        //             // name="PanNumber"
        //             // value={PanNumber}
        //             placeholder="PAN Number"
        //             required
        //           />
        //         </CCol>
        //       </CRow>
        //       <CRow className="pt-20 ">
        //         <CCol xs={6}></CCol>
        //         <CCol xs={5}>
        //           <CButton style={{ width: "50%" }} type="submit">
        //             Verify
        //           </CButton>
        //         </CCol>
        //       </CRow>
        //     </CForm>
        //     {showPanOtp && (
        //       <div>
        //         <CForm onSubmit={(e) => handlePanOtpVerify(e)}>
        //           <CRow>
        //             <CCol xs={5}></CCol>
        //             <CCol
        //               xs={3}
        //               className="position-relative"
        //               style={{ margin: "23px 0px 0px 0px" }}
        //             >
        //               <CFormLabel htmlFor="panNumber">
        //                 Enter Your Otp Here
        //                 <span className="red-star">{` `}*</span>
        //               </CFormLabel>
        //               <CFormInput
        //                 type="number"
        //                 // id="panNumber"
        //                 placeholder=" enter otp "
        //                 // name="aadharNumber"
        //                 // value={aadharNumber}
        //                 // onChange={onChangeAadhar}
        //                 required
        //               />
        //             </CCol>
        //           </CRow>
        //           <CRow className="pt-20 ">
        //             <CCol xs={6}></CCol>
        //             <CCol xs={3}>
        //               <CButton style={{ width: "50%" }} type="submit">
        //                 Verify
        //               </CButton>
        //             </CCol>
        //           </CRow>
        //         </CForm>
        //       </div>
        //     )}
        //   </CCardBody>
        // </CCard>
      }
    </>
  );
};

AadharPanVerify.propTypes = {
  handlePanOtpVerify: PropTypes.func,
  handlePanVerify: PropTypes.func,
  setAadhar: PropTypes.func,
  setAadharOtp: PropTypes.func,
  handleOtpVerify: PropTypes.func,
  handleAadharVerify: PropTypes.func,
  showTabs: PropTypes.bool,
  aadhar: PropTypes.number,
  aadharOtp: PropTypes.number,
  showPan: PropTypes.bool,
  showPanOtp: PropTypes.bool,
};

export default AadharPanVerify;
