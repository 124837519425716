import { put, takeLatest, call } from "redux-saga/effects";
import authInstance from "../../api/apiConfig";
import {
  ERROR,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER,
  ADD_USER_DATA,
  DELETE_USER_BY_ID,
  UPDATE_USER_BY_ID,
  ADD_USER_DATA_SUCCESS,
  DELETE_USER_BY_ID_SUCCESS,
  UPDATE_USER_BY_ID_SUCCESS,
  UPDATE_CUSTOMER_STATUS_SUCCESS,
  UPDATE_CUSTOMER_STATUS,
  UPLOAD_SANCTION_LETTER_SUCCESS,
  UPLOAD_SANCTION_LETTER,
  GET_ALL_BRANCHES_SUCCESS,
  GET_ALL_BRANCHES,
  CREATE_BRANCHES,
  CREATE_BRANCHES_SUCCESS,
  GET_ALL_DIRECTORS,
  GET_ALL_DIRECTORS_SUCCESS,
  CREATE_DIRECTORS,
  CREATE_DIRECTORS_SUCCESS,
  CREATE_CAPITALS,
  GET_ALL_CAPITALS,
  GET_ALL_CAPITALS_SUCCESS,
  CREATE_CAPITALS_SUCCESS,
  UPDATE_CUSTOMER_BY_ID,
  UPDATE_CUSTOMER_BY_ID_SUCCESS,
  CREATE_LOAN_SUCCESS,
  CREATE_LOAN,
  UPDATE_LOAN_SUCCESS,
  UPDATE_LOAN,
  GET_LOAN_BY_ID_SUCCESS,
  GET_LOAN_BY_ID,
  GET_ALL_LOAN_SUCCESS,
  GET_ALL_LOAN,
} from "../action_Types";

function* allUser() {
  try {
    const response = yield call(authInstance.get, "");

    yield put({
      type: GET_ALL_USER_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}
function* CompanyStatus(action) {
  const { id, data } = action.payload;

  try {
    const response = yield call(authInstance.patch, `customers/${id}`, data);

    yield put({
      type: UPDATE_CUSTOMER_STATUS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}
function* uploadLetter(action) {
  try {
    const response = yield call(
      authInstance.post,
      "sanction-letter",
      action.payload
    );

    yield put({
      type: UPLOAD_SANCTION_LETTER_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addUser(action) {
  try {
    const response = yield call(authInstance.post, "customers", action.payload);

    yield put({
      type: ADD_USER_DATA_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* deleteUser(action) {
  try {
    const response = yield call(authInstance.delete, `/${action.payload}`);

    yield put({
      type: DELETE_USER_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updateUser(action) {
  try {
    const { id, data } = action.payload;

    const response = yield call(authInstance.patch, `/${id}`, data);
    yield put({
      type: UPDATE_USER_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}
function* updateCustomer(action) {
  try {
    const { id, data } = action.payload;

    const response = yield call(authInstance.patch, `customers/${id}`, data);

    yield put({
      type: UPDATE_CUSTOMER_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getAllBranches(action) {
  try {
    const response = yield call(
      authInstance.post,
      "getAllBranches",
      action.payload
    );

    yield put({
      type: GET_ALL_BRANCHES_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}
function* createBranches(action) {
  try {
    const response = yield call(authInstance.post, "branch", action.payload);

    yield put({
      type: CREATE_BRANCHES_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getAllDirectors(action) {
  try {
    const response = yield call(
      authInstance.post,
      "getallDirectors",
      action.payload
    );

    yield put({
      type: GET_ALL_DIRECTORS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* createDirector(action) {
  try {
    const response = yield call(authInstance.post, "directors", action.payload);

    yield put({
      type: CREATE_DIRECTORS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* createNewLoans(action) {
  try {
    const response = yield call(authInstance.post, "addLoans", action.payload);

    yield put({
      type: CREATE_LOAN_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updateLoans(action) {
  const { id, data } = action.payload;
  try {
    const response = yield call(authInstance.patch, `loan/${id}`, data);
    yield put({
      type: UPDATE_LOAN_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getLoansById(action) {
  try {
    const response = yield call(authInstance.post, `getCustomerLoans`, action.payload);
    yield put({
      type: GET_LOAN_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getAllLoans(action) {
  try {
    const response = yield call(
      authInstance.post,
      "getAllLoanByCustomer",
      action.payload
    );
    yield put({
      type: GET_ALL_LOAN_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* getAllCapitals(action) {
  try {
    const response = yield call(
      authInstance.post,
      "getAllCapitalAccount",
      action.payload
    );

    yield put({
      type: GET_ALL_CAPITALS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* createCapitals(action) {
  const { payload } = action;
  try {
    const response = yield call(authInstance.post, "capitalAccount", payload);

    yield put({
      type: CREATE_CAPITALS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

export default function* customerActionWatcher() {
  yield takeLatest(GET_ALL_USER, allUser);
  yield takeLatest(GET_ALL_BRANCHES, getAllBranches);
  yield takeLatest(CREATE_BRANCHES, createBranches);
  yield takeLatest(CREATE_LOAN, createNewLoans);
  yield takeLatest(UPDATE_LOAN, updateLoans);
  yield takeLatest(GET_LOAN_BY_ID, getLoansById);
  yield takeLatest(GET_ALL_LOAN, getAllLoans);
  yield takeLatest(GET_ALL_DIRECTORS, getAllDirectors);
  yield takeLatest(CREATE_DIRECTORS, createDirector);
  yield takeLatest(GET_ALL_CAPITALS, getAllCapitals);
  yield takeLatest(CREATE_CAPITALS, createCapitals);
  yield takeLatest(ADD_USER_DATA, addUser);
  yield takeLatest(DELETE_USER_BY_ID, deleteUser);
  yield takeLatest(UPDATE_USER_BY_ID, updateUser);
  yield takeLatest(UPDATE_CUSTOMER_BY_ID, updateCustomer);
  yield takeLatest(UPDATE_CUSTOMER_STATUS, CompanyStatus);
  yield takeLatest(UPLOAD_SANCTION_LETTER, uploadLetter);
}
