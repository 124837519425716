import {
  CButton,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { successAlert } from "src/utils/Swal";
import PropTypes from "prop-types";

const initialValues = {
  branchName: "",
  bankName: "",
  ifsc: "",
  accountNumber: "",
  bankStatementsImageUrl: "",
  creditScore: "",
};

const BankDetails = (props) => {
  const {isUpdateForm}=props;
  const baneStateRef = useRef();
  const [submission, setSubmission] = useState(false);
  const [bankdata, setBankData] = useState(props?.formData || initialValues);

  const onChangeValue = (e) => {
    const { name, value } = e.target;

    setBankData({
      ...bankdata,
      [name]: value,
    });
 
  };

  const handleSubmit = (values) => {
    const obj = {
      branchName,
      bankName,
      ifsc,
      accountNumber,
      bankStatementsImageUrl: baneStateRef?.current?.files[0],
      creditScore,
    };
    props.updateFormData("bankDetails", obj);
    props.setKey("Income", values);
    setSubmission(true);
  };

  useEffect(() => {
    if (submission === true) {
      successAlert("Success", "New Customer has been Created");
    }
  }, [submission]);

  const {
    branchName,
    bankName,
    ifsc,
    accountNumber,
    bankStatementsImageUrl,
    creditScore,
  } = bankdata;

  return (
    <CCardBody>
      <CContainer>
        <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="bankName">
                Bank Name
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="text"
                id="bankName"
                placeholder="Bank Name"
                name="bankName"
                value={bankName}
                onChange={onChangeValue}
                required
              />
            </CCol>

            <CCol xs={6}>
              <CFormLabel htmlFor="accountNumber">
                Account Number
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="number"
                id="accountNumber"
                name="accountNumber"
                value={accountNumber}
                onChange={onChangeValue}
                placeholder="Account Number"
                required
              />
            </CCol>
          </CRow>

          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="ifsc">
                IFSC Code
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="text"
                id="ifsc"
                placeholder="IFSC Code"
                name="ifsc"
                value={ifsc}
                onChange={onChangeValue}
                required
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="creditScore">
                Credit Score
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="number"
                id="creditScore"
                placeholder="Credit Score"
                name="creditScore"
                value={creditScore}
                onChange={onChangeValue}
                required
              />
            </CCol>
          </CRow>

          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="bankStatementsImageUrl">
                Upload Bank Statement <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={baneStateRef}
                id="bankStatementsImageUrl"
                placeholder="Upload Bank Statement"
                onChange={onChangeValue}
                required={!isUpdateForm}
              />
            </CCol>
          </CRow>

          <div className="identification_btn">
            <CButton type="submit" className="next_btn">
              Next{" "}
            </CButton>
          </div>
        </CForm>
      </CContainer>
    </CCardBody>
  );
};
BankDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
  isUpdateForm: PropTypes.bool,
};
export default BankDetails;
