import ProductList from "src/pages/Products/ProductList";
import Users from "../pages/company";
import Dashboard from "../pages/Dashboard";
import UserOperations from "src/pages/staffSection";
import KycUpdation from "src/pages/KycUpdation/kycUpdationList";
import CustomerProfile from "src/pages/CustomerDetails/CustomerProfile";
import LoanType from "src/pages/loanType";
import Interest from "src/pages/interest";
import Collection from "src/pages/collection";
import AddStaffForm from "src/pages/staffSection/AddStaffForm";
import CustomerAllDetails from "src/pages/customer/customerAllDetails";
import CustomerListPage from "src/pages/CustomerDetails/customerListPage";
import Pending from "src/pages/LoanStatus/Pending";
import Rejected from "src/pages/LoanStatus/Rejected";
import Approved from "src/pages/LoanStatus/Approved";
import Disbursement from "src/pages/Disbursement";
import PrintDisbursement from "src/pages/Disbursement/printDisbursment";
import CalEMi from "src/pages/cal-emi";
import FinalDetailsForm from "src/pages/customer/finalDetailsForm";
import ViewCustomerProfileInfo from "src/pages/LoanData/ViewCustomerProfileInfo";
import ViewProfileInfo from "src/pages/CustomerDetails/profile";
import BranchList from "src/pages/branches";
import DirectorList from "src/pages/directors";
import DirectorListById from "src/pages/directors/capital";
import AgentDetailsById from "src/pages/staffSection/AddStaffForm/agentDetails";
import PaidCollection from "src/pages/collection/paidCollections";
import AddForm from "src/pages/customer/addForm";
import EmiRecords from "src/pages/CustomerDetails/emiRecord";
import Dues from "src/pages/CustomerDetails/due";
import PaymentRecords from "src/pages/CustomerDetails/paymentRecord";
import AddGroupForm from "src/pages/group/AddGroup";
import GroupList from "src/pages/group/groupList";
import NewSection from "src/pages/CustomerDetails/newSection";
import JournalEntries from "src/acounting/pages/journalEntries";
import ContraEntries from "src/acounting/pages/contraEntry";
import OpeningBalanceList from "src/acounting/pages/openingBalance";
import ProfitAndLossList from "src/acounting/pages/profitAndLossChart";
import Ledger from "src/acounting/pages/ledger";
import CustomerListForVeriFication from "src/pages/otherVerification/customerListForVerification";
import JournalEntryAddForm from "src/acounting/pages/journalEntries/journalEntryAddForm";
import JEntryData from "src/acounting/pages/journalEntries/journalEntryList";
import MasterList from "src/acounting/pages/master/masterList";
import ProfileDetails from "src/components/profile/profile";
import EmiDataForStatement from "src/pages/emiStatement/emiData";
import RecieptEntryForm from "src/acounting/pages/reciept/recieptEntryForm";
import PaymentForm from "src/acounting/pages/payment/paymentForm";
import CustomerDetailsForm from "src/pages/CustomerDetails/CustomerDetailsForm/CustomerDetailsForm";

const AppRoutes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/customerdetailsform", name: "Final Details Form", element: FinalDetailsForm },
  { path: "/users", name: "Users", element: Users },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/productlist", name: "Product List", element: ProductList },
  { path: "/branches", name: "branches", element: BranchList },
  { path: "/directors", name: "director", element: DirectorList },
  { path: "/emistatement", name: "EMI", element: EmiDataForStatement },
  { path: "/verification", name: "Verification", element: CustomerListForVeriFication },
  { path: "/journalentrieslist", name: "Journal Entries", element: JournalEntries },
  { path: "/journalAddForm", name: "Journal Add Form", element: JournalEntryAddForm },
  { path: "/recieptForm", name: "Reciept", element: RecieptEntryForm },
  { path: "/paymentForm", name: "Payment", element: PaymentForm },
  { path: "/journaldata", name: "Journal Data", element: JEntryData },
  { path: "/profile", element: ProfileDetails },
  { path: "/contraentrieslist", name: "Contra Entries", element: ContraEntries },
  { path: "/balancelist", name: "Balance List", element: OpeningBalanceList },
  { path: "/profitandlosslist", name: "Profit And Loss List", element: ProfitAndLossList },
  { path: "/ledger", name: "Ledger", element: Ledger },
  { path: "/directorsCapital", name: "director", element: DirectorListById },
  { path: "/staff", name: "Staffs", element: UserOperations },
  { path: "/owndetails", name: "Staffs", element: AgentDetailsById },
  { path: "/customers/approved", name: "Customers/Approved", element: Approved },
  { path: "/customers/pending", name: "Customers/Pending", element: Pending },
  { path: "/customers/rejected", name: "Customers/Rejected", element: Rejected },
  { path: "/emirecords", name: "Emirecord", element: EmiRecords },
  { path: "/emisection", name: "section", element: NewSection },
  { path: "/due", name: "Due", element: Dues },
  { path: "/paymentrecords", name: "Paymentrecord", element: PaymentRecords },
  { path: "/customers", name: "Customers", element: AddForm },
  // {
  //   path: "/customers/loandetails",
  //   name: "Customers/loandetails",
  //   element: CustomerAllDetails,
  // },
  { path: "/calculateEmi", name: "Calculate EMI", element: CalEMi },
  { path: "/masteraccount", name: "Master", element: MasterList },
  // { path: "/customers/kyc", name: "/customers/kyc", element: KycUpdation },
  { path: "/customer-profile", name: "Customer Details", element: CustomerProfile },
  { path: "/emi/loanType", name: "Loan Type", element: LoanType },
  { path: "/interest", name: "Interest", element: Interest },
  { path: "/pendingcollection", name: "Collection", element: Collection },
  { path: "/paidcollection", name: "Collection", element: PaidCollection },
  // {
  //   path: "/viewprofileinfo",
  //   name: "ViewProfileInfo",
  //   element: ViewProfileInfo,
  // },
  { path: "/viewcustomerprofileinfo", name: "ViewCustomerProfileInfo", element: ViewCustomerProfileInfo },
  { path: "/staff/addstaff", name: "staff/addstaff", element: AddStaffForm },
  { path: "/group/addgroup", name: "group/addgroup", element: AddGroupForm },
  { path: "/group/grouplist", name: "group/grouplist", element: GroupList },
  { path: "/staff/edit", name: "staff/edit", element: AddStaffForm },

  // { path: "/agent/loandetails", name: "/agent/loandetails", element: CustomerListPage },
  // { path: "/disbursement", name: "/disbursement", element: Disbursement },
  // { path: "/printdisburshment", name: "/printdisburshment", element: PrintDisbursement },
  // { path: "/customerdetailsform", name: "/CustomerDetailsForm", element: CustomerDetailsForm },


];

export default AppRoutes;

