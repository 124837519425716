import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CCardHeader,
  CTableRow,
  CButton,
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-js-pagination";
import { Button } from "react-bootstrap";

import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import CreateBranchForm from "./operations/createBranchForm";
import { getAllBranches } from "src/redux/actions/customerAction";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "src/services/const";

const BranchList = () => {
  const componentPDF = useRef();
  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const SchemaName = sessionStorage.getItem("schemaName");
  const [currItem, setCurrItem] = useState({});
  const [printState, setPrintState] = useState(false);
  const { Branches, newCreatedData } = useSelector(
    (state) => state?.customerReducer
  );
  const [newBranch, setNewBranch] = useState(newCreatedData);
  const obj = {
    schemaName: SchemaName,
  };

  useEffect(() => {
    dispatch(getAllBranches(obj));
  }, [newBranch, newCreatedData]);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = Branches?.data?.slice(indexOfFirstRow, indexOfLastRow);

  const [showEdit, setShowEdit] = useState(false);

  const handleShowAddForm = (details) => {
    setShowAddButton(false);
    setShowSelectAndSearch(false);
    setShowAddForm(true);
    setCurrItem(details);
    setShowEdit(false);
  };

  const download_btn = () => {
    setPrintState(true);
  }
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };


  return (
    <>
      <CRow className="content">
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Branches</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol xs={12}>
                  <CCard>
                    <div className="search_bar">
                      <div className="search-inner">
                        {showSelectAndSearch && (
                          <div>
                            <CFormSelect
                              size="sm"
                              className="mb-3"
                              style={{ cursor: "pointer" }}
                              aria-label="Small select example"
                              // onChange={(e) => setRowsPerPage(e.target.value)}
                              onChange={handleRowsPerPageChange}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                            </CFormSelect>
                          </div>
                        )}

                        <div style={{ display: "flex" }}>
                          {showSelectAndSearch && (
                            <>
                              <CFormInput
                                type="text"
                                size="sm"
                                placeholder="Search"
                                aria-label="sm input example"
                                style={{ margin: "0px 0px 16px 0px" }}
                                onChange={handleSearchChange}
                                value={searchInput}
                              />

                              <span
                                style={{
                                  border: " 1px solid #d1c5c5",
                                  borderRadius: "4px",
                                  margin: "0px 5px 16px -1px",
                                  padding: "1px 8px 0px 8px",
                                  background: "#ebedef",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fa fa-search"></i>
                              </span>
                              {/* </CFormInput> */}
                            </>
                          )}
                        </div>
                        {/* div1 */}
                      </div>
                      {/* btn */}
                      <div className="btn_add mt-0 mb-14">
                        {showAddButton && (
                          <Button
                            className="add-btn w-auto plr-30 mr-10"
                            onClick={handleShowAddForm}
                          >
                            Add
                          </Button>
                        )}
                        {showAddButton && (
                          <Button
                            className="add-btn w-auto mr-10"
                            onClick={download_btn}
                          >
                            Download
                          </Button>
                        )}
                        {showAddButton && (
                          <CSVLink data={currentRows === undefined ? " " : currentRows} filename={"branch-details.csv"}>
                            <CButton className="add-btn w-auto m-0">
                              CSV
                            </CButton>
                          </CSVLink>
                        )}
                      </div>
                    </div>
                    <CCardBody>
                      <div
                        className={`table-container ${showAccordion ? "hidden" : ""
                          } ${showAddForm ? "hidden" : ""}`}
                      >
                        <div
                          ref={printState ? componentPDF : null}
                          style={{ padding: printState ? "20px" : "0px" }}
                        >
                          {printState && (
                            <div className="print-div">
                              <div>
                                <Vakilkaro />
                              </div>
                              <div>
                                <p>{COMPANY_NAME}</p>
                              </div>
                            </div>
                          )}

                          <CTable color="dark" hover responsive>
                            <CTableHead className="tabl-head">
                              <CTableRow className="table-row">
                                <CTableHeaderCell className="col-1">
                                  S. No.
                                </CTableHeaderCell>
                                <CTableHeaderCell className="col-2">
                                  Branch Name
                                </CTableHeaderCell>
                                <CTableHeaderCell className="col-2">
                                  Branch Code
                                </CTableHeaderCell>
                                <CTableHeaderCell className="col-2">
                                  Contact Number
                                </CTableHeaderCell>
                                <CTableHeaderCell className="col-2">
                                  Email
                                </CTableHeaderCell>
                                <CTableHeaderCell className="col-4">
                                  Branch Address
                                </CTableHeaderCell>
                              </CTableRow>
                            </CTableHead>
                            <CTableBody>
                              {Branches?.data && Branches?.data.length > 0 ? (
                                currentRows
                                  ?.filter((item) =>
                                    item?.branchName
                                      .toLowerCase()
                                      .includes(searchInput.toLowerCase())
                                  )
                                  .map((items, index) => {
                                    return (
                                      <CTableRow key={index}>
                                        <CTableDataCell>
                                          {indexOfFirstRow + index + 1}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {items.branchName ?? "--"}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {items.branchCode ?? "--"}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {items.contactNumber ?? "--"}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {items.email ?? "--"}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {/* {items.address ?? "--"} */}
                                          {items.address ?? "--"}
                                        </CTableDataCell>
                                      </CTableRow>
                                    );
                                  })
                              ) : (
                                <CTableRow>
                                  <CTableDataCell colSpan="6">
                                    Loading...
                                  </CTableDataCell>
                                </CTableRow>
                              )}
                            </CTableBody>
                          </CTable>
                        </div>

                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        > */}
                        <div className="pagin-div">
                          <div>
                            Showing {indexOfFirstRow + 1} to{" "}
                            {Math?.min(indexOfLastRow, Branches?.data?.length)}{" "}
                            of {Branches?.data?.length} entries
                          </div>
                          <div className="pagination-container">
                            <ReactPaginate
                              activePage={currentPage}
                              itemsCountPerPage={rowsPerPage}
                              totalItemsCount={
                                Branches?.data?.length
                                // ? Branches?.data?.length
                                // : 0
                              }
                              pageRangeDisplayed={5}
                              onChange={handlePageChange}
                              prevPageText="Previous"
                              nextPageText="Next"
                              firstPageText="First"
                              lastPageText="Last"
                              innerClass="pagination"
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          </div>
                        </div>
                      </div>
                      {showAddForm && (
                        <CreateBranchForm
                          selectedCompany={selectedCompany}
                          setShowAddForm={setShowAddForm}
                          setShowAddButton={setShowAddButton}
                          setShowSelectAndSearch={setShowSelectAndSearch}
                          intial={currItem}
                          edit={showEdit}
                          callBack={() => {
                            window.location.reload();
                            showAddForm(false);
                          }}
                        />
                      )}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <div
            className={`accordion-container ${showAccordion ? "visible" : ""} ${showAddForm ? "visible" : ""
              }`}
          ></div>
        </CCol>
      </CRow>
    </>
  );
};

export default BranchList;
