import React, { useState } from 'react'
import {
  CModal,
  CModalBody,
  CCol,
  CContainer,
  CForm,
  CModalHeader,
  CModalTitle,
  CFormInput,
  CButton
} from "@coreui/react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
import {
  updateCompanyAaddharTokenLimit,
  getAllCompany
} from "src/redux/actions/companyAction";

const AddAaddharTokenLimit = ({ visibleAddAadharToken, setVisibleAddAadharToken,
  activeCompanyId,
  setActiveCompanyId
}) => {
  const { register, handleSubmit, reset } = useForm();
  const [validationMsg, setValidationMsg] = useState(false);
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    const { aaddhar_token_limit } = data
    // Regular expression to match positive numbers
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (aaddhar_token_limit === '' || !regex.test(aaddhar_token_limit)) {
      // If the input is empty or it matches the regex for positive numbers,
      // update the state with the new value
      setValidationMsg(true);
      setTimeout(() => {
        setValidationMsg(false);
      }, [1000])
      return
    }
    const updatedData = {
      id: activeCompanyId,
      data: { aaddhar_token_limit },
    };
    dispatch(updateCompanyAaddharTokenLimit(updatedData))
    setActiveCompanyId(0);
    reset();
    setVisibleAddAadharToken(false);
    dispatch(getAllCompany());
  }
  return (
    <CModal
      className="fullPageModal"
      backdrop="static"
      visible={visibleAddAadharToken}
      onClose={() => setVisibleAddAadharToken(false)}
      aria-labelledby="StaticBackdropExampleLabel"
      size="md"
    >
      <CModalHeader>
        <CModalTitle id="StaticBackdropExampleLabel">
          Add Aadhaar Token Update
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer>
          {" "}
          <CForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <CCol xs={6}>
              <div className="mb-4">
                <label htmlFor="aaddhar_token_limit" className="mb-1">
                  Token
                </label>
                <CFormInput
                  type="number"
                  placeholder=""
                  name="aaddhar_token_limit"
                  required
                  defaultValue={""}
                  {...register("aaddhar_token_limit", { required: true })}
                />
                <span className="deleteIcon">{validationMsg && "Please Enter positive value"}</span>
              </div>
            </CCol>
            <CButton type="submit" size="sm" color="primary">
              Add Token
            </CButton>
          </CForm>
        </CContainer>
      </CModalBody>
    </CModal>
  );
}
AddAaddharTokenLimit.propTypes = {
  visibleAddAadharToken: PropTypes.bool.isRequired,
  setVisibleAddAadharToken: PropTypes.func.isRequired,
  activeCompanyId: PropTypes.number,
  setActiveCompanyId: PropTypes.func.isRequired,
};
export default AddAaddharTokenLimit