import {
  CButton,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { successAlert } from "src/utils/Swal";
import PropTypes from "prop-types";
import { getInterest } from "src/api/allApis";
import { useSelector } from "react-redux";
import _ from "lodash";

const RegistrationForm = (props) => {
  const [submission, setSubmission] = useState(false);
  const { newOtpVerifiedData } = useSelector((state) => state?.companyReducer);

  const [mainData, setMainData] = useState(props.formData);
  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setMainData({ ...mainData, [name]: value });
  };

  const handleSubmit = (values) => {
    values.preventDefault();
    if (phoneNumber.length !== 10) {
      alert("Mobile number must be 10 digits");
      return;
    }
    props.updateFormData("registration", mainData);
    props.setKey("address");
    setSubmission(true);
  };

  const [interset, setInterest] = useState([]);

  const currentDate = new Date();

  const maxDateJoining = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const maxJoining = `${maxDateJoining.getFullYear()}-${(
    maxDateJoining.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${maxDateJoining.getDate().toString().padStart(2, "0")}`;

  const maxDoB = new Date(
    currentDate.getFullYear() - 18,
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const maxDateOfBirth = `${maxDoB.getFullYear()}-${(maxDoB.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${maxDoB.getDate().toString().padStart(2, "0")}`;

  const fetchInterest = async () => {
    const res = await getInterest({
      schemaName: sessionStorage.getItem("schemaName"),
    });
    setInterest(res?.data || []);
  };

  useEffect(() => {
    fetchInterest();
  }, []);

  useEffect(() => {
    if (submission === true) {
      successAlert("Success", "New Customer has been Created");
    }
  }, [submission]);

  // console.log("dateArray", `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`);

  useEffect(() => {
    if (_.isEmpty(props.formData)) {
      console.log("hot emmp");
      const dateArray = newOtpVerifiedData?.data?.dob?.split("-");
      const careOf = newOtpVerifiedData?.data?.care_of || "";
      const nameWithoutSO = careOf.replace(/^S\/O:\s*/, "");
      const fullName = newOtpVerifiedData?.data?.name || "";
      const gend = newOtpVerifiedData?.data?.gender || "";
      console.log(gend, "");
      const [firstName, lastName] = fullName.split(" ");
      setMainData((prevMainData) => ({
        ...prevMainData,
        firstName: firstName || "",
        lastName: lastName || "",
        gender: gend === "M" ? "Male" : gend === "F" ? "Female" : "",
        dob: `${dateArray?.[2]}-${dateArray?.[1]}-${dateArray?.[0]}` || "",
        fatherName: nameWithoutSO,
      }));
    }
  }, [newOtpVerifiedData, props.formData]);
  const {
    firstName,
    middleName,
    lastName,
    martialStatus,
    phoneNumber,
    empStartDate,
    loanAmountRequsted,
    email,
    gender,
    dob,
    fatherName,
    occupation,
    loanType,
    loanTerm,
  } = mainData;

  return (
    <>
      <CCardBody>
        <CContainer>
          <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="firstName">
                  First Name
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="firstName"
                  placeholder="First Name"
                  name="firstName"
                  value={firstName}
                  onChange={onChangeValue}
                  disabled
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="middleName">Middle Name</CFormLabel>
                <CFormInput
                  type="text"
                  id="middleName"
                  placeholder="Middle Name"
                  name="middleName"
                  value={middleName || ""}
                  onChange={onChangeValue}
                />
              </CCol>
            </CRow>

            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="lastName">
                  Last Name
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="lastName"
                  placeholder="Last Name"
                  name="lastName"
                  value={lastName}
                  onChange={onChangeValue}
                  disabled
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="gender">
                  Email
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="email"
                  id="email"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={onChangeValue}
                  required
                />
              </CCol>
            </CRow>

            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="phoneNumber">
                  Phone Number
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="phoneNumber"
                  maxLength={10}
                  placeholder="Phone Number"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={onChangeValue}
                  required
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="planType">
                  Date of Birth
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  // type="date"
                  id="dob"
                  name="dob"
                  value={dob}
                  onChange={onChangeValue}
                  disabled
                // max={maxDateOfBirth}
                />
              </CCol>
            </CRow>

            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="father">Father Name</CFormLabel>
                <CFormInput
                  type="text"
                  id="fatherName"
                  placeholder="Father Name"
                  name="fatherName"
                  value={fatherName}
                  onChange={onChangeValue}
                  disabled
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="gender">
                  Gender
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  className="custom-select"
                  id="gender"
                  name="gender"
                  type="select"
                  value={gender}
                  disabled
                  onChange={onChangeValue}
                />
              </CCol>
            </CRow>

            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="martialStatus">
                  Marital Status
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormSelect
                  className="custom-select"
                  id="gender"
                  name="martialStatus"
                  type="select"
                  value={martialStatus}
                  onChange={onChangeValue}
                  required
                >
                  <option value="">Select Marital Status{martialStatus}</option>
                  <option value="married">Married</option>
                  <option value="unMarried">Unmarried</option>
                </CFormSelect>
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="occupation">
                  Current Employee/Occupation
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormSelect
                  className="custom-select"
                  id="occupation"
                  name="occupation"
                  type="select"
                  value={occupation}
                  onChange={onChangeValue}
                  required
                >
                  <option value="">Select Employee/Occupation</option>
                  <option value="job">Employee</option>
                  <option value="bussiness">Occupation</option>
                </CFormSelect>
              </CCol>
            </CRow>

            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="empStartDate">
                  Employment/Occupation Start Date
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="date"
                  id="empStartDate"
                  name="empStartDate"
                  value={empStartDate}
                  onChange={onChangeValue}
                  required
                  max={maxJoining}
                />
              </CCol>
              {_.isEmpty(props?.customer) &&
                <CCol xs={6}>
                  <CFormLabel htmlFor="loanAmountRequsted">
                    Requested Loan Amount
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    type="number"
                    id="loanAmountRequsted"
                    placeholder="Requested Loan Amount"
                    name="loanAmountRequsted"
                    value={loanAmountRequsted}
                    onChange={onChangeValue}
                    required
                  />
                </CCol>
              }

            </CRow>

            {_.isEmpty(props?.customer) &&
              <CRow className="pt-20">
                <CCol xs={6}>
                  <CFormLabel htmlFor="loanType">
                    Loan Type
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormSelect
                    className="custom-select"
                    id="loanType"
                    name="loanType"
                    type="select"
                    value={loanType}
                    onChange={onChangeValue}
                    required
                  >
                    <option value="">Select Loan Type</option>
                    {interset?.map((item, i) => (
                      <option key={i} value={item?.id}>
                        {item?.loanType?.toUpperCase()}
                      </option>
                    ))}
                  </CFormSelect>
                </CCol>

                <CCol xs={6}>
                  <CFormLabel htmlFor="loanTerm">
                    Term [in Months]
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormInput
                    type="number"
                    id="loanTerm"
                    placeholder="Loan Term"
                    name="loanTerm"
                    value={loanTerm}
                    onChange={onChangeValue}
                    required
                  />
                </CCol>
              </CRow>
            }

            <div className="identification_btn">
              <CButton type="submit" color="primary" className="px-4">
                Save
              </CButton>
            </div>
          </CForm>
        </CContainer>
      </CCardBody>
    </>
  );
};
RegistrationForm.propTypes = {
  formData: PropTypes.object,
  customer: PropTypes.object,
  updateFormData: PropTypes.func,
  setKey: PropTypes.func,
};
export default RegistrationForm;
