import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";

import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import ReactPaginate from "react-js-pagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { getAllEmisByEmiStatus } from "src/redux/actions/emiAction";

const NewSection = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { emiStatusData } = useSelector((state) => state?.emiReducer);
  const [SchemaName, setSchemaName] = useState(
    sessionStorage.getItem("schemaName")
  );
  const [minEndDate, setMinEndDate] = useState("");
  const [status, setStatus] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [selectedEmiStatus, setSelectedEmiStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [groupName, setGroupName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [printState, setPrintState] = useState(false);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const rowsPerPage = 10;

  const objData = {
    schemaName: SchemaName,
    emiStatus: selectedEmiStatus,
    startDate: startDate,
    enddate: endDate,
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const componentPDF = useRef();

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const handleSearch = () => {
    setStatus(true);
    setShowTable(true);
  };

  useEffect(() => {
    if (status === true) {
      dispatch(getAllEmisByEmiStatus(objData));
      setStatus(false);
    }
  }, [status, showTable]);
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setStartDate(selectedStartDate);
    setMinEndDate(selectedStartDate);
    if (endDate < selectedStartDate) {
      setEndDate("");
    }
  };

  const filterByGroupName = () => {
    let filteredRows = emiStatusData?.data || [];

    if (groupName) {
      filteredRows = filteredRows.filter(
        (item) =>
          String(item?.customer?.groupLoan?.groupName) === String(groupName)
      );
    }
    if (customerName) {
      filteredRows = filteredRows.filter(
        (item) =>
          String(item?.customer?.firstName) === String(customerName)
      );
    }
    return filteredRows;
  };

  const filteredRows = filterByGroupName();

  const currentRows = showTable
    ? filteredRows.slice(indexOfFirstRow, indexOfLastRow)
    : [];

  const download_btn = () => {
    setPrintState(true);
  };

  return (
    <div className="content">
      <CCard>
        <CCardHeader><strong>All EMI Record</strong></CCardHeader>
        <CCardBody>
          <div>
            <CRow className="mb-2">
              <CCol xs={6}>
                <CFormLabel htmlFor="address">Start Date
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="date"
                  size="sm"
                  placeholder="Start Date"
                  aria-label="sm input example"
                  onChange={handleStartDateChange}
                  value={startDate}
                  required
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="address">End Date
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="date"
                  size="sm"
                  placeholder="End Date"
                  aria-label="sm input example"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                  min={minEndDate}
                  required
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol xs={6}>
                <div className="mb-4">
                  <CFormLabel htmlFor="address">Select EMI Type
                    <span className="red-star">{` `}*</span>
                  </CFormLabel>
                  <CFormSelect
                    id=""
                    value={selectedEmiStatus}
                    onChange={(e) => setSelectedEmiStatus(e.target.value)}
                    required
                  >
                    <option value="">Select EMI Type</option>
                    <option value="paid">Paid</option>
                    <option value="pending">Pending</option>
                  </CFormSelect>
                </div>
              </CCol>

              <div className="branch_btn">
                <CButton onClick={handleSearch}>Search</CButton>
              </div>

            </CRow>
          </div>
          {showTable && (
            <div>
              <div className="searchinput">
                <CRow >
                  
                  <CCol xs={12} md={4} lg={5}>
                    <CFormLabel htmlFor="groupName">Group Name</CFormLabel>
                    <CFormInput
                      type="text"
                      size="sm"
                      placeholder="groupName"
                      aria-label="sm input example"
                      onChange={(e) => setGroupName(e.target.value)}
                      value={groupName}
                    />
                  </CCol>
                  <CCol xs={12} md={4} lg={5}>
                    <CFormLabel htmlFor="customerName">
                      Customer Name
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      size="sm"
                      placeholder="customerName"
                      aria-label="sm input example"
                      onChange={(e) => setCustomerName(e.target.value)}
                      value={customerName}
                    />
                  </CCol>
                  
                  <CCol xs={12} md={4} lg={2}>
                  <Button
                    className="add-btn mr-10 w-auto"
                    onClick={download_btn}
                  >
                    Download
                  </Button>
                  </CCol>

                </CRow>
              </div>
              <div className="mb-2"
                ref={printState ? componentPDF : null}
                style={{ padding: printState ? "20px" : "0px" }}
              >
                {printState && (
                  <div className="print-div">
                    <div>
                      <Vakilkaro />
                    </div>
                    <div>
                      <p>Vakilkaro Pvt. Ltd.</p>
                    </div>
                  </div>
                )}
              <CTable hover className="" responsive>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col" className="">
                      <span>Sr. No.</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span>Customer Name</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span>Loan Account No.</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span>Group Name</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span>Branch Code</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span>EMI Amount</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="">
                      <span>
                        {selectedEmiStatus === "paid"
                          ? "Paid Date"
                          : "Due Date"}{" "}
                      </span>
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {currentRows?.map((company, index) => (
                    <CTableRow key={index}>
                      <CTableDataCell>{indexOfFirstRow + index + 1}</CTableDataCell>
                      <CTableDataCell>
                        {`${company?.customer?.firstName} ${company?.customer?.lastName}`}
                      </CTableDataCell>
                      <CTableDataCell>
                        {company?.customer?.loanAccountNumber
                          ? company?.customer?.loanAccountNumber
                          : "-"}
                      </CTableDataCell>
                      <CTableDataCell>
                        {company?.customer?.groupLoan?.groupName
                          ? company?.customer?.groupLoan?.groupName
                          : "--"}
                      </CTableDataCell>
                      <CTableDataCell>
                        {company?.customer?.branchCode
                          ? company?.customer?.branchCode
                          : "--"}
                      </CTableDataCell>
                      <CTableDataCell>
                        {company?.emiAmount ? company?.emiAmount : "--"}
                      </CTableDataCell>
                      <CTableDataCell>
                        {company?.dueDate ? company?.dueDate : "--"}
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Showing {indexOfFirstRow + 1} to{" "}
                  {Math?.min(indexOfLastRow, emiStatusData?.data?.length)} of{" "}
                  {emiStatusData?.data?.length} entries
                </div>
                <div className="pagination-container">
                  <ReactPaginate
                    activePage={currentPage}
                    itemsCountPerPage={rowsPerPage}
                    totalItemsCount={emiStatusData?.data?.length}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText="Previous"
                    nextPageText="Next"
                    firstPageText="First"
                    lastPageText="Last"
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            </div>
          )}
        </CCardBody>
      </CCard>
    </div>
  );
};

export default NewSection;
