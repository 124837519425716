import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addUser } from "src/redux/actions/customerAction";
import { CButton, CCol, CFormInput, CFormSelect, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { successAlert } from "src/utils/Swal";
import PropTypes from "prop-types";
const RegistrationForm = (props) => {
  const dispatch = useDispatch();
  const [submission, setSubmission] = useState(false);

  const initialValues = {
    customerName: "",
    pendingEmi: "",
    paidEmi: "",
    totalEmi: "",
    rate: "",
    loanType: "",
  };

  const validationSchema = Yup.object().shape({
    customerName: Yup.string().required("Customer Name is required"),
    totalEmi: Yup.number().required("Total Emi is required"),
    rate: Yup.number().required("Rate Emi is required"),
    pendingEmi: Yup.number().required("Pending Emi is required"),
    paidEmi: Yup.number().required("Paid Emi is required"),
  });

  const handleSubmit = (values) => {
    props.updateFormData("registration", values);
    props.setKey("address");
    // dispatch(addUser(values));
    setSubmission(true);
  };
  useEffect(() => {
    if (submission === true) {
      successAlert("Success", "New Customer has been Created");
    }
  }, [submission]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        {/* <CRow style={{ margin: "20px 0px 0px 10px" }}>
          <CCol xs={4} className="position-relative">
            <Field
              type="text"
              id="firstName"
              name="firstName"
              as={CFormInput}
              floatingClassName="mb-4"
              floatingLabel="First Name"
              placeholder="First Name"
            />
            <ErrorMessage
              name="firstName"
              component="div"
              className="text-danger"
            />
          </CCol>
          <CCol xs={4} className="position-relative">
            <Field
              type="text"
              id="middleName"
              name="middleName"
              as={CFormInput}
              floatingClassName="mb-4"
              floatingLabel="Middle Name"
              placeholder="Middle Name"
            />
          </CCol>
          <CCol xs={4} className="position-relative">
            <Field
              type="text"
              id="lastName"
              name="lastName"
              as={CFormInput}
              floatingClassName="mb-4"
              floatingLabel="Last Name"
              placeholder="Last Name"
            />
            <ErrorMessage
              name="lastName"
              component="div"
              className="text-danger"
            />
          </CCol>
        </CRow> */}
        <CRow style={{ margin: "20px 0" }}>
          <CCol xs={6} className="position-relative">
            <Field
              type="text"
              id="email"
              name="customerName"
              as={CFormInput}
              floatingClassName="mb-4"
              floatingLabel="Customer Name"
            //   placeholder="name@example.com"
            />
            <ErrorMessage
              name="customerName"
              component="div"
              className="text-danger"
            />
          </CCol>

          <CCol xs={6} className="position-relative">
            <Field
              type="number"
              id="mobileNumber"
              name="pendingEmi"
              as={CFormInput}
              floatingClassName="mb-4"
              floatingLabel="Pending Emi"
            //   placeholder="Mobile Number"
            />
            <ErrorMessage
              name="pendingEmi"
              component="div"
              className="text-danger"
            />
          </CCol>
        </CRow>
        <CRow style={{ margin: "20px 0" }}>
          <CCol xs={6} className="position-relative">
            <Field
              as={CFormInput}
              type="number"
              id="dob"
              name="paidEmi"
              floatingClassName="mb-4"
              floatingLabel="Paid Emi"
            //   placeholder="Date of Birth"
            />
            <ErrorMessage
              name="paidEmi"
              component="div"
              className="text-danger"
            />
          </CCol>

          <CCol xs={6} className="position-relative">
            <Field
              as={CFormInput}
              type="number"
              id="fatherName"
              name="totalEmi"
              floatingClassName="mb-4"
              floatingLabel="Total Emi"
            //   placeholder="Father's Name"
            />
            <ErrorMessage
              name="totalEmi"
              component="div"
              className="text-danger"
            />
          </CCol>
        </CRow>
        <div style={{ margin: "0px 0px 20px" }}>
          <CRow>
            <CCol xs={6} className="position-relative">
              {/* <label htmlFor="gender" className="form-label">
              Gender
            </label> */}

              <Field
                size="lg"
                as={CFormSelect}
                id="gender"
                name="loanType"
                className="form-select"
              >
                <option value="" disabled>
                  Select Loan Type
                  <span className="red-star">{` `}*</span>
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Field>
              <ErrorMessage
                name="loanType"
                component="div"
                className="text-danger err_select"
              />
            </CCol>
          </CRow>
        </div>

        <CRow style={{ margin: "20px 0" }}>
          <CCol xs={6} className="position-relative">
            <Field
              type="number"
              id="rate"
              name="rate"
              as={CFormInput}
              floatingClassName="mb-4"
              floatingLabel="Rate"
            />
            <ErrorMessage name="rate" component="div" className="text-danger" />
          </CCol>
        </CRow>

        <div className="identification_btn">
          <CButton type="submit">Submit </CButton>
        </div>
      </Form>
    </Formik>
  );
};
RegistrationForm.propTypes = {
  formData: PropTypes.object,
  updateFormData: PropTypes.func,
  setKey: PropTypes.func,
};
export default RegistrationForm;
