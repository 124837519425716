import React, { useEffect, useState } from "react";

import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { getStaffDetailsById } from "src/redux/actions/staffAction";
import { useSelector } from "react-redux";
const AgentDetailsById = ({

  setShowAccordion,
  setShowSelectAndSearch,
}) => {
  const dispatch = useDispatch();
  const { staffOwnData } = useSelector((state) => state.staffReducer)
  const [SchemaName, setSchemaName] = useState(
    sessionStorage.getItem("schemaName")
  );

  const [employee, seteEmployee] = useState(
    sessionStorage.getItem("emplyeeId")
  );

  const selectedCompany = staffOwnData?.data

  useEffect(() => {
    const obj = {
      id: employee,
      schemaName: SchemaName
    }
    dispatch(getStaffDetailsById(obj));
  }, []);
  return (
    <>
      <div className="close_btn">
        <div>
          <strong>Staff Details</strong>
        </div>
      </div>
      <CCardBody>
        <CRow style={{ margin: "20px 0px 0px 10px" }}>
          <CCol xs={4} className="position-relative">
            <label className="label">Staff First Name</label>
            <CCard>
              <CCardBody>{selectedCompany?.firstName} </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={4} className="position-relative">
            <label className="label">Staff Middle Name</label>
            <CCard>
              <CCardBody>{selectedCompany?.middleName} </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={4} className="position-relative">
            <label className="label">Staff Last Name</label>
            <CCard>
              <CCardBody>{selectedCompany?.lastName} </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={6}>
            <label className="label">Staff Job Title</label>
            <CCard>
              <CCardBody>{selectedCompany?.jobTitle} </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Mobile Number</label>
            <CCard>
              <CCardBody>{selectedCompany?.phoneNumber} </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <div className="mt-2"></div>
        <CRow>
          <CCol xs={6}>
            <label>Staff Mail Id</label>
            <CCard>
              <CCardBody>{selectedCompany?.email} </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Aadhar Number</label>
            <CCard>
              <CCardBody>{selectedCompany?.aadharNumber} </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <div className="mt-2"></div>
        <CRow>
          <CCol xs={6}>
            <label>Staff Bank Account No.</label>
            <CCard>
              <CCardBody>{selectedCompany?.bankAccountNo} </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff IFSC Code</label>
            <CCard>
              <CCardBody>{selectedCompany?.ifscCode}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Basic Salary</label>
            <CCard>
              <CCardBody>{selectedCompany?.basicSalary}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Degree And Certificate</label>
            <CCard>
              <CCardBody>{selectedCompany?.degreeAndCertificate}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Driving Licence</label>
            <CCard>
              <CCardBody>{selectedCompany?.dlNumber}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff D.O.B.</label>
            <CCard>
              <CCardBody>{selectedCompany?.dob}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Emergency Contact Information</label>
            <CCard>
              <CCardBody>
                {selectedCompany?.emergencyContactInformation}
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Emergency Contact Number</label>
            <CCard>
              <CCardBody>{selectedCompany?.emergencyContactNumber}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Employee Branch Code</label>
            <CCard>
              <CCardBody>{selectedCompany?.empbranchCode}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Employee Role</label>
            <CCard>
              <CCardBody>{selectedCompany?.employeeRole}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Employee Verification</label>
            <CCard>
              <CCardBody>{selectedCompany?.employeeVerification}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Gender</label>
            <CCard>
              <CCardBody>{selectedCompany?.gender}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Highest Education</label>
            <CCard>
              <CCardBody>{selectedCompany?.emergencyContactNumber}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Identification</label>
            <CCard>
              <CCardBody>{selectedCompany?.identification}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Manager Name</label>
            <CCard>
              <CCardBody>{selectedCompany?.managerName}</CCardBody>
            </CCard>
          </CCol>{" "}
          <CCol xs={6}>
            <label>Staff Medical Insurance</label>
            <CCard>
              <CCardBody>{selectedCompany?.medicaInsurance}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Passport Number</label>
            <CCard>
              <CCardBody>{selectedCompany?.passportNumber}</CCardBody>
            </CCard>
          </CCol>
          <CCol xs={6}>
            <label>Staff Previous Work Experience</label>
            <CCard>
              <CCardBody>{selectedCompany?.previousWorkExperience}</CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </>
  );
};
AgentDetailsById.propTypes = {
  setShowAccordion: PropTypes.func,
  setShowSelectAndSearch: PropTypes.func,

};

export default AgentDetailsById;
