import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import {
  createBranches,
  getAllBranches,
} from "src/redux/actions/customerAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pageRefresh } from "src/redux/actions/userAction";
import { successAlert } from "src/utils/Swal";

const CreateBranchForm = ({
  setShowAddForm,
  setShowAddButton,
  setShowSelectAndSearch,
}) => {
  const dispatch = useDispatch();
  const { renderComp } = useSelector((state) => state?.customerReducer);
  const SchemaName = sessionStorage.getItem("schemaName");
  const CompanyId = sessionStorage.getItem("companyId");

  const initialFormData = {
    schemaName: SchemaName,
    email: "",
    address: "",
    branchName: "",
    contactNumber: "",
    branchCode: "",
    companyId: CompanyId,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [inputErrors, setInputErrors] = useState({
    branchName: "",
    email: "",
    branchCode: "",
    contactNumber: "",
    address: "",
  });

  const handleShowAddButton = () => {
    setShowSelectAndSearch(true);
    setShowAddButton(true);
    setShowAddForm(false);
  };

  const obj = {
    schemaName: SchemaName,
  };

  const handleAddForm = (e) => {
    e.preventDefault();

    const isFormValid = Object.values(inputErrors).every(
      (error) => error === ""
    );
    if (isFormValid) {
      dispatch(createBranches(formData));
      successAlert("Success", "New Branch Successfully Created");
      setShowAddButton(true);
      setShowAddForm(false);
      setShowSelectAndSearch(true);
    }
  };

  useEffect(() => {
    if (renderComp === true) {
      if (validateField()) {
        dispatch(getAllBranches(obj));
        setShowSelectAndSearch(true);
        setShowAddButton(true);
        setShowAddForm(false);
        dispatch(pageRefresh());
      }
    }
  }, [renderComp]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "contactNumber") {
      const sanitizedValue = value.replace(/[^\d]/g, "");
      setFormData({
        ...formData,
        [name]: sanitizedValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setInputErrors({
      ...inputErrors,
      [name]: validateField(name, value),
    });
  };

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "branchName":
        return value.trim() === ""
          ? "Branch Name cannot be empty!"
          : !/^[A-Za-z\s]+$/.test(value)
            ? "Branch Name should contain only alphabets!"
            : "";
      case "email":
        return value.trim() === "" ? "Email cannot be empty!" : "";
      case "branchCode":
        return value.trim() === "" ? "Branch Code cannot be empty!" : "";
      case "contactNumber":
        return value.length !== 10 || isNaN(value)
          ? "Please enter a valid 10-digit phone number!"
          : "";
      case "address":
        return value.trim() === "" ? "Address cannot be empty!" : "";
      default:
        return "";
    }
  };
  const { email, address, branchName, contactNumber, branchCode } = formData;

  return (
    <CCard>
      <CCardHeader>
        <div className="close_btn p-0">
          <div>
            <strong>Add Branch</strong>
          </div>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => handleShowAddButton()}
          ></button>
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <h6 className="color_heading">Note : (please do not refresh the page)</h6>
        </CRow>
        <CRow>
          <CCol xs={12}>
            <CForm onSubmit={handleAddForm}>
              <CRow className="row_margin">
                <CCol xs={6}>
                  <div className="mb-4">
                    <CFormLabel htmlFor="branch_name">
                      Branch Name
                      <span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Branch Name"
                      id="branch_name"
                      name="branchName"
                      value={branchName}
                      onChange={handleInputChange}
                      required
                    />
                    <span className="red-star">{inputErrors.branchName}</span>
                  </div>
                </CCol>

                <CCol xs={6}>
                  <div className="mb-4">
                    <CFormLabel htmlFor="branch_location">
                      Branch Code
                      <span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      id="branch_location"
                      placeholder="Branch Code"
                      name="branchCode"
                      value={branchCode}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </CCol>

                <CCol xs={6}>
                  <div className="mb-4">
                    <CFormLabel htmlFor="email">
                      Branch Email Address
                      <span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <CFormInput
                      type="email"
                      placeholder="Email Address"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </CCol>

                <CCol xs={6}>
                  <div className="mb-4">
                    <CFormLabel htmlFor="branch_contact">
                      Branch Contact Number
                      <span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      placeholder="Contact Number"
                      id="branch_contact"
                      name="contactNumber"
                      value={contactNumber}
                      onChange={handleInputChange}
                      required
                    />
                    <span className="red-star">{inputErrors.contactNumber}</span>
                  </div>
                </CCol>

                <CCol xs={6}>
                  <div className="mb-4">
                    <CFormLabel htmlFor="branch_address">
                      Branch Address
                      <span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      id="branch_address"
                      placeholder="Address"
                      name="address"
                      value={address}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </CCol>
                <CCol xs={12}>
                  <h6
                    style={{
                      color: "#FF0000",
                      fontSize: 14,
                      textAlign: "center",
                    }}
                  >
                  </h6>
                </CCol>
              </CRow>
              <div className="branch_btn">
                <CButton type="submit">Submit</CButton>
              </div>
            </CForm>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};
CreateBranchForm.propTypes = {
  setShowAddForm: PropTypes.func.isRequired,
  setShowAddButton: PropTypes.func.isRequired,
  setShowSelectAndSearch: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
  intial: PropTypes.object,
  edit: PropTypes.bool,
  callBack: PropTypes.func,
};

export default CreateBranchForm;

