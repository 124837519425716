import * as React from "react";
import Grid from "@mui/material/Grid";

import Vakilkaro from "src/assets/brand/vakilkaro";
import { COMPANY_NAME, S3_BASE_URL } from "src/services/const";


const FinalDetailsForm = (data) => {

  const { formdata, componentPDF, printState } = data;

  const {
    Nomniee,
    address: formDataAddress,
    bankDetails,
    identiFication,
    registration,
    income: formdataIncome,
  } = formdata;

  const { area, city, country, district, pincode, state } = formDataAddress;

  const {
    accountNumber,
    bankName,
    bankStatementsImageUrl,
    branchName,
    creditScore,
    ifsc,
  } = bankDetails;

  const {
    PanNumber,
    aadharImageUrl,
    aadharNumber,
    panImageUrl,
    passportSizePhoto,
    singnatureImageUrl,
  } = identiFication;

  const { income, monthlyExpenses } = formdataIncome;

  const { GuarantorName, address, fathersName, mobileNumber, aadhar, pan } =
    formdataIncome;

  const {
    dob, email, empStartDate, fatherName, firstName, gender, lastName, loanAmountRequsted, loanTerm, loanType, martialStatus, middleName, occupation, phoneNumber
  } = registration;


  return (
    <div
      ref={printState ? componentPDF : null}
      style={{ padding: printState ? "20px" : "0px" }}
    >
      {printState && (
        <div className="print-div">
          <div>
            <Vakilkaro />
          </div>
          <div>
            <p>{COMPANY_NAME}</p>
          </div>
        </div>
      )}
      <div className="customer-details-form" ref={componentPDF}>
        <div className="heading-div"> PERSONAL DETAILS </div>
        <div className="container-div">
          <div style={{ width: "100%", height: "auto" }}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <div className="title-div">Customer Name</div>
              </Grid>
              <Grid item xs={9}>
                <div className="ans-div ans-div-capitalize">
                  {firstName} {middleName == "undefined" ? "" : middleName}{" "}
                  {lastName}
                </div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">E-mail Address</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{email}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Phone Number</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{phoneNumber}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">D.O.B.</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{dob}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Gender</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div ans-div-capitalize">{gender}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Marital Status</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div ans-div-capitalize">
                  {martialStatus}
                </div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">{"Father's Name"}</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div ans-div-capitalize">{fatherName}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Occupation</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div ans-div-capitalize">{occupation}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Emp. Start Date</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{empStartDate}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Income</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{income}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Monthly Expenses</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{monthlyExpenses}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Address</div>
              </Grid>
              <Grid item xs={9}>
                <div className="ans-div ans-div-capitalize">
                  {area}
                  {area ? ", " : ""}
                  {district}
                  {district ? ", " : ""}
                  {city}
                  {city ? ", " : ""}
                  {state}
                  {state ? ", " : ""}
                  {country}
                  {country ? ", " : ""}
                  {pincode ? "(" : ""}
                  {pincode}
                  {pincode ? ")" : ""}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="heading-div"> BANK DETAILS </div>
        <div className="container-div">
          <div style={{ width: "100%", height: "auto" }}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <div className="title-div">Account No.</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{accountNumber}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Bank Name</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{bankName}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Branch Name</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{branchName}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">IFSC</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{ifsc}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Credit Score</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{creditScore}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Bank Statement Image</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">
                  {/* <img src={bankStmt} alt="loading" height={"90px"} /> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="heading-div"> AADHAR AND PAN DETAILS </div>
        <div className="container-div">
          <div style={{ width: "100%", height: "auto" }}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <div className="title-div">Aadhar No.</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{aadharNumber}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">PAN No.</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div ans-div-uppercase">{PanNumber}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Aadhar Image</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div ans-div-uppercase">
                  {/* <img src={adharUrl} alt="loading" height={"90px"} /> */}
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="title-div">PAN Image</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">
                  {/* <img src={panUrl} alt="loading" height={"90px"} /> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="heading-div"> LOAN DETAILS </div>
        <div className="container-div">
          <div style={{ width: "100%", height: "auto" }}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <div className="title-div">Loan Amount Requested</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{loanAmountRequsted}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Loan Type</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{loanType}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Loan Term [in Months]</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{loanTerm}</div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="heading-div"> OTHER DETAILS </div>
        <div className="container-div">
          <div style={{ width: "100%", height: "auto" }}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <div className="title-div">Sanction Letter Image</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">
                  {/* <img src={sanctionUrl} alt="loading" height={"90px"} /> */}
                </div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Customer Signature </div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">
                  <img
                    src={`${S3_BASE_URL}/${singnatureImageUrl}`}
                    alt="loading"
                    height={"90px"}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="heading-div"> FIRST GUARANTOR DETAILS </div>
        <div className="container-div">
          <div style={{ width: "100%", height: "auto" }}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <div className="title-div">Guarantor Name</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{Nomniee.GuarantorName}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Mobile Number</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{Nomniee.mobileNumber}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">{`Father's Name`}</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{Nomniee.fathersName}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Aadhar Number</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{Nomniee.aadhar}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">PAN Number</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{Nomniee.pan}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Address</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{Nomniee.address}</div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="heading-div"> SECOND GUARANTOR DETAILS </div>
        <div className="container-div">
          <div style={{ width: "100%", height: "auto" }}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <div className="title-div">Guarantor Name</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div ans-div-capitalize">
                  {Nomniee.GuarantorName1}
                </div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Mobile Number</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{Nomniee.mobileNumber1}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">{`Father's Name`}</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{Nomniee.fathersName1}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Aadhar Number</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{Nomniee.aadhar1}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">PAN Number</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{Nomniee.pan1}</div>
              </Grid>

              <Grid item xs={3}>
                <div className="title-div">Address</div>
              </Grid>
              <Grid item xs={3}>
                <div className="ans-div">{Nomniee.address1}</div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};


export default FinalDetailsForm;
