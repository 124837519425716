import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faTimes } from "@fortawesome/free-solid-svg-icons";

import Tooltip from "src/components/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-js-pagination";
import AddForm from "../../customer/addForm";
import CustomerAllDetails from "../../customer/customerAllDetails";
import { getAllCustomers, updateCustomer } from "src/api/allApis";
import { createEmi } from "src/redux/actions/emiAction";

const Pending = () => {
  const { update } = useSelector((state) => state?.customerReducer);
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customersPendingStatus, setCustomersPendingStatus] = useState([]);
  const [pendingData, setPendingData] = useState();

  const SchemaName = sessionStorage.getItem("schemaName");
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    getAllCompanyData();
  }, []);
  useEffect(() => {
    if (update === true) {
      getAllCompanyData();
    }
  }, [update]);

  useEffect(() => {
    const loanApprovalStatus = customersPendingStatus?.filter(
      (l) => l.loanApprovalStatus === null || l.loanApprovalStatus === "pending"
    );
    setPendingData(loanApprovalStatus);
  }, [customersPendingStatus]);

  const getAllCompanyData = () => {
    const payload = { schemaName: SchemaName };
    getAllCustomers(payload).then((res) => {
      setCustomersPendingStatus(res?.data);
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const currentRows = pendingData?.slice(indexOfFirstRow, indexOfLastRow);

  // const handleShowAddForm = () => {
  //   // When the user clicks the "Add" button, hide the button and show the form
  //   setShowAddButton(false);
  //   setShowSelectAndSearch(false);
  //   setShowAddForm(true);
  // };

  const handleViewdetails = (company) => {
    setShowSelectAndSearch(false);
    setSelectedCompany(company);
    setShowAccordion(true);
  };

  const handleApproved = async (customerData) => {

    let formData = new FormData();
    formData.append("schemaName", SchemaName);
    formData.append("loanApprovalStatus", "approved");
    const updatedCompanyStatus = {
      id: customerData?.id,
      data: formData,
    };
    await updateCustomer(updatedCompanyStatus);
    const emiCreate = {
      schemaName: SchemaName,
      loadAmount: customerData?.loanAmountRequested,
      loadDurationInMonths: customerData?.loanTerm,
      annualInterestRate: 8,
      customerId: customerData?.id
    }
    // dispatch(createEmi(emiCreate))
    getAllCompanyData();
  };

  const handleRejected = async (customerData) => {
    let formData = new FormData();
    formData.append("schemaName", SchemaName);
    formData.append("loanApprovalStatus", "rejected");
    const updatedCompanyStatus = {
      id: customerData?.id,
      data: formData,
    };
    await updateCustomer(updatedCompanyStatus);
    getAllCompanyData();
  };

  return (
    <>
      <CRow className="p-1">
        <CCol xs={12}>
          <CCard className="mb-4">
            {/* <div className="btn_add">
              {roletype !== "customer" && showAddButton && (
                <Button className="add-btn" onClick={handleShowAddForm}>
                  Add
                </Button>
              )}
            </div> */}

            <div className="search_bar">
              {showSelectAndSearch && (
                <div>
                  <CFormSelect
                    size="sm"
                    className="mb-3"
                    style={{ cursor: "pointer" }}
                    aria-label="Small select example"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </CFormSelect>
                </div>
              )}
              <div style={{ display: "flex" }}>
                {showSelectAndSearch && (
                  <>
                    <CFormInput
                      type="text"
                      size="sm"
                      placeholder="Search"
                      aria-label="sm input example"
                      style={{ margin: "0px 0px 16px 0px" }}
                    />

                    <span
                      style={{
                        border: " 1px solid #d1c5c5",
                        borderRadius: "4px",
                        margin: "0px 5px 16px -1px",
                        padding: "1px 8px 0px 8px",
                        background: "#ebedef",
                        cursor: "pointer",
                      }}
                    >
                      <i className="fa fa-search"></i>
                    </span>
                  </>
                )}
              </div>
            </div>

            <CCardBody>
              <div
                className={`table-container ${showAccordion ? "hidden" : ""} ${showAddForm ? "hidden" : ""
                  }`}
              >
                <CTable color="dark" hover className="" responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col" className="col-md-1">
                        S. No.
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Address
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Email Id
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Mobile Number
                      </CTableHeaderCell>
                      {role === "employee" ? (
                        ""
                      ) : (
                        <CTableHeaderCell scope="col" className="col-md-3">
                          Action
                        </CTableHeaderCell>
                      )}
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {pendingData && pendingData.length > 0 ? (
                      currentRows?.map((customerData, index) => (
                        <>
                          <CTableRow key={index}>
                            <CTableHeaderCell
                              scope="row"
                              style={{ paddingLeft: "15px" }}
                            >
                              {indexOfFirstRow + index + 1}
                            </CTableHeaderCell>
                            <CTableDataCell>
                              {customerData?.firstName || "--"}
                            </CTableDataCell>
                            <CTableDataCell>
                              {customerData?.address || "--"}
                            </CTableDataCell>
                            <CTableDataCell>
                              {customerData?.email || "--"}
                            </CTableDataCell>
                            <CTableDataCell>
                              {customerData?.phoneNumber || "--"}
                            </CTableDataCell>
                            {role === "employee" ? (
                              ""
                            ) : (
                              <CTableDataCell
                                style={{ padding: "10px 0px 10px 0px" }}
                                className="col-md-3 "
                              >
                                <>
                                  <Tooltip text="approved">
                                    <FontAwesomeIcon
                                      onClick={() =>
                                        handleApproved(customerData)
                                      }
                                      role="button"
                                      icon={faCheck}
                                      size="1x"
                                      className="deleteIcon"
                                      style={{ marginRight: 15 }}
                                    />
                                  </Tooltip>

                                  <Tooltip text="reject">
                                    <FontAwesomeIcon
                                      role="button"
                                      icon={faTimes}
                                      size="1x"
                                      className="viewIcon"
                                      onClick={() =>
                                        handleRejected(customerData)
                                      }
                                      style={{ marginRight: 15 }}
                                    />
                                  </Tooltip>

                                  <Tooltip text="View">
                                    <FontAwesomeIcon
                                      role="button"
                                      icon={faEye}
                                      size="1x"
                                      className="viewIcon"
                                      onClick={() =>
                                        handleViewdetails(customerData)
                                      }
                                    />
                                  </Tooltip>
                                </>
                              </CTableDataCell>
                            )}
                          </CTableRow>
                        </>
                      ))
                    ) : (
                      // Display a loading message or handle the case where data is not available
                      <div className="loading_data">Loading...</div>
                    )}
                  </CTableBody>
                </CTable>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontSize: "16px", fontWeight: "600" }}>
                    Showing {indexOfFirstRow + 1} to{" "}
                    {Math?.min(indexOfLastRow, pendingData?.length)} of{" "}
                    {pendingData?.length} entries
                  </div>
                  <div className="pagination-container">
                    <ReactPaginate
                      activePage={currentPage}
                      itemsCountPerPage={rowsPerPage}
                      totalItemsCount={pendingData?.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      prevPageText="Previous"
                      nextPageText="Next"
                      firstPageText="First"
                      lastPageText="Last"
                      innerClass="pagination"
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </div>
              {showAccordion && (
                <CustomerAllDetails
                  selectedCompany={selectedCompany}
                  setShowAccordion={setShowAccordion}
                  setShowSelectAndSearch={setShowSelectAndSearch}
                />
              )}
              {showAddForm && (
                <AddForm
                  selectedCompany={selectedCompany}
                  setShowAddForm={setShowAddForm}
                  setShowAddButton={setShowAddButton}
                  setShowSelectAndSearch={setShowSelectAndSearch}
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <div
            className={`accordion-container ${showAccordion ? "visible" : ""} ${showAddForm ? "visible" : ""
              }`}
          ></div>
        </CCol>
      </CRow>
    </>
  );
};

export default Pending;
