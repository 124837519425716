import React from "react";

import {
  // CAccordion,
  // CAccordionBody,
  // CAccordionHeader,
  // CAccordionItem,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
const CustomerAllDetails = ({
  selectedCompany,
  setShowAccordion,
  setShowSelectAndSearch,
}) => {
  const handleCloseAccordian = () => {
    setShowSelectAndSearch(true);
    setShowAccordion(false);
  };
  return (
    <>
      <div className="close_btn">
        <div></div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => handleCloseAccordian()}
        ></button>
      </div>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader></CCardHeader>
            <CCardBody>
              <CRow>
                <CCol xs={6}>
                  <label className="label">Customer Name</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.companyName} </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>Customer Loan Type</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.companyMobileNumber}{" "}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Rate</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.companyMailId} </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>Paid Emi</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.companyPassword} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label className="label">Pending Emi</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.companyName} </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>Total EMIs</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.companyMobileNumber}{" "}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
CustomerAllDetails.propTypes = {
  setShowAccordion: PropTypes.func.isRequired,
  setShowSelectAndSearch: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
};

export default CustomerAllDetails;
