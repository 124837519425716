import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getGroupDetailById } from "src/redux/actions/userAction";
import { useSelector } from "react-redux";
import AddForm from "../customer/addForm";
import { useReactToPrint } from "react-to-print";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { COMPANY_NAME } from "src/services/const";

const GroupDetailsById = ({
  setShowGroupDetails,
  showGroupDetails,
  groupList,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentPDF = useRef();
  const { groupDetails } = useSelector((state) => state?.userReducer);
  const SchemaName = sessionStorage.getItem("schemaName");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showCustomerAddForm, setShowCustomerAddForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [staffData, setStaffData] = useState([]);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = staffData?.slice(indexOfFirstRow, indexOfLastRow);
  const [printState, setPrintState] = useState(false);
  const obj = {
    id: groupList?.id,
    schemaName: SchemaName,
  };

  useEffect(() => {
    dispatch(getGroupDetailById(obj));
  }, []);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);


  const handleCustomer = () => {
    setShowCustomerAddForm(true);
  };

  const download_btn = () => {
    setPrintState(true);
  }
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  return (
    <CRow >
      {showCustomerAddForm === true ? (
        <AddForm
          setShowCustomerAddForm={setShowCustomerAddForm}
          showCustomerAddForm={showCustomerAddForm}
          groupId={groupList?.id}
        />
      ) : (
        <CCol xs={12} style={{ marginTop: 102 }}>
          <CCard className="mb-4">
            <CCardHeader>
              <div className="group_top_header">
                <span className="group_details">
                  {groupList?.groupName
                    ? groupList?.groupName
                    : "name Group Details"}
                </span>
                <CButton onClick={() => setShowGroupDetails(false)}>
                  Back
                </CButton>
              </div>
            </CCardHeader>
            <CRow>
              <CCol xs={12}>
                <CCardBody>
                  <CCard>
                    <CCardBody>
                      <CContainer>
                        <CRow>
                          <CCol xs={6} className="">
                            <CCard>
                              <CCardBody>
                                <span className="group_details">
                                  Group Name :{" "}
                                  <span>{groupList?.groupName}</span>{" "}
                                </span>
                              </CCardBody>
                            </CCard>
                          </CCol>
                          <CCol xs={6}>
                            <CCard>
                              <CCardBody>
                                <span className="group_details">
                                  Group Code :{" "}
                                  <span>{groupList?.groupCode}</span>{" "}
                                </span>
                              </CCardBody>
                            </CCard>
                          </CCol>
                        </CRow>
                        <CRow className="mt-2">
                          <CCol xs={6}>
                            <CCard>
                              <CCardBody>
                                <span className="group_details">
                                  Group Head Name :{" "}
                                  <span>{groupList?.groupHeadName}</span>{" "}
                                </span>
                              </CCardBody>
                            </CCard>
                          </CCol>
                          <CCol xs={6}>
                            <CCard>
                              <CCardBody>
                                <span className="group_details">
                                  Branch :{" "}
                                  <span>
                                    {groupList?.branchId
                                      ? groupList?.branchId
                                      : "--"}
                                  </span>{" "}
                                </span>
                              </CCardBody>
                            </CCard>
                          </CCol>
                        </CRow>
                        <CCard className="mt-2">
                          <CCardBody>
                            <CCardHeader>
                              <div className="group_top_header">
                                <span className="group_details">
                                  All Customers
                                </span>
                                <div className="btn_add mt-0 mb-14" >
                                  <CButton
                                    // className="add-btn m-0 w-auto"
                                    className="mr-10"
                                    onClick={handleCustomer}
                                  >
                                    Add Customer
                                  </CButton>
                                  <CButton
                                    // className="add-btn m-0 w-auto"
                                    onClick={download_btn}
                                  >
                                    Download
                                  </CButton>
                                </div>
                              </div>
                            </CCardHeader>
                            <div
                              ref={printState ? componentPDF : null}
                              style={{ padding: printState ? "20px" : "0px" }}
                            >
                              {printState && (
                                <div className="print-div">
                                  <div>
                                    <Vakilkaro />
                                  </div>
                                  <div>
                                    <p>{COMPANY_NAME}</p>
                                  </div>
                                </div>
                              )}

                              <CTable>
                                <CTableHead>
                                  <CTableRow>
                                    <CTableHeaderCell scope="col">
                                      S. No.
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Customer Name
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Customer Email
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Phone Number
                                    </CTableHeaderCell>
                                    <CTableHeaderCell scope="col">
                                      Occupation
                                    </CTableHeaderCell>
                                  </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                  {groupDetails?.data?.customers?.map(
                                    (value, index) => (
                                      <CTableRow key={index}>
                                        <CTableDataCell>
                                          {index + 1}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {`${value?.firstName} ${value?.lastName}`}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {value?.email ? value?.email : "--"}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {value?.phoneNumber
                                            ? value?.phoneNumber
                                            : "--"}
                                        </CTableDataCell>
                                        <CTableDataCell>
                                          {value?.occupation
                                            ? value?.occupation
                                            : "--"}
                                        </CTableDataCell>
                                      </CTableRow>
                                    )
                                  )}
                                </CTableBody>
                              </CTable>
                            </div>
                          </CCardBody>
                        </CCard>
                      </CContainer>
                    </CCardBody>
                  </CCard>
                </CCardBody>
              </CCol>
            </CRow>
          </CCard>
        </CCol>
      )}
    </CRow>
  );
};
GroupDetailsById.propTypes = {
  setShowGroupDetails: PropTypes.func.isRequired,
  showGroupDetails: PropTypes.bool.isRequired,
  groupList: PropTypes.object.isRequired,
};
export default GroupDetailsById;
