import { put, takeLatest, call } from "redux-saga/effects";
import authInstance from "../../api/apiConfig";
import {
  ERROR,
  GET_ALL_COMPANY,
  GET_ALL_COMPANY_SUCCESS,
  ADD_COMPANY_DATA,
  ADD_COMPANY_DATA_SUCCESS,
  DELETE_COMPANY_BY_ID,
  DELETE_COMPANY_BY_ID_SUCCESS,
  UPDATE_COMPANY_BY_ID,
  UPDATE_COMPANY_BY_ID_SUCCESS,
  DELETE_LOAN_BY_ID,
  CREATE_AADHAR_VERIFICATION_SUCCESS,
  CREATE_AADHAR_VERIFICATION,
  AADHAR_VERIFICATION_OTP_SUCCESS,
  AADHAR_VERIFICATION_OTP,
  DELETE_LOAN_BY_ID_SUCCESS,
  NEW_ERROR,
  NEW_VERY_ERROR,
  UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID,
  UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID_SUCCESS
} from "../action_Types";

function* allCompany() {
  try {
    const response = yield call(authInstance.post, "companies");

    yield put({
      type: GET_ALL_COMPANY_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* addCompany(action) {
  try {
    const response = yield call(authInstance.post, "company", action.payload);

    yield put({
      type: ADD_COMPANY_DATA_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* deleteCompany(action) {
  const { itemId, itemSchemaName } = action.payload;
  try {
    const response = yield call(authInstance.post, `deleteCompany/${itemId}`, {
      schemaName: itemSchemaName,
    });

    yield put({
      type: DELETE_COMPANY_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* deleteLoan(action) {
  try {
    const { selectedItemId } = action.payload;
    const response = yield call(
      authInstance.post,
      `deleteInterest/${selectedItemId}`,
      action.payload
    );
    yield put({
      type: DELETE_LOAN_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updateCompany(action) {
  try {
    const { id, data } = action.payload;

    const response = yield call(authInstance.patch, `company/${id}`, data);
    yield put({
      type: UPDATE_COMPANY_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* updateCompanyAaddharTokenLimit(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(authInstance.patch, `company/${id}/updateAaddharToken`, data);
    yield put({
      type: UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error.response.data.message;
    yield put({
      type: ERROR,
      payload: err,
    });
  }
}

function* aadharVerification(action) {
  try {
    const response = yield call(
      authInstance.post,
      "sendAadharOtp",
      action.payload
    );
    yield put({
      type: CREATE_AADHAR_VERIFICATION_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error;
    console.log(err,"errrrrrrrrrrrrrrrrr")
    yield put({
      type: NEW_ERROR,
      payload: err,
    });
  }
}
function* aadharOtpVerification(action) {
  try {
    const response = yield call(
      authInstance.post,
      "verifyAadharOtp",
      action.payload
    );
    yield put({
      type: AADHAR_VERIFICATION_OTP_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    const err = error;
    yield put({
      type: NEW_VERY_ERROR,
      payload: err,
    });
  }
}

export default function* companyActionWatcher() {
  yield takeLatest(ADD_COMPANY_DATA, addCompany);
  yield takeLatest(GET_ALL_COMPANY, allCompany);
  yield takeLatest(DELETE_COMPANY_BY_ID, deleteCompany);
  yield takeLatest(DELETE_LOAN_BY_ID, deleteLoan);
  yield takeLatest(UPDATE_COMPANY_BY_ID, updateCompany);
  yield takeLatest(UPDATE_COMPANY_AADDHAR_TOKEN_LIMIT_BY_ID, updateCompanyAaddharTokenLimit);
  yield takeLatest(CREATE_AADHAR_VERIFICATION, aadharVerification);
  yield takeLatest(AADHAR_VERIFICATION_OTP, aadharOtpVerification);
}

