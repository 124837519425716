import { CButton } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Emi from "../emi";
import FinalDetailsForm from "../customer/finalDetailsForm";
import { useReactToPrint } from "react-to-print";

const NomineeGroup = (props) => {
  const [showEmi, setShowEmi] = useState(false);
  const [emiList, setEmiList] = useState([]);
  const [totalAmountWithInterest, setTotalAmountWithInterest] = useState(0);
  const [totalAmountToPayWithInterest, setTotalAmountToPayWithInterest] =
    useState(0);

  const handleSubmit = () => {
    props.handleFormSubmit();
  };

  const [printState, setPrintState] = useState(false);

  const calculateEMI = (loanAmount, loanTerm, interestRate) => {
    const monthlyInterestRate = interestRate / 100 / 12;
    const totalMonths = loanTerm;

    const emiArray = [];
    let outstandingBalance = loanAmount;

    for (let month = 1; month <= totalMonths; month++) {
      const interestPayment = outstandingBalance * monthlyInterestRate;
      const principalPayment = loanAmount / totalMonths;
      const emi = principalPayment + interestPayment;
      outstandingBalance -= principalPayment;

      const totalAmountWithInterest = emi + principalPayment + interestPayment;
      emiArray.push({
        month,
        emi,
        principalPayment,
        interestPayment,
        totalAmountWithInterest,
      });
    }

    return emiArray;
  };

  const componentPDF = useRef();

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);

  const handlePrint = () => {
    setPrintState(true);
  }
  const generatePDF = useReactToPrint({

    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const handleCalculation = () => {
    const loanAmount = props?.formdata?.loanAmountRequsted;
    const loanTerm = props?.formdata?.loanTerm;
    const interestRate = 7;

    const emiArray = calculateEMI(loanAmount, loanTerm, interestRate);
    const totalAmount =
      emiArray[0].emi +
      emiArray[0].principalPayment +
      emiArray[0].interestPayment;
    setTotalAmountWithInterest(totalAmount);

    setEmiList(emiArray);

    const totalAmountToPay =
      emiArray[emiArray?.length - 1]?.totalAmountWithInterest;
    setTotalAmountToPayWithInterest(totalAmountToPay);
    setShowEmi(true);
  };

  return (
    <div>
      <FinalDetailsForm
        formdata={props?.formdata}
        componentPDF={componentPDF}
        printState={printState}
        setPrintState={setPrintState} />
      {showEmi && (
        <Emi
          showEmi={showEmi}
          setShowEmi={setShowEmi}
          emiList={emiList}
          totalAmountWithInterest={totalAmountWithInterest}
        />
      )}
      <div className="identification_btn">
        
        <CButton
          style={{ margin: "0px -9px 18px 100px" }}
          className="next_btn"
          onClick={handlePrint}
        >
          Download
        </CButton>
        <CButton
          style={{ margin: "0px -9px 18px 100px" }}
          className="next_btn"
          onClick={handleSubmit}
        >
          Submit Form{" "}
        </CButton>
      </div>
    </div>
  );
};
NomineeGroup.propTypes = {
  formdata: PropTypes.object.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  emiList: PropTypes.array.isRequired,
};
export default NomineeGroup;

