import React, { useEffect, useState } from "react";
import {
  CCardHeader,
  CCol,
  CButton,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { successAlert } from "src/utils/Swal";
import { addInterests, editIntereset } from "src/redux/actions/loanListAction";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProductAddForm = ({
  setShowAddForm,
  setShowAddButton,
  setShowSelectAndSearch,
  showAddForm,
  intial,
  edit,
}) => {
  const { addData, updateData, error } = useSelector(
    (state) => state?.interestsReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submission, setSubmission] = useState(false);

  const SchemaName = sessionStorage.getItem("schemaName");

  const [formData, setFormData] = useState({
    loanType: intial?.loanType || "",
    rate: intial?.rate || "",
    schemaName: SchemaName,
    id: intial?.id || "",
  });

  const updateFormData = (formName, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: data,
    }));
  };

  const handleShowAddButton = () => {
    setShowSelectAndSearch(true);
    setShowAddButton(true);
    setShowAddForm(false);
  };

  const validationSchema = Yup.object().shape({
    loanType: Yup.string().required("Loan Type is required"),
    rate: Yup.number().required("Rate is required"),
  });

  useEffect(() => {
    if (error === false) {
      if (addData?.status === "success") {
        successAlert("Success", "Product Has Been Successfully Created");
        setSubmission(true);
        setShowAddForm(false);
        setShowSelectAndSearch(true);
        setShowAddButton(true);
      }
    }
  }, [addData]);

  const handleSubmit = (values) => {
    if (edit) {
      dispatch(editIntereset(values));
      successAlert("Success", "Product Has Been Successfully Updated");
      setSubmission(true);
      setShowAddForm(false);
      setShowSelectAndSearch(true);
      setShowAddButton(true);
      return;
    }
    updateFormData("interests", values);
    dispatch(addInterests(values));
  };

  return (
    <>
      <CRow>
        <CCardHeader>
          <div className="d-flex justify-content-between">
            <div>
              <strong>Add Loan Type</strong>
            </div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => handleShowAddButton()}
            />
          </div>
        </CCardHeader>

        {/* <CCard className="mb-4"> */}
        <CRow>
          <CCol xs={12}>
            <Formik
              initialValues={formData}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="p-4">
                <CRow>
                  <CCol xs={6} className="position-relative">
                    <CFormLabel htmlFor="loanType">
                      Loan Type
                      <span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <Field
                      type="text"
                      id="loanType"
                      name="loanType"
                      as={CFormInput}
                      placeholder="Loan Type"
                    />
                    <ErrorMessage
                      name="loanType"
                      component="div"
                      className="text-danger"
                    />
                  </CCol>

                  <CCol xs={6} className="position-relative">
                    <CFormLabel htmlFor="rate">
                      Rate
                      <span className="red-star">{` `}*</span>
                    </CFormLabel>
                    <Field
                      type="number"
                      id="rate"
                      name="rate"
                      as={CFormInput}
                      placeholder="Rate"
                    />
                    <ErrorMessage
                      name="rate"
                      component="div"
                      className="text-danger"
                    />
                  </CCol>
                </CRow>

                <div className="identification_btn">
                  <CButton type="submit" className="next_btn">
                    {edit ? "Update" : "Submit"}{" "}
                  </CButton>
                </div>
              </Form>
            </Formik>
          </CCol>
        </CRow>
      </CRow>
      {/* </CCard> */}
    </>
  );
};
ProductAddForm.propTypes = {
  showAddForm: PropTypes.bool,
  setShowAddForm: PropTypes.func,
  setShowAddButton: PropTypes.func,
  setShowSelectAndSearch: PropTypes.func,
  selectedCompany: PropTypes.object,
  intial: PropTypes.object,
  edit: PropTypes.bool,
  callBack: PropTypes.func,
};

export default ProductAddForm;
