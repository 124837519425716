import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Tooltip = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false)

  const showTooltip = () => {
    setIsVisible(true)
  }

  const hideTooltip = () => {
    setIsVisible(false)
  }

  return (
    <div className="tooltip-container" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {isVisible && <div className="tooltip">{text}</div>}
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
}

export default Tooltip
