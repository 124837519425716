import * as Yup from "yup";
import {
  CButton,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import _ from "lodash";

const initialValues = {
  pincode: "",
  country: "",
  area: "",
  city: "",
  district: "",
  state: "",
  socialSecurityNumber: "",
};

const Adress = (props) => {
  const { newOtpVerifiedData } = useSelector((state) => state?.companyReducer);

  const [submission, setSubmission] = useState(false);
  const [customerAddress, setCustomerAddress] = useState(
    props?.formData || initialValues
  );

  const validationSchema = Yup.object().shape({
    state: Yup.string().required("state is required"),
    district: Yup.string().required("district is required"),
    city: Yup.string().required("city  is required"),
    area: Yup.string().required("area is required"),
    country: Yup.string().required("country is required"),
    pincode: Yup.number().required("pincode is required"),
    socialSecurityNumber: Yup.string().required("SSN or NIN no is required"),
  });

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setCustomerAddress({ ...customerAddress, [name]: value });
  };

  const handleSubmit = (values) => {
    props.updateFormData("address", customerAddress);
    props.setKey("Identification", values);
    setSubmission(true);
  };

  useEffect(() => {
   if(!_.isEmpty(newOtpVerifiedData)){ 
    const count = newOtpVerifiedData?.data?.split_address?.country || "";
    const pin = newOtpVerifiedData?.data?.split_address?.pincode || "";
    const dist = newOtpVerifiedData?.data?.split_address?.dist || "";
    const sta = newOtpVerifiedData?.data?.split_address?.state || "";
    const street = newOtpVerifiedData?.data?.split_address?.street || "";
    const cty = newOtpVerifiedData?.data?.split_address?.po || "";

    setCustomerAddress((prevMainData) => ({
      ...prevMainData,
      country: count,
      pincode: pin,
      district: dist,
      state: sta,
      area: street,
      city: cty,
    }));
  }
  }, [newOtpVerifiedData]);

  const {
    pincode,
    country,
    area,
    city,
    district,
    state,
    socialSecurityNumber,
  } = customerAddress;

  return (
    <CCardBody>
      <CContainer>
        <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
          <CRow className="pt-20">
            
            <CCol xs={6}>
              <CFormLabel htmlFor="area">
                Plot/Building/Flat No.
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="text"
                id="area"
                placeholder="Plot/Building/Flat No."
                name="area"
                value={area}
                onChange={onChangeValue}
                disabled
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="district">Landmark</CFormLabel>
              <CFormInput
                type="text"
                id="district"
                placeholder="Landmark"
                name="district"
                value={district}
                onChange={onChangeValue}
                disabled
              />
            </CCol>
          </CRow>

          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="city">
                City
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="text"
                id="city"
                placeholder="City"
                name="city"
                value={city}
                onChange={onChangeValue}
                disabled
              />
            </CCol>

            <CCol xs={6}>
              <CFormLabel htmlFor="state">
                State
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="text"
                id="state"
                placeholder="State"
                name="state"
                value={state}
                onChange={onChangeValue}
                disabled
              />
            </CCol>
          </CRow>

          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="country">
                Country
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="text"
                id="country"
                placeholder="Country"
                name="country"
                value={country}
                onChange={onChangeValue}
                disabled
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="pincode">
                Pin Code
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="number"
                id="pincode"
                placeholder="Pin Code"
                name="pincode"
                value={pincode}
                onChange={onChangeValue}
                disabled
              />
            </CCol>
          </CRow>
          <div className="identification_btn">
            <CButton className="next_btn" type="submit">
              Next{" "}
            </CButton>
          </div>
        </CForm>
      </CContainer>
    </CCardBody>
  );
};
Adress.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
};
export default Adress;
