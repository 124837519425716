import React, { useState } from "react";
import {
  CButton,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";

import PropTypes from "prop-types";

const initialValues = {
  refName: "",
  referencePhoneNumber: "",
  nameProfessional: "",
  phoneNumberProfessional: "",
};

const AllRefrence = (props) => {
  const [refer, setRefer] = useState(props?.formData || initialValues);

  const onChangeValue = (e) => {
    const { name, value } = e.target;

    if (name === "referencePhoneNumber" || name === "phoneNumberProfessional") {
      const sanitizedValue = value.replace(/[^0-9]/g, "").slice(0, 10);
      setRefer({ ...refer, [name]: sanitizedValue });
    } else if (name === "refName" || name === "nameProfessional") {
      const sanitizedValue = value.replace(/[^A-Za-z]/gi, "");
      setRefer({ ...refer, [name]: sanitizedValue });
    } else {
      setRefer({ ...refer, [name]: value });
    }
  };

  const handleSubmit = (values) => {
    props.updateFormData("references", refer);
    props.setKey("Nominee", values);
  };

  const {
    refName,
    referencePhoneNumber,
    nameProfessional,
    phoneNumberProfessional,
  } = refer;

  return (
    <CCardBody>
      <CContainer>
        <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
          <div className="ref_personal pt-20">Personal References</div>
          <CRow>
            <CCol xs={6}>
              <CFormLabel htmlFor="refName">
                Enter Referencer Name
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="text"
                id="refName"
                placeholder="Enter Referencer Name"
                name="refName"
                value={refName}
                onChange={onChangeValue}
                required
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="referencePhoneNumber">
                Enter Referencer Contact Number
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="number"
                id="referencePhoneNumber"
                placeholder="Enter Referencer Contact Number"
                name="referencePhoneNumber"
                value={referencePhoneNumber}
                onChange={onChangeValue}
                required
              />
            </CCol>
          </CRow>

          <div className="ref_personal pt-20">Professional References</div>
          <CRow>
            <CCol xs={6}>
              <CFormLabel htmlFor="nameProfessional">
                Name (Professional)
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="text"
                id="nameProfessional"
                placeholder="Enter Referencer Name"
                name="nameProfessional"
                value={nameProfessional}
                onChange={onChangeValue}
                required
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="phoneNumberProfessional">
                Phone Number (Professional)
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="number"
                id="phoneNumberProfessional"
                placeholder="Enter Referencer Contact Number"
                name="phoneNumberProfessional"
                value={phoneNumberProfessional}
                onChange={onChangeValue}
                required
              />
            </CCol>
          </CRow>

          <div className="identification_btn">
            <CButton className="next_btn" type="submit">
              Next
            </CButton>
          </div>
        </CForm>
      </CContainer>
    </CCardBody>
  );
};
AllRefrence.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
  collectAllData: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
};

export default AllRefrence;
