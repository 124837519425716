import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  createDisbursementPayment,
  getAllEmiByCustomerId,
  setPaymentFalse,
  updateEmi,
} from "src/redux/actions/emiAction";

const {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CModalBody,
  CFormInput,
  CFormLabel,
  CRow,
  CCol,
  CFormSelect,
} = require("@coreui/react");
const { useState, useEffect } = require("react");
const { React } = require("react");
const PropTypes = require("prop-types");
const PaymentModel = ({
  visible,
  setVisible,
  customerData,
  emiData,
  dueData,
  loanId,
}) => {
  const dispatch = useDispatch();
  const { payment } = useSelector((state) => state?.staffReducer);
  const SchemaName = sessionStorage.getItem("schemaName");
  const [amount, setAmount] = useState();
  const [paymentMode, setPaymentMode] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const currentDate = new Date().toISOString().split("T")[0];
  const emisData = emiData?.data?.map((company, index) => {
    return company?.emiAmount;
  });
  const emiId = emiData?.data?.map((company, index) => {
    return company?.id;
  });

  useEffect(() => {
    setAmount(dueData?.emiAmount);
  }, []);

  const paymentAmountData = emisData.length > 0 ? emisData[0] : 0;
  const paymentAmountId = emiId.length > 0 ? emiId[0] : 0;

  const handleSubmit = () => {
    const data = {
      schemaName: SchemaName,
      status: "paid",
      receivedBy: 1,
      paymentDate: currentDate,
      paymentAmount: amount,
      customerId: customerData?.id,
      paymentMode: paymentMode,
      emiId: dueData?.id,
      loanId: loanId,
    };
    dispatch(createDisbursementPayment(data));
    setPaymentMode("");
    setEmployeeName("");
    const obj = {
      id: paymentAmountId,
      schemaName: SchemaName,
      customerId: customerData?.id,
      emiStatus: "paid",
    };
    dispatch(updateEmi(obj));
    const obje = {
      schemaName: SchemaName,
      customerId: customerData?.id,
    };

    dispatch(getAllEmiByCustomerId(obje));

    setVisible(false);
  };

  useEffect(() => {
    // if (payment === true) {

    dispatch(setPaymentFalse());
    // }
  }, []);

  return (
    <>
      <CModal
        scrollable
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel2"
      >
        <CModalHeader>
          <CModalTitle id="ScrollingLongContentExampleLabel2">
            {`${customerData?.firstName} ${customerData?.lastName}`}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs={10}>
              <CFormLabel htmlFor="currentDate">Payment Date</CFormLabel>
              <CFormInput type="date" value={currentDate} disabled />
            </CCol>
          </CRow>

          <CRow>
            <CCol xs={10}>
              <CFormLabel htmlFor="amount">Due Amount</CFormLabel>
              <CFormInput
                type="text"
                placeholder="enter Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol xs={10}>
              <CFormLabel htmlFor="paymentMode">Payment Mode</CFormLabel>
              <CFormSelect
                value={paymentMode}
                onChange={(e) => setPaymentMode(e.target.value)}
              >
                <option value="">Select Payment Mode</option>
                <option value="online">Online</option>
                <option value="cash">Cash</option>
                <option value="transfer">Transfer</option>
              </CFormSelect>
            </CCol>
          </CRow>
          {/* <CRow>
            <CCol xs={10}>
              <CFormLabel htmlFor="employee">Reciever Name</CFormLabel>
              <CFormInput
                type="text"
                placeholder="enter reciever name"
                value={employeeName}
                onChange={(e) => setEmployeeName(e.target.value)}
              />
            </CCol>
          </CRow> */}
        </CModalBody>
        <CModalFooter>
          <CButton onClick={handleSubmit} color="primary">
            Payment
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};
PaymentModel.propTypes = {
  visible: PropTypes.bool.isRequired,
  loanId: PropTypes.number.isRequired,
  setVisible: PropTypes.func.isRequired,
  customerData: PropTypes.object.isRequired,
  dueData: PropTypes.object.isRequired,
  emiData: PropTypes.array.isRequired,
};

export default PaymentModel;
