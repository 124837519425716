import React, { useRef, useState } from "react";
import { CButton, CCardBody, CCol, CContainer, CForm, CFormInput, CFormLabel, CRow } from "@coreui/react";
import * as Yup from "yup";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

const initialValues = {
  aadharNumber: "",
  PanNumber: "",
  singnatureImageUrl: null,
  panImageUrl: null,
  aadharImageUrl: null,
  paySlipImageUrl: null
};

const IdentiFicationForm = (props) => {
  const {isUpdateForm}= props
  const { adharNumber } = useSelector((state) => state?.companyReducer);

  console.log(adharNumber,"adharNumberadharNumber")
  const panRef = useRef();
  const aadharRef = useRef();
  const singnatureRef = useRef();
  const photoRef = useRef();
  const paySlipImageUrlRef = useRef();
  const [identification, setIdentification] = useState(props?.formData || initialValues)

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setIdentification({ ...identification, [name]: value });
  };


  const validationSchema = Yup.object().shape({
    aadharCard: Yup.string()
      .typeError("please select a file")
      .required("aadharNumber is required"),
    aadharNumber: Yup.number().required("AadharCard is required"),
    PanNumber: Yup.number().required("PanCard is required"),
    panCard: Yup.string()
      .typeError("please select a file")
      .required("panCard is required"),
    panImageUrl: Yup.mixed()
      .required('Image is required')
      .test(
        'fileSize',
        'File size is too large',
        (value) => value && value.size <= 1024000 // Adjust the size limit as needed (in bytes)
      )
      .test(
        'fileType',
        'Unsupported file format',
        (value) => value && ['image/jpeg', 'image/png'].includes(value.type)
      ),
  });

  const handleSubmit = (values) => {

    const obj = {
      aadharNumber: adharNumber,
      PanNumber: PanNumber,
      singnatureImageUrl: singnatureRef?.current?.files[0],
      panImageUrl: panRef?.current?.files[0],
      aadharImageUrl: aadharRef?.current?.files[0],
      passportSizePhoto: photoRef?.current?.files[0],
      paySlipImageUrl: paySlipImageUrlRef?.current?.files[0]
    }
    props.updateFormData("identiFication", obj);
    props.setKey("Location", values);
  };

  const {
    aadharNumber,
    PanNumber,
  } = identification

  return (
    <CCardBody>
      <CContainer>
        <CForm
          onSubmit={(event) => handleSubmit(event)}
          autoComplete="off"
        >
          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="aadharNumber">
                Aadhar Number
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                id="aadharNumber"
                type="number"
                placeholder="Aadhar Card Number"
                value={adharNumber || props?.customerData?.aadharNumber}               
                disabled
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="PanNumber">PAN Number</CFormLabel>
              <span className="red-star">{` `}*</span>
              <CFormInput
                id="PanNumber"
                type="text"
                placeholder="PAN Number"
                name="PanNumber"
                value={PanNumber}
                onChange={onChangeValue}
                required
              />
            </CCol>
          </CRow>

          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="aadharImageUrl">
                Upload Aadhar Card
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={aadharRef}
                id="aadharImageUrl"
                placeholder="Upload Aadhar Card"
                onChange={onChangeValue}
                required={!isUpdateForm}
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="panImageUrl">
                Upload PAN Card{" "}
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={panRef}
                id="panImageUrl"
                placeholder="Upload PAN Card "
                onChange={onChangeValue}
                required={!isUpdateForm}
              />
            </CCol>
          </CRow>

          <CRow className="pt-20">

            <CCol xs={6}>
              <CFormLabel htmlFor="passportSizePhoto">
                Upload Passport Size Photo {" "}
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={photoRef}
                id="passportSizePhoto"
                placeholder="Upload Passport Size Photo"
                onChange={onChangeValue}
                required={!isUpdateForm}
              />
            </CCol>
            <CCol xs={6}>
              <CFormLabel htmlFor="singnatureImageUrl">
                Upload Signature
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={singnatureRef}
                id="singnatureImageUrl"
                placeholder="Upload Singnature"
                onChange={onChangeValue}
                required={!isUpdateForm}
              />
            </CCol>
          </CRow>

          <CRow className="pt-20">
            <CCol xs={6}>
              <CFormLabel htmlFor="paySlipImageUrl">
                Upload Pay Slip
                <span className="red-star">{` `}*</span>
              </CFormLabel>
              <CFormInput
                type="file"
                ref={paySlipImageUrlRef}
                id="paySlipImageUrl"
                placeholder="Upload Pay Slip"
                onChange={onChangeValue}
                required={!isUpdateForm}
              />
            </CCol>
          </CRow>

          <div className="identification_btn">
            <CButton type="submit" className='next_btn'>Next </CButton>
          </div>
        </CForm>
      </CContainer>
    </CCardBody>
  );
};
IdentiFicationForm.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
  isUpdateForm: PropTypes.bool,
  customerData: PropTypes.object,
};
export default IdentiFicationForm;
