import React from 'react';
import { useReactToPrint } from 'react-to-print';
import Vakilkaro from 'src/assets/brand/vakilkaro';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import { getS3Url } from 'src/services/halper';

const ViewCustomerProfileInfo = (props) => {

  const IncomingData = useLocation();
  const customerData = IncomingData?.state?.customerData || {};
  const add = JSON.parse(customerData.address);
  const { branch } = customerData;

  const panUrl = getS3Url(customerData.panImageUrl);
  const aadhaarUrl = getS3Url(customerData.aadharImageUrl);
  const bankstmtImg = getS3Url(customerData.bankStatementsImageUrl);

  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  return (
    <div className="form-wrapper content">
      <div className="container">
        <div className="row">
          <div className="col-12 text-end pdfbtn">
            <button className='btn btn-primary ' onClick={handlePrint}>Generate PDF</button>
          </div>
        </div>
      </div>
      <div className='statement-form' ref={componentRef} id='my-print'>
        <div className='statement-header'>
          <div className="stmt-logo">
            <Vakilkaro />
          </div>
          <div>
            <h3>Statement of Account</h3>
          </div>
        </div>
        <hr></hr>
        <div className='maxima'>
          <div className='fifty'>
            <div className='stmt-info'>
              <span className='info-head'><p>Loan Reference Number</p></span>
              <div className='info-input'><>:</><span className='input-span'>{ }</span></div>
            </div>
            <div className='stmt-info'>
              <span className='info-head'><p>Customer name</p></span>
              <div className='info-input'><>:</><span className='input-span'>{customerData.firstName}{" "}{customerData.middleName}{" "}{customerData.firstName}</span></div>
            </div>
          </div>
        </div>
        <div className='stmt-headbar'>
          <div className='stmt-headbar1'>
            <div className='info-head'><p>Customer Level Details</p></div>
            <div className='info-head'><p>Loan Details</p></div>
          </div>
          <div className='stmt-headbar2'><p>All Amounts in INR</p></div>
        </div>
        <div className='maximacopy'>
          <div className='fiftycopyauto'>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>First Name</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.firstName}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>Middle Name</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.middleName !== 'undefined' ? customerData.middleName : ''}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>Last Name</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.lastName}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>Gender</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.gender}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>{"Father's Name"}</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.fatherName}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>Mobile Number</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.phoneNumber}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>Email Address</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.email}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>D.O.B.</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.dob}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>Marital Status</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.martialStatus}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>Nominee</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.nominee}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>Occupation</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.occupation}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>Income</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.income}</span></div>
            </div>
            <div className='stmt-infocopy' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span className='info-headcopy'><p>Pay Slip</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>
                <img src={bankstmtImg} alt="paySlipUrl Image" width={'100px'} /></span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>Monthly Expenses</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.monthlyExpenses}</span></div>
            </div>
            <div className='stmt-infocopy'>
              <span className='info-headcopy'><p>Property and Vehicle</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.propertyAndVehicle}</span></div>
            </div>
            <div className='stmt-infocopy' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span className='info-headcopy'><p>Signature Image</p></span>
              <div className='info-inputcopy'><>:</><span className='input-span'>
                <img src={bankstmtImg} alt="signatureUrl" width={'100px'} /></span></div>
            </div>
            <div className='stmt-headbarhalf' style={{ marginTop: '20px' }}>
              <div className='stmt-headbar1'>
                <div className='info-head'><p>Property Details</p></div>
              </div>
              <div className='stmt-headbar2'></div>
            </div>
            <div className='maxima50'>
              <div className='fiftycopyhalf'>
                <div className='stmt-infocopy'>
                  <span className='info-headcopy info-headcopy-in'><p>Area</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>{add.area}</span></div>
                </div>
                <div className='stmt-infocopy'>
                  <span className='info-headcopy info-headcopy-in'><p>District</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>{add.district}</span></div>
                </div>
                <div className='stmt-infocopy'>
                  <span className='info-headcopy info-headcopy-in'><p>City</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>{add.city}</span></div>
                </div>
                <div className='stmt-infocopy'>
                  <span className='info-headcopy info-headcopy-in'><p>State</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>{add.state}</span></div>
                </div>
                <div className='stmt-infocopy'>
                  <span className='info-headcopy info-headcopy-in'><p>Country</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>{add.country}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div className='fiftycopyauto'>
            <div className='fiftycopyauto-box'>
              <div className='stmt-infocopy'>
                <span className='info-headcopy'><p>Loan Account Number </p></span>
                <div className='info-inputcopy'><span>:</span><span className='input-span'>{customerData.loanAccountNumber}</span></div>
              </div>

              <div className='stmt-infocopy'>
                <span className='info-headcopy'><p>Loan Amount Requested</p></span>
                <div className='info-inputcopy'><span>:</span><span className='input-span'>{customerData.loanAmountRequested}</span></div>
              </div>
              <div className='stmt-infocopy'>
                <span className='info-headcopy'><p>Credit Score</p></span>
                <div className='info-inputcopy'><span>:</span><span className='input-span'>{customerData.creditScore}</span></div>
              </div>
              <div className='stmt-infocopy'>
                <span className='info-headcopy'><p>Loan Approval Status</p></span>
                <div className='info-inputcopy'><span>:</span><span className='input-span'>{customerData.loanApprovalStatus}</span></div>
              </div>
              <div className='stmt-infocopy'>
                <span className='info-headcopy'><p>Approval Amount</p></span>
                <div className='info-inputcopy'><span>:</span><span className='input-span'>{customerData.approvalAmount}</span></div>
              </div>
              <div className='stmt-infocopy'>
                <span className='info-headcopy'><p>Sanctioned Loan Amt</p></span>
                <div className='info-inputcopy'><span>:</span><span className='input-span'>{""}</span></div>
              </div>
              <div className='stmt-infocopy'>
                <span className='info-headcopy'><p>Loan Term</p></span>
                <div className='info-inputcopy'><span>:</span><span className='input-span'>{customerData.loanTerm}</span></div>
              </div>
              <div className='stmt-infocopy'>
                <span className='info-headcopy'><p>Disbursed Amount</p></span>
                <div className='info-inputcopy'><>:</><span className='input-span'>{""}</span></div>
              </div>
              <div className='stmt-infocopy'>
                <span className='info-headcopy'><p>Tenure{"(Months)"}</p></span>
                <div className='info-inputcopy'><>:</><span className='input-span'>{customerData.loanTerm}</span></div>
              </div>
              <div className='stmt-infocopy'>
                <span className='info-headcopy'><p>End Installment Date</p></span>
                <div className='info-inputcopy'><>:</><span className='input-span'>{""}</span></div>
              </div>
            </div>
            <div className='stmt-headbarhalf' style={{ marginTop: '20px' }}>
              <div className='stmt-headbar1'>
                <div className='info-head'><p>Bank Details</p></div>
              </div>
              <div className='stmt-headbar2'></div>
            </div>
            <div className='maxima50'>
              <div className='fiftycopyhalf'>
                <div className='stmt-infocopy'>
                  <span className='info-headcopy info-headcopy-in'><p>Bank Account Number</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>{customerData?.bankAccountNo}</span></div>
                </div>
                <div className='stmt-infocopy'>
                  <span className='info-headcopy info-headcopy-in'><p>IFSC Code</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>{customerData?.ifscCode}</span></div>
                </div>
                <div className='stmt-infocopy'>
                  <span className='info-headcopy info-headcopy-in'><p>Bank Name</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>{customerData?.bankName}</span></div>
                </div>
                <div className='stmt-infocopy' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <span className='info-headcopy info-headcopy-in'><p>Bank Statement Image</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>
                    <img src={bankstmtImg} alt="statement Image" width={'150px'} />
                  </span></div>
                </div>
                <div className='stmt-infocopy'>
                  <span className='info-headcopy info-headcopy-in'><p> Branch Name</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>{branch?.branchName}</span></div>
                </div>
                <div className='stmt-infocopy'>
                  <span className='info-headcopy info-headcopy-in'><p>Branch Code</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>{branch?.branchCode}</span></div>
                </div>
                <div className='stmt-infocopy'>
                  <span className='info-headcopy info-headcopy-in'><p>Contact Number</p></span>
                  <div className='info-inputcopy'><>:</><span className='input-span'>{branch?.contactNumber}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='stmt-headbar-iii global-margin-top'>
          <div className='stmt-headbar1-iii'>
            <div className='info-head'><p>Customer PAN Details</p></div>
            <div className='info-head'><p>Customer Adhaar Details</p></div>
          </div>

          <div className='maxima'>
            <div className='fifty'>
              <div className='stmt-info'>
                <span className='info-head'><p>PAN Number</p></span>
                <div className='info-input'><>:</><span className='input-span'>{customerData?.PanNumber}</span></div>
              </div>
              <div className='stmt-info' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span className='info-head'><p>PAN Image</p></span>
                <div className='info-input'><>:</><span className='input-span'>
                  <img
                    src={panUrl}
                    alt="PAN Image"
                    width={'150px'} />
                </span></div>
              </div>

            </div>

            <div className='fifty'>

              <div className='stmt-info' >
                <span className='info-head'><p>Aadhaar Number</p></span>
                <div className='info-input'><>:</><span className='input-span'>{customerData?.aadharNumber}</span></div>
              </div>

              <div className='stmt-info' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span className='info-head'><p>Aadhaar Image</p></span>
                <div className='info-input'><>:</><span className='input-span'>
                  <img src={aadhaarUrl} alt="aadhar Image" width={'150px'} />
                </span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ViewCustomerProfileInfo.propTypes = {
  customerData: PropTypes.object.isRequired
};

export default ViewCustomerProfileInfo;
