import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUpload } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "src/components/Tooltip";
import { useSelector } from "react-redux";
import ReactPaginate from "react-js-pagination";
import { Button } from "react-bootstrap";
import AddForm from "../../customer/addForm";
import CustomerAllDetails from "../../customer/customerAllDetails";
import { getAllCustomerSanctionLetter, getAllCustomers } from "src/api/allApis";
import SantialLetter from "src/pages/customer/santialLetter";
import PrintSantionLetterForm from "src/pages/customer/santialLetter/printSanctionLetterForm";

const Approved = () => {
  const { routeValue } = useSelector((state) => state?.planReducer);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customersPendingStatus, setCustomersPendingStatus] = useState([]);
  const SchemaName = sessionStorage.getItem("schemaName");
  const [showSantialLetter, setShowSantialLetter] = useState(false);
  const [printSantialLetter, setPrintSantialLetter] = useState(false);
  const [cuData, setCuData] = useState();
  const [approvedData, setApprovedData] = useState([]);
  const [sanctionLetter, setSanctionLetter] = useState([]);
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    getAllCompanyData();
    getAllCustomer();
  }, []);

  useEffect(() => {
    const loanApprovalStatus = customersPendingStatus.filter(
      (l) => l.loanApprovalStatus === "approved"
    );
    setApprovedData(loanApprovalStatus);
  }, [customersPendingStatus]);

  const getAllCompanyData = () => {
    const payload = { schemaName: SchemaName };
    getAllCustomers(payload).then((res) => {
      setCustomersPendingStatus(res?.data);
    });
  };

  const handleShowSantialLetter = (customerData) => {
    setCuData(customerData);
    setShowSantialLetter(true);
  };
  const handlePrintSantialLetter = (customerData) => {
    setCuData(customerData);
    setPrintSantialLetter(true);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  // const currentRows = sanctionLetter?.slice(indexOfFirstRow, indexOfLastRow);
  const currentRows = approvedData?.slice(indexOfFirstRow, indexOfLastRow);

  const handleShowAddForm = () => {
    // When the user clicks the "Add" button, hide the button and show the form
    setShowAddButton(false);
    setShowSelectAndSearch(false);
    setShowAddForm(true);
  };

  const handleViewdetails = (company) => {
    setShowSelectAndSearch(false);
    setSelectedCompany(company);
    setShowAccordion(true);
  };

  const roletype = sessionStorage.getItem("role");

  const getAllCustomer = () => {
    // const payload = { schemaName: SchemaName };
    // getAllCustomerSanctionLetter(payload)
    //   .then(res => {
    //     // setSanctionLetter(res?.data.filter(l => l.isUploaded === null || l.isUploaded === false))
    //   }
    //   )
  };

  return (
    <>
      {printSantialLetter ? (
        <PrintSantionLetterForm
          cuData={cuData}
          setPrintSantialLetter={setPrintSantialLetter}

        />
      ) : (<CRow className="p-1">
        <CCol xs={12}>
          <CCard className="mb-4">
            {/* <div className="btn_add">
              {roletype !== "customer" && showAddButton && (
                <Button className="add-btn" onClick={handleShowAddForm}>
                  Add
                </Button>
              )}
            </div> */}

            <div className="search_bar">
              {showSelectAndSearch && (
                <div>
                  <CFormSelect
                    size="sm"
                    className="mb-3"
                    style={{ cursor: "pointer" }}
                    aria-label="Small select example"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </CFormSelect>
                </div>
              )}
              <div style={{ display: "flex" }}>
                {showSelectAndSearch && (
                  <>
                    <CFormInput
                      type="text"
                      size="sm"
                      placeholder="Search"
                      aria-label="sm input example"
                      style={{ margin: "0px 0px 16px 0px" }}
                    // onChange={handleSearchChange}
                    // value={searchInput}
                    />

                    <span
                      style={{
                        border: " 1px solid #d1c5c5",
                        borderRadius: "4px",
                        margin: "0px 5px 16px -1px",
                        padding: "1px 8px 0px 8px",
                        background: "#ebedef",
                        cursor: "pointer",
                      }}
                    >
                      <i className="fa fa-search"></i>
                    </span>
                  </>
                )}
              </div>
            </div>

            <CCardBody>
              <div
                className={`table-container ${showAccordion ? "hidden" : ""} ${showAddForm ? "hidden" : ""
                  }`}
              >
                <CTable color="dark" hover className="" responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col" className="col-md-1">
                        S. No.
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Name
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Address
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Email Id
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Mobile Number
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        Print Sanction Letter
                      </CTableHeaderCell>
                      {role === "employee" ? (
                        ""
                      ) : (
                        <CTableHeaderCell scope="col" className="col-md-3">
                          Action
                        </CTableHeaderCell>
                      )}
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {/* {sanctionLetter && sanctionLetter.length > 0 ? ( */}
                    {approvedData && approvedData.length > 0 ? (
                      currentRows?.map((customerData, index) => {

                        return (
                          <CTableRow key={index}>
                            <CTableHeaderCell
                              scope="row"
                              style={{ paddingLeft: "15px" }}
                            >
                              {indexOfFirstRow + index + 1}
                            </CTableHeaderCell>
                            <CTableDataCell>
                              {customerData?.firstName || "--"}
                              {/* {customerData?.customer.firstName || "--"} */}
                            </CTableDataCell>
                            <CTableDataCell>
                              {customerData?.address || "--"}
                              {/* {customerData?.customer.address || "--"} */}
                            </CTableDataCell>
                            <CTableDataCell>
                              {customerData?.email || "--"}
                              {/* {customerData?.customer.email || "--"} */}
                            </CTableDataCell>
                            <CTableDataCell>
                              {customerData?.phoneNumber || "--"}
                            </CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                onClick={() =>
                                  handlePrintSantialLetter(
                                    customerData
                                  )
                                }
                              >
                                print Letter
                              </CButton>
                            </CTableDataCell>

                            {role === "employee" ? (
                              ""
                            ) : (
                              <CTableDataCell
                                style={{ padding: "10px 0px 10px 0px" }}
                                className="col-md-3 "
                              >
                                <>
                                  <Tooltip text="upload">
                                    <FontAwesomeIcon
                                      role="button"
                                      icon={faUpload}
                                      size="1x"
                                      className=""
                                      onClick={() =>
                                        handleShowSantialLetter(
                                          customerData?.customer
                                        )
                                      }
                                      style={{ marginRight: 15 }}
                                    />
                                  </Tooltip>
                                  <Tooltip text="View">
                                    <FontAwesomeIcon
                                      role="button"
                                      icon={faEye}
                                      size="1x"
                                      className="viewIcon"
                                      onClick={() =>
                                        handleViewdetails(
                                          customerData?.customer
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </>
                              </CTableDataCell>
                            )}
                          </CTableRow>
                        );
                      })
                    ) : (
                      // Display a loading message or handle the case where data is not available
                      <div className="loading_data">Loading...</div>
                    )}
                  </CTableBody>
                </CTable>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontSize: "16px", fontWeight: "600" }}>
                    Showing {indexOfFirstRow + 1} to{" "}
                    {/* {Math?.min(indexOfLastRow, sanctionLetter?.length)} of{" "}
                          {sanctionLetter?.length} entries */}
                    {Math?.min(indexOfLastRow, approvedData?.length)} of{" "}
                    {approvedData?.length} entries
                  </div>
                  <div className="pagination-container">
                    <ReactPaginate
                      activePage={currentPage}
                      itemsCountPerPage={rowsPerPage}
                      // totalItemsCount={sanctionLetter?.length}
                      totalItemsCount={approvedData?.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      prevPageText="Previous"
                      nextPageText="Next"
                      firstPageText="First"
                      lastPageText="Last"
                      innerClass="pagination"
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              </div>
              {showAccordion && (
                <CustomerAllDetails
                  selectedCompany={selectedCompany}
                  setShowAccordion={setShowAccordion}
                  setShowSelectAndSearch={setShowSelectAndSearch}
                />
              )}
              {showAddForm && (
                <AddForm
                  selectedCompany={selectedCompany}
                  setShowAddForm={setShowAddForm}
                  setShowAddButton={setShowAddButton}
                  setShowSelectAndSearch={setShowSelectAndSearch}
                />
              )}
              {showSantialLetter && (
                <SantialLetter
                  cuData={cuData}
                  setShowSantialLetter={setShowSantialLetter}
                  showSantialLetter={showSantialLetter}
                />
              )}

            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          <div
            // className={`accordion-container ${showAccordion ? "visible" : ""}`}
            // className={`accordion-container ${showAddForm ? "visible" : ""}`}
            className={`accordion-container ${showAccordion ? "visible" : ""} ${showAddForm ? "visible" : ""
              }`}
          ></div>
        </CCol>
      </CRow>)}

    </>
  );
};

export default Approved;
