import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import Tooltip from "src/components/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompany } from "src/redux/actions/companyAction";
import CompanyAllDetail from "./companyAllDetails";
import ReactPaginate from "react-js-pagination";
import DeleteModal from "src/components/commonComponents/commonDeleteModal";
import CompanyAddAndUpdateForm from "src/components/Modal/editAndAddForm";
import avatarImage from "../../assets/images/avatars/1.jpg";
import AddAaddharTokenLimit from "./updateAaddharTokenLimit";

const Users = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state?.companyReducer);
  const { update } = useSelector((state) => state?.companyReducer);
  const { companyReducer } = useSelector((state) => state);
  const [model, setModel] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editLoanData, setEditLoanData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAccordion, setShowAccordion] = useState(false);
  const [updateForm, setUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [companyModel, setCompanyModel] = useState(false);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [visibleUser, setVisibleUser] = useState(false);
  const [visibleAddAadharToken, setVisibleAddAadharToken] = useState(false);
  const [activeCompanyId, setActiveCompanyId] = useState(0);
  const handleShow = () => setShowModal(true);

  const handleDelete = () => {
    setSelectedItemId(null);
    dispatch(getAllCompany());
  };

  useEffect(() => {
    dispatch(getAllCompany());
  }, []);

  useEffect(() => {
    if (companyReducer?.status === "success") {
      dispatch(getAllCompany());
    }
  }, [companyReducer]);

  useEffect(() => {
    // if (companyReducer?.status === "success") {
    dispatch(getAllCompany());
    // }
  }, [update]);

  const handleSearchChange = (e) => {
    // Step 2: Update the searchInput state as the user types
    setSearchInput(e.target.value);
  };

  const handleDeleteClick = (itemId, itemSchemaName) => {
    setSelectedItemId({ itemId, itemSchemaName });
    setCompanyModel(true);
    handleShow();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data?.data?.slice(indexOfFirstRow, indexOfLastRow);
  const handleEditLoan = (loanData) => {
    setUpdate(true);
    setEditLoanData(loanData);
    setModel("update");
  };

  const handleViewDetails = (company) => {
    setShowSelectAndSearch(false);
    setSelectedCompany(company);
    setShowAccordion(true);
  };

  const handleshowAddForm = () => {
    // navigate("/users/addform")
    setVisibleUser(true);
    setModel("add");
  };

  return (
    <CRow className="content">
      <CCol xs={12}>
        <CCard className="mb-4">
          <div className="search_bar">
            {showSelectAndSearch && (
              <div>
                <CFormSelect
                  size="sm"
                  className="mb-3"
                  style={{ cursor: "pointer" }}
                  aria-label="Small select example"
                  onChange={(e) => setRowsPerPage(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </CFormSelect>
              </div>
            )}
            <div style={{ display: "flex" }}>
              {showSelectAndSearch && (
                <>
                  <CFormInput
                    type="text"
                    size="sm"
                    placeholder="Search"
                    aria-label="sm input example"
                    style={{ margin: "0px 0px 16px 0px" }}
                    onChange={handleSearchChange}
                    value={searchInput}
                  />

                  <span
                    style={{
                      border: " 1px solid #d1c5c5",
                      borderRadius: "4px",
                      margin: "0px 5px 16px -1px",
                      padding: "1px 8px 0px 8px",
                      background: "#ebedef",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </span>
                </>
              )}
            </div>
            <div className="btn_add mt-0 mb-14">
              {showSelectAndSearch && (
                // <div className="btn_add">
                <CButton
                  className="add-btn w-auto plr-30 mr-10"
                  onClick={handleshowAddForm}
                >
                  Add
                </CButton>
                // </div>
              )}
            </div>
          </div>

          <CCardBody>
            <div className={`table-container ${showAccordion ? "hidden" : ""}`}>
              <CTable color="dark" hover className="" responsive>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">
                      <span className="header_tab">Username</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <span className="header_tab">Email Address</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <span className="header_tab">Role</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <span className="header_tab">Birth Date</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <span className="header_tab">Contact</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <span className="header_tab">Aadhaar Token Limit</span>
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <span className="header_tab">Action</span>
                    </CTableHeaderCell>

                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {data?.data && data?.data.length > 0 ? (
                    currentRows
                      ?.filter((company) =>
                        company.companyName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      )
                      .map((company, index) => (
                        <CTableRow className="" key={index}>
                          <CTableDataCell>
                            <div className="image_name">
                              <img
                                className="img_tag"
                                src={avatarImage}
                                alt="Avatar"
                              />

                              <span className="spn">
                                {company?.companyName || "--"}
                              </span>
                            </div>
                          </CTableDataCell>
                          <CTableDataCell>
                            <span className="spn_data">
                              {company?.email || "--"}
                            </span>
                          </CTableDataCell>
                          <CTableDataCell>
                            <span className="spn_data">
                              {company?.userName || "--"}
                            </span>
                          </CTableDataCell>
                          <CTableDataCell>
                            <span className="spn_data">
                              {company?.birthDate || "May 02,1990"}
                            </span>
                          </CTableDataCell>
                          <CTableDataCell>
                            <span className="spn_data">
                              {company?.contact || "9795464124"}
                            </span>
                          </CTableDataCell>
                          <CTableDataCell className="col-md-1"
                            onClick={() => {
                              setVisibleAddAadharToken(true);
                              setActiveCompanyId(company?.id);
                              // company?.id
                            }}
                          >
                            <span className="spn_data cursor-pointer">
                              {company?.aaddhar_token_limit || "0"}
                              <Tooltip text="Add Aadhaar Token Update">
                                <FontAwesomeIcon
                                  role="button"
                                  icon={faPenToSquare}
                                  size="1x"
                                  className="px-3 updateIcon"
                                />
                              </Tooltip>
                            </span>
                          </CTableDataCell>
                          <CTableDataCell className="col-md-3">
                            <>
                              <span className="spn_data">
                                <span className="view_spn">
                                  <Tooltip text="View">
                                    <FontAwesomeIcon
                                      role="button"
                                      icon={faEye}
                                      size="1x"
                                      className="viewIcon"
                                      onClick={() => handleViewDetails(company)}
                                    />
                                  </Tooltip>
                                </span>
                                <span className="edit_spn">
                                  <Tooltip text="Update">
                                    <FontAwesomeIcon
                                      role="button"
                                      icon={faPenToSquare}
                                      size="1x"
                                      onClick={() => handleEditLoan(company)}
                                      className="px-3 updateIcon"
                                    />
                                  </Tooltip>
                                </span>
                                <span className="delete_spn">
                                  <Tooltip text="Delete">
                                    <FontAwesomeIcon
                                      onClick={() =>
                                        handleDeleteClick(
                                          company?.id,
                                          company?.schemaName
                                        )
                                      }
                                      role="button"
                                      icon={faTrash}
                                      size="1x"
                                      className="deleteIcon"
                                    />
                                    {showModal ? (
                                      <DeleteModal
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                        onDelete={handleDelete}
                                        selectedItemId={selectedItemId}
                                        companyModel={companyModel}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Tooltip>
                                </span>
                              </span>
                            </>
                          </CTableDataCell>
                        </CTableRow>
                      ))
                  ) : (
                    // Display a loading message or handle the case where data is not available
                    <div className="loading_data">Loading...</div>
                  )}
                </CTableBody>
              </CTable>
              {model === "update" ? (
                <CompanyAddAndUpdateForm
                  setVisible={setModel}
                  visible={model}
                  loanData={editLoanData}
                  updateForm={updateForm}
                />
              ) : (
                ""
              )}
              {model === "add" ? (
                <CompanyAddAndUpdateForm
                  setVisible={setModel}
                  visible={model}
                />
              ) : (
                ""
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Showing {indexOfFirstRow + 1} to{" "}
                  {Math?.min(indexOfLastRow, data?.data?.length)} of{" "}
                  {data?.data?.length} entries
                </div>
                <div className="pagination-container">
                  <ReactPaginate
                    activePage={currentPage}
                    itemsCountPerPage={rowsPerPage}
                    totalItemsCount={data?.data?.length}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText="Previous"
                    nextPageText="Next"
                    firstPageText="First"
                    lastPageText="Last"
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            </div>
            {showAccordion && (
              <CompanyAllDetail
                selectedCompany={selectedCompany}
                setShowAccordion={setShowAccordion}
                setShowSelectAndSearch={setShowSelectAndSearch}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs={12}>
        <div
          className={`accordion-container ${showAccordion ? "visible" : ""}`}
        ></div>
      </CCol>
      {visibleAddAadharToken && <AddAaddharTokenLimit
        visibleAddAadharToken={visibleAddAadharToken}
        setVisibleAddAadharToken={setVisibleAddAadharToken}
        activeCompanyId={activeCompanyId}
        setActiveCompanyId={setActiveCompanyId}
      />}
    </CRow>
  );
};

export default Users;
