import React, { useEffect, useState } from "react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { CChartLine } from "@coreui/react-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils";
import CIcon from "@coreui/icons-react";
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilUser,
  cilUserFemale,
} from "@coreui/icons";

import avatar1 from "src/assets/images/avatars/1.jpg";
import avatar2 from "src/assets/images/avatars/2.jpg";
import avatar3 from "src/assets/images/avatars/3.jpg";
import avatar4 from "src/assets/images/avatars/4.jpg";
import avatar5 from "src/assets/images/avatars/5.jpg";
import avatar6 from "src/assets/images/avatars/6.jpg";
import WidgetsBrand from "../../views/widgets/WidgetsBrand";
import WidgetsDropdown from "../../views/widgets/WidgetsDropdown";
import { getAllCustomers, getCollections, getCompanyDashboard } from "src/api/allApis";

const Dashboard = () => {
  const random = (min, max) =>
    Math?.floor(Math?.random() * (max - min + 1) + min);

  const [aaddharUsageCount, setaaddharUsageCount] = useState(0);
  const [aaddharTokenBalance, setaaddharTokenBalance] = useState(0);
  const [metaData, setMetaData] = useState({});
  const [customerData, setCustomerData] = useState([]);
  const [emiData, setEmiData] = useState([]);
  const schema = sessionStorage.getItem("schemaName");

  useEffect(() => {
    const companyId= sessionStorage.getItem("companyId")
    fetchCustomers();
    totalDisbursementAmount();
    totalApprovedLoans();
    fetchEMIs();
    getCompanyDashboard({companyId:companyId}).then((data)=>{
      console.log("data", data);
      setaaddharUsageCount(data?.data?.aaddharUsageCount);
      setaaddharTokenBalance(data?.data?.aaddhar_token_limit)
    })
  }, []);

  const fetchCustomers = () => {
    getAllCustomers({ schemaName: schema })
      .then((users) => setCustomerData(users?.data))
      .catch((error) => console.log("err"));
  };

  const fetchEMIs = () => {
    getCollections({ schemaName: schema })
      .then((users) => setEmiData(users?.data))
      .catch((error) => console.log("err"));
  };

  const totalDisbursementAmount = () => {
    let subTotal = 0;
    customerData?.map((item) =>
      item?.disbursements?.map(
        (amt) => (subTotal += Number(amt?.DisbursementAmount))
      )
    );

    return subTotal;
  };
  const totalApprovedLoans = () => {
    let subTotal = 0;
    customerData?.map(
      (item) => (subTotal += Number(item?.loanApprovalStatus === "approved"))
    );

    return subTotal;
  };
  const totalPendingLoans = () => {
    let subTotal = 0;
    customerData?.map(
      (item) => (subTotal += Number(item?.loanApprovalStatus === "pending"))
    );

    return subTotal;
  };
  const totalRejectedLoans = () => {
    let subTotal = 0;
    customerData?.map(
      (item) => (subTotal += Number(item?.loanApprovalStatus === "rejected"))
    );

    return subTotal;
  };
  const totalPendingEMIs = () => {
    let subTotal = 0;
    emiData?.map((item) => (subTotal += Number(item?.emiStatus === "pending")));

    return subTotal;
  };

  const totalPaidEMIs = () => {
    let subTotal = 0;
    emiData?.map((item) => (subTotal += Number(item?.emiStatus === "paid")));

    return subTotal;
  };

  const progressExample = [
    { title: "Total applications", value: `${customerData?.length}`, percent: 10, color: "success" },
    { title: "Pending Loans", value: totalPendingLoans(), percent: 20, color: "info" },
    { title: "Approved Loans", value: totalApprovedLoans(), percent: 60, color: "warning", },
    { title: "Rejected Loans", value: totalRejectedLoans(), percent: 80, color: "danger" },

  ];

  const progressGroupExample1 = [
    { title: "New Clients", value1: 34, value2: 78 },
    { title: "Due EMI Collection", value1: totalPendingEMIs(), value2: totalPendingEMIs() },
    { title: "Today's EMI Collection", value1: totalPaidEMIs(), value2: totalPaidEMIs() },
    { title: "Paid EMI", value1: totalPaidEMIs(), value2: totalPaidEMIs() },
  
  ];

  const progressGroupExample2 = [
    { title: "Male", icon: cilUser, value: 53 },
    { title: "Female", icon: cilUserFemale, value: 43 },
  ];

  const progressGroupExample3 = [
    { title: "Organic Search", icon: cibGoogle, percent: 56, value: "191,235" },
    { title: "Facebook", icon: cibFacebook, percent: 15, value: "51,223" },
    { title: "Twitter", icon: cibTwitter, percent: 11, value: "37,564" },
    { title: "LinkedIn", icon: cibLinkedin, percent: 8, value: "27,319" },
  ];

  const tableExample = [
    {
      avatar: { src: avatar1, status: "success" },
      user: {
        name: "Yiorgos Avraamu",
        new: true,
        registered: "Jan 1, 2021",
      },
      country: { name: "USA", flag: cifUs },
      usage: {
        value: 50,
        period: "Jun 11, 2021 - Jul 10, 2021",
        color: "success",
      },
      payment: { name: "Mastercard", icon: cibCcMastercard },
      activity: "10 sec ago",
    },
    {
      avatar: { src: avatar2, status: "danger" },
      user: {
        name: "Avram Tarasios",
        new: false,
        registered: "Jan 1, 2021",
      },
      country: { name: "Brazil", flag: cifBr },
      usage: {
        value: 22,
        period: "Jun 11, 2021 - Jul 10, 2021",
        color: "info",
      },
      payment: { name: "Visa", icon: cibCcVisa },
      activity: "5 minutes ago",
    },
    {
      avatar: { src: avatar3, status: "warning" },
      user: { name: "Quintin Ed", new: true, registered: "Jan 1, 2021" },
      country: { name: "India", flag: cifIn },
      usage: {
        value: 74,
        period: "Jun 11, 2021 - Jul 10, 2021",
        color: "warning",
      },
      payment: { name: "Stripe", icon: cibCcStripe },
      activity: "1 hour ago",
    },
    {
      avatar: { src: avatar4, status: "secondary" },
      user: { name: "Enéas Kwadwo", new: true, registered: "Jan 1, 2021" },
      country: { name: "France", flag: cifFr },
      usage: {
        value: 98,
        period: "Jun 11, 2021 - Jul 10, 2021",
        color: "danger",
      },
      payment: { name: "PayPal", icon: cibCcPaypal },
      activity: "Last month",
    },
    {
      avatar: { src: avatar5, status: "success" },
      user: {
        name: "Agapetus Tadeáš",
        new: true,
        registered: "Jan 1, 2021",
      },
      country: { name: "Spain", flag: cifEs },
      usage: {
        value: 22,
        period: "Jun 11, 2021 - Jul 10, 2021",
        color: "primary",
      },
      payment: { name: "Google Wallet", icon: cibCcApplePay },
      activity: "Last week",
    },
    {
      avatar: { src: avatar6, status: "danger" },
      user: {
        name: "Friderik Dávid",
        new: true,
        registered: "Jan 1, 2021",
      },
      country: { name: "Poland", flag: cifPl },
      usage: {
        value: 43,
        period: "Jun 11, 2021 - Jul 10, 2021",
        color: "success",
      },
      payment: { name: "Amex", icon: cibCcAmex },
      activity: "Last week",
    },
  ];

  return (
    <>
      <div className="content">
        <WidgetsDropdown usersCount={customerData?.length} incomeCount={totalDisbursementAmount()} aaddharUsageCount={aaddharUsageCount} aaddharTokenBalance={aaddharTokenBalance} />
        <CCard className="content mb-4">
          <CCardBody>
            <CRow>
              <CCol sm={5}>
                <h4 id="traffic" className="card-title mb-0">
                  Traffic
                </h4>
                <div className="small text-medium-emphasis">
                  January - July 2021
                </div>
              </CCol>
              <CCol sm={7} className="d-none d-md-block">
                <CButton color="primary" className="float-end">
                  <CIcon icon={cilCloudDownload} />
                </CButton>
                <CButtonGroup className="float-end me-3">
                  {["Day", "Month", "Year"]?.map((value) => (
                    <CButton
                      color="outline-secondary"
                      key={value}
                      className="mx-0"
                      active={value === "Month"}
                    >
                      {value}
                    </CButton>
                  ))}
                </CButtonGroup>
              </CCol>
            </CRow>
            <CChartLine
              style={{ height: "300px", marginTop: "40px" }}
              data={{
                labels: [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                ],
                datasets: [
                  {
                    label: "My First dataset",
                    backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
                    borderColor: getStyle("--cui-info"),
                    pointHoverBackgroundColor: getStyle("--cui-info"),
                    borderWidth: 2,
                    data: [
                      random(50, 200),
                      random(50, 200),
                      random(50, 200),
                      random(50, 200),
                      random(50, 200),
                      random(50, 200),
                      random(50, 200),
                    ],
                    fill: true,
                  },
                  {
                    label: "My Second dataset",
                    backgroundColor: "transparent",
                    borderColor: getStyle("--cui-success"),
                    pointHoverBackgroundColor: getStyle("--cui-success"),
                    borderWidth: 2,
                    data: [
                      random(50, 200),
                      random(50, 200),
                      random(50, 200),
                      random(50, 200),
                      random(50, 200),
                      random(50, 200),
                      random(50, 200),
                    ],
                  },
                  {
                    label: "My Third dataset",
                    backgroundColor: "transparent",
                    borderColor: getStyle("--cui-danger"),
                    pointHoverBackgroundColor: getStyle("--cui-danger"),
                    borderWidth: 1,
                    borderDash: [8, 5],
                    data: [65, 65, 65, 65, 65, 65, 65],
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    grid: {
                      drawOnChartArea: false,
                    },
                  },
                  y: {
                    ticks: {
                      beginAtZero: true,
                      maxTicksLimit: 5,
                      stepSize: Math.ceil(250 / 5),
                      max: 250,
                    },
                  },
                },
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3,
                  },
                },
              }}
            />
          </CCardBody>
          <CCardFooter>
            <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
              {progressExample?.map((item, index) => (
                <CCol className="mb-sm-2 mb-0" key={index}>
                  <div className="text-medium-emphasis">{item?.title}</div>
                  <strong>
                    {item?.value}
                    {/* ({item.percent}%) */}
                  </strong>
                  <CProgress
                    thin
                    className="mt-2"
                    color={item?.color}
                    value={item?.percent}
                  />
                </CCol>
              ))}
            </CRow>
          </CCardFooter>
        </CCard>

        <WidgetsBrand withCharts />

        <CRow>
          <CCol xs>
            <CCard className="mb-4">
              <CCardHeader>Traffic {" & "} Sales</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol xs={12} md={6} xl={6}>
                    <CRow>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-info py-1 px-3">
                          <div className="text-medium-emphasis small">
                            New Clients
                          </div>
                          <div className="fs-5 fw-semibold">
                            {metaData?.newUsersCount}
                          </div>
                        </div>
                      </CCol>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-danger py-1 px-3 mb-3">
                          <div className="text-medium-emphasis small">
                            Due EMI Collection
                          </div>
                          <div className="fs-5 fw-semibold">
                            {metaData?.dueEMICollection}
                          </div>
                        </div>
                      </CCol>
                    </CRow>

                    <hr className="mt-0" />
                    {progressGroupExample1?.map((item, index) => (
                      <div className="progress-group mb-4" key={index}>
                        <div className="progress-group-prepend">
                          <span className="text-medium-emphasis small">
                            {item.title}
                          </span>
                        </div>
                        <div className="progress-group-bars">
                          <CProgress thin color="info" value={item?.value1} />
                          <CProgress thin color="danger" value={item?.value2} />
                        </div>
                      </div>
                    ))}
                  </CCol>

                  <CCol xs={12} md={6} xl={6}>
                    <CRow>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-warning py-1 px-3 mb-3">
                          <div className="text-medium-emphasis small">
                            {"Today's EMI Collection"}
                          </div>
                          <div className="fs-5 fw-semibold">
                            {metaData?.todayEMICollection}
                          </div>
                        </div>
                      </CCol>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                          <div className="text-medium-emphasis small">
                            Pending
                          </div>
                          <div className="fs-5 fw-semibold">
                            {totalPendingEMIs()}
                          </div>
                        </div>
                      </CCol>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                          <div className="text-medium-emphasis small">
                            Paid EMIs
                          </div>
                          <div className="fs-5 fw-semibold">{totalPaidEMIs()}</div>
                        </div>
                      </CCol>
                      <CCol sm={6}>
                        <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                          <div className="text-medium-emphasis small">
                            Paid EMIs
                          </div>
                          <div className="fs-5 fw-semibold">{totalPaidEMIs()}</div>
                        </div>
                      </CCol>
                    </CRow>

                    <hr className="mt-0" />

                    {progressGroupExample2?.map((item, index) => (
                      <div className="progress-group mb-4" key={index}>
                        <div className="progress-group-header">
                          <CIcon className="me-2" icon={item?.icon} size="lg" />
                          <span>{item?.title}</span>
                          <span className="ms-auto fw-semibold">
                            {item?.value}%
                          </span>
                        </div>
                        <div className="progress-group-bars">
                          <CProgress thin color="warning" value={item?.value} />
                        </div>
                      </div>
                    ))}

                    <div className="mb-5"></div>

                    {progressGroupExample3?.map((item, index) => (
                      <div className="progress-group" key={index}>
                        <div className="progress-group-header">
                          <CIcon className="me-2" icon={item.icon} size="lg" />
                          <span>{item.title}</span>
                          <span className="ms-auto fw-semibold">
                            {item.value}{" "}
                            <span className="text-medium-emphasis small">
                              ({item.percent}%)
                            </span>
                          </span>
                        </div>
                        <div className="progress-group-bars">
                          <CProgress
                            thin
                            color="success"
                            value={item.percent}
                          />
                        </div>
                      </div>
                    ))}
                  </CCol>
                </CRow>

                <br />

                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell>ID</CTableHeaderCell>
                      <CTableHeaderCell>User</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        Credit Score
                      </CTableHeaderCell>
                      <CTableHeaderCell>Loan Amount Requested</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">
                        Loan Approve Status
                      </CTableHeaderCell>
                      <CTableHeaderCell>Loan Term</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {customerData?.map((item, index) => (
                      <CTableRow v-for="item in tableItems" key={index}>
                        <CTableDataCell>
                          <div>{item?.id}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{item?.firstName}</div>
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item?.creditScore}
                        </CTableDataCell>
                        <CTableDataCell>
                          {item?.loanAmountRequested}
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {item?.loanApprovalStatus?.toUpperCase()}
                        </CTableDataCell>
                        <CTableDataCell>{item?.loanTerm}</CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </>
  );
};

export default Dashboard;
