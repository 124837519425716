import React from "react";
import CIcon from "@coreui/icons-react";
import { CNavGroup, CNavItem } from "@coreui/react";
import {
  cilSpeedometer,
  cilLibraryBuilding,
  cilLayers,
  cibCreativeCommonsSamplingPlus,
  cilUserPlus,
} from "@coreui/icons";

const Menu = [
  //All Users Menu
  {
    component: CNavItem, // Update component to CNavItem
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  //Adminstatiores Menu
  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Company",
    to: "/users",
    showOnlyForRole: "admin",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  //Company Screens Menu
  {
    component: CNavGroup,
    name: "Directors",
    to: "/directors",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
  },

  {
    component: CNavGroup,
    name: "Branches",
    to: "/branches",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Products",
    showOnlyForRole: "company",
    to: "/productlist",
    icon: (
      <CIcon icon={cibCreativeCommonsSamplingPlus} customClassName="nav-icon" />
    ),
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Products",
    showOnlyForRole: "employee",
    to: "/productlist",
    icon: (
      <CIcon icon={cibCreativeCommonsSamplingPlus} customClassName="nav-icon" />
    ),
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Manage Staff",
    to: "/staff",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "Add Staff",
        to: "/staff/addstaff",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Staff Details",
        to: "/staff",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Loans",
    // to: "/customer-profile",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      // {
      //   component: CNavItem, // Update component to CNavItem
      //   name: "Check CIBIL",
      //   // to: "/customers",
      //   // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      // },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Apply Loan",
        to: "/customers",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Verification",
        to: "/verification",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Track Customer Details",
        to: "/customer-profile",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Download Customer Details",
        to: "/allcustomerdetails",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Group Loan",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add Group",
        to: "/group/addgroup",
      },
      {
        component: CNavItem,
        name: "Group List",
        to: "/group/grouplist",
      },
    ],
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "EMI Status",
    // to: "/emirecord",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "EMI Record",
        to: "/emirecords",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Due",
        to: "/due",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Payment Records",
        to: "/paymentrecords",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Reports",
    // to: "/emirecord",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "EMI Statement",
        to: "/emistatement",
      },
    ],
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Collections",
    to: "/emisection",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },

  // {
  //   component: CNavGroup,
  //   name: "Verification",
  //   to: "/verification",
  //   showOnlyForRole: "company",
  // },

  // {
  //   component: CNavGroup, // Update component to CNavGroup
  //   name: "EMI Calculations",
  //   to: "/calculateEmi",
  //   showOnlyForRole: "company",
  //   icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem, // Update component to CNavItem
  //       name: "EMI",
  //       to: "/calculateEmi",
  //       // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
  //     },
  //   ],
  // },

  //Manager Screen Menu

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Products",
    showOnlyForRole: "manager",
    to: "/productlist",
    icon: (
      <CIcon icon={cibCreativeCommonsSamplingPlus} customClassName="nav-icon" />
    ),
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Manage Staff",
    to: "/staff",
    showOnlyForRole: "manager",
    icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "Add Staff",
        to: "/staff/addstaff",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Staff Details",
        to: "/staff",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Group Loan",
    showOnlyForRole: "manager",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add Group",
        to: "/group/addgroup",
      },
      {
        component: CNavItem,
        name: "Group List",
        to: "/group/grouplist",
      },
    ],
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Loans",
    // to: "/customer-profile",
    showOnlyForRole: "manager",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      // {
      //   component: CNavItem, // Update component to CNavItem
      //   name: "Check CIBIL",
      //   // to: "/customers",
      //   // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      // },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Apply Loan",
        to: "/customers",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Verification",
        to: "/verification",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Track Customer Details",
        to: "/customer-profile",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Download Customer Details",
        to: "/allcustomerdetails",
      },
    ],
  },

  // monu

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "EMI Status",
    // to: "/emirecord",
    showOnlyForRole: "manager",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "EMI Record",
        to: "/emirecords",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Due",
        to: "/due",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Payment Records",
        to: "/paymentrecords",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },

  //Staff Screen Menu

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Manage Staff",
    to: "/staff",
    showOnlyForRole: "employee",
    icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "Staff",
        to: "/staff",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },


  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Loans",
    // to: "/customer-profile",
    showOnlyForRole: "employee",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      // {
      //   component: CNavItem, // Update component to CNavItem
      //   name: "Check CIBIL",
      //   // to: "/customers",
      //   // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      // },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Apply Loan",
        to: "/customers",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Verification",
        to: "/verification",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Track Customer Details",
        to: "/customer-profile",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Loans",
    // to: "/customer-profile",
    showOnlyForRole: "staff",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      // {
      //   component: CNavItem, // Update component to CNavItem
      //   name: "Check CIBIL",
      //   // to: "/customers",
      //   // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      // },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Apply Loan",
        to: "/customers",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Verification",
        to: "/verification",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Track Customer Details",
        to: "/customer-profile",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Download Customer Details",
        to: "/allcustomerdetails",
      },
    ],
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Collections",
    to: "/collection",
    showOnlyForRole: "employee",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "Agent Collections",
        to: "/paidcollection",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Collection",
        to: "/collection",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "EMI Status",
    to: "/emirecord",
    // showOnlyForRole: "agent",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "Emi Record",
        to: "/paidcollection",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Due",
        to: "/collection",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Payment Records",
        to: "/collection",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },

  // {
  //   component: CNavGroup, // Update component to CNavGroup
  //   name: "EMI Calculations",
  //   to: "/calculateEmi",
  //   showOnlyForRole: "employee",
  //   icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem, // Update component to CNavItem
  //       name: "EMI",
  //       to: "/calculateEmi",
  //       // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
  //     },
  //   ],
  // },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Collections",
    to: "/pendingcollection",
    showOnlyForRole: "manager",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "Emi Collections",
        to: "/emisection",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },

  // {
  //   component: CNavGroup,
  //   name: "Verification",
  //   to: "/verification",
  //   showOnlyForRole: "manager",
  // },

  //Agent Screens Menu
  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Home",
    to: "/owndetails",
    showOnlyForRole: "agent",
    icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
  },

  // {
  //   component: CNavGroup,
  //   name: "Verification",
  //   to: "/verification",
  //   showOnlyForRole: "employee",
  // },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Loans",
    // to: "/customer-profile",
    showOnlyForRole: "agent",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      // {
      //   component: CNavItem, // Update component to CNavItem
      //   name: "Check CIBIL",
      //   // to: "/customers",
      //   // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      // },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Apply Loan",
        to: "/customers",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Track Customer Details",
        to: "/customer-profile",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Download Customer Details",
        to: "/allcustomerdetails",
      },
    ],
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Collections",
    to: "/pendingcollection",
    showOnlyForRole: "agent",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "Agent Collections",
        to: "/paidcollection",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Pending Collections",
        to: "/pendingcollection",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
      {
        component: CNavItem, // Update component to CNavItem
        name: "Paid Collections",
        to: "/paidcollection",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },

  // {
  //   component: CNavGroup, // Update component to CNavGroup
  //   name: "EMI Calculations",
  //   to: "/calculateEmi",
  //   showOnlyForRole: "agent",
  //   icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem, // Update component to CNavItem
  //       name: "EMI",
  //       to: "/calculateEmi",
  //       // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
  //     },
  //   ],
  // },

  //Customer Screens Menu

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Manage Customers",
    to: "/customer-profile",
    showOnlyForRole: "customer",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "Customers Loan Details",
        to: "/customer-profile",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },

  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Emi",
    to: "/emi/loanType",
    showOnlyForRole: "customer",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem, // Update component to CNavItem
        name: "loanType",
        to: "/emi/loanType",
        // icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
      },
    ],
  },

  // ================================ Accounting section  ===========================================
  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Accounting",
    // to: "/customer-profile",
    showOnlyForRole: "company",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      // {
      //   component: CNavItem,
      //   name: "Journal Entries",
      //   to: "/journalentrieslist",
      // },
      {
        component: CNavItem,
        name: "Master Account",
        to: "/masteraccount",
      },
      {
        component: CNavItem,
        name: "Payment",
        to: "/paymentForm",
      },
      {
        component: CNavItem,
        name: "Reciept",
        to: "/recieptForm",
      },
      {
        component: CNavItem,
        name: "Journals",
        to: "/journaldata",
      },
      // {
      //   component: CNavItem,
      //   name: "Contra Entries",
      //   to: "/contraentrieslist",
      // },
      {
        component: CNavItem,
        name: "Ledger",
        to: "/ledger",
      },
      // {
      //   component: CNavItem,
      //   name: "Profit And Loss Account",
      //   to: "/profitandlosslist",
      // },
      // {
      //   component: CNavItem,
      //   name: "Balance Sheet",
      //   to: "/balancelist",
      // },
    ],
  },
  {
    component: CNavGroup, // Update component to CNavGroup
    name: "Accounting",
    // to: "/customer-profile",
    showOnlyForRole: "manager",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Master Account",
        to: "/masteraccount",
      },
      {
        component: CNavItem,
        name: "Journals",
        to: "/journaldata",
      },
      {
        component: CNavItem,
        name: "Ledger",
        to: "/ledger",
      },

      {
        component: CNavItem,
        name: "Trading Account",
        to: "/trading",
      },
      {
        component: CNavItem,
        name: "Profit And Loss Account",
        to: "/profitandlosslist",
      },
      {
        component: CNavItem,
        name: "Opening Balance Sheet",
        to: "/openingbalancelist",
      },
    ],
  },
];

export default Menu;

