import React from "react";
import PropTypes from "prop-types";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardImage,

  CCardTitle,
  CCol,
  CRow,
} from "@coreui/react";
import { getS3Url } from "src/services/halper";
const DownloadDocuments = ({ customerData, emiData }) => {

  const panUrl = getS3Url(customerData?.panImageUrl);
  const aadhaarUrl = getS3Url(customerData?.aadharImageUrl);
  const bankstmtImg = getS3Url(customerData?.bankStatementsImageUrl);
  const paySlipUrl = getS3Url(customerData?.paySlipImageUrl);
  const signatureUrl = getS3Url(customerData?.signatureImageUrl);

  const handleDownloadClick = (imageUrl, fileName) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div>
      <CCard>
        <CCardBody>
          <CRow xs={{ cols: 1, gutter: 4 }} md={{ cols: 2 }}>
            <CCol xs>
              <CCard>
                <CCardImage orientation="top" src={panUrl} width="100%" />
                <CCardBody>
                  <CCardTitle>Pan Card</CCardTitle>
                </CCardBody>
                <CCardFooter className="text-center">
                  <CButton
                    onClick={() =>
                      handleDownloadClick(panUrl, "pan_card_image.jpg")
                    }
                  >
                    Download
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
            <CCol xs>
              <CCard>
                <CCardImage orientation="top" src={aadhaarUrl} />
                <CCardBody>
                  <CCardTitle>Aadhar Card</CCardTitle>
                </CCardBody>
                <CCardFooter className="text-center">
                  <CButton
                    onClick={() =>
                      handleDownloadClick(aadhaarUrl, "aadhaar_card_image.jpg")
                    }
                  >
                    Download
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
            <CCol xs>
              <CCard>
                <CCardImage orientation="top" src={bankstmtImg} />
                <CCardBody>
                  <CCardTitle>Bank Statement</CCardTitle>
                </CCardBody>
                <CCardFooter className="text-center">
                  <CButton
                    onClick={() =>
                      handleDownloadClick(bankstmtImg, "bankstmtImg_image.jpg")
                    }
                  >
                    Download
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
            <CCol xs>
              <CCard>
                <CCardImage orientation="top" src={paySlipUrl} />
                <CCardBody>
                  <CCardTitle>Pay Slip</CCardTitle>
                </CCardBody>
                <CCardFooter className="text-center">
                  <CButton
                    onClick={() =>
                      handleDownloadClick(paySlipUrl, "paySlip_image.jpg")
                    }
                  >
                    Download
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
            <CCol xs>
              <CCard>
                <CCardImage orientation="top" src={signatureUrl} />
                <CCardBody>
                  <CCardTitle>Signature</CCardTitle>
                </CCardBody>
                <CCardFooter className="text-center">
                  <CButton
                    onClick={() =>
                      handleDownloadClick(signatureUrl, "signature_image.jpg")
                    }
                  >
                    Download
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  );
};
DownloadDocuments.propTypes = {
  customerData: PropTypes.object.isRequired,
  emiData: PropTypes.array.isRequired,
};
export default DownloadDocuments;
