import React, { useState, useRef, useEffect } from "react";
import {
  CModal,
  CButton,
  CModalBody,
  CCol,
  CContainer,
  CForm,
  CModalHeader,
  CModalTitle,
  CRow,
  CFormInput,
} from "@coreui/react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { uploadSanctionLetter } from "src/redux/actions/customerAction";
import { useNavigate } from "react-router-dom";

const initialValues = {
  sanctionLetterImageUrl: null,
};
const SantialLetter = ({ setShowSantialLetter, showSantialLetter, cuData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const santialRef = useRef();
  const [SchemaName, setSchemaName] = useState(sessionStorage.getItem("schemaName"));
  const [status, setStatus] = useState(false)
  const [identification, setIdentification] = useState(initialValues);

  const handleSubmit = (e) => {
    e.preventDefault();

    const santialFile = santialRef?.current?.files[0];

    if (santialFile) {
      const formData = new FormData();
      formData.append("schemaName", SchemaName);
      formData.append("isUploaded", true);
      formData.append("customerId", cuData?.id);
      formData.append("sanctionLetterDate", new Date().toISOString().split('T')[0]);
      formData.append("sanctionLetterImageUrl", santialFile);

      dispatch(uploadSanctionLetter(formData));
      setStatus(true)
    }
  };
  useEffect(() => {
    if (status === true) {
      navigate("/printdisburshment")

    }
  }, [status])
  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setIdentification({ ...identification, [name]: value });
  };
  return (
    <CModal
      backdrop="static"
      visible={showSantialLetter}
      onClose={() => setShowSantialLetter(false)}
      aria-labelledby="StaticBackdropExampleLabel"
      size="lg"
    >
      <CModalHeader>
        <CModalTitle id="StaticBackdropExampleLabel">
          Upload Santial Letter
        </CModalTitle>
      </CModalHeader>

      <CModalBody>
        <CContainer>
          <CForm onSubmit={handleSubmit} autoComplete="off">
            <CRow style={{ margin: "20px 0" }}>
              <CCol>
                <CFormInput
                  type="file"
                  ref={santialRef}
                  id="sanctionLetterImageUrl"
                  placeholder="Upload Sanction Letter"
                />
              </CCol>
            </CRow>
            <CRow style={{ margin: "20px 0" }} className="d-flex justify-content-center">
              <CCol xs={7}>
                <CRow>
                  <CCol xs={3}></CCol>
                  <CCol xs={3}></CCol>
                  <CCol xs={3}>
                    <CButton type="submit" color="primary" className="px-4">
                      Upload
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

SantialLetter.propTypes = {
  showSantialLetter: PropTypes.bool.isRequired,
  cuData: PropTypes.object.isRequired,
  setShowSantialLetter: PropTypes.func.isRequired,
};


export default SantialLetter;
