import React, { useEffect, useState } from "react";
import {
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CRow,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormLabel,
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { EmployeePorvider } from "./provider";
import { setSchemaName } from "src/redux/actions/staffAction";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CameraIndoorOutlined } from "@mui/icons-material";

const AddStaffForm = (event) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [schemaname, setSchemaname] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const { Branches } = useSelector((state) => state?.customerReducer);
  const currentDate = new Date();
  const maxDateJoining = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const maxJoining = `${maxDateJoining.getFullYear()}-${(
    maxDateJoining.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${maxDateJoining.getDate().toString().padStart(2, "0")}`;
  const maxDoB = new Date(
    currentDate.getFullYear() - 18,
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const maxDateOfBirth = `${maxDoB.getFullYear()}-${(maxDoB.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${maxDoB.getDate().toString().padStart(2, "0")}`;

  useEffect(() => {
    const schemaname = sessionStorage.getItem("schemaName");
    dispatch(setSchemaName(schemaname));
    setSchemaname(schemaname);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("isEdit") === "1") {
      setIsEdit(true);
    }
  }, []);

  const {
    employeeData,
    onChangeValue,
    handleSubmit,
    handleSelectChange,
    DACRef,
    error,
    passPortPhotoRef,
    panImageRef,
    aadharImageRef,
    selectedBranchId,
    signatureRef,
  } = EmployeePorvider();

  const {
    userName,
    password,
    firstName,
    middleName,
    lastName,
    dob,
    gender,
    email,
    phoneNumber,
    address,
    emergencyContactInformation,
    emergencyContactNumber,
    jobTitle,
    dateOfHire,
    managerName,
    basicSalary,
    bankAccountNo,
    ifscCode,
    highestEducation,
    degreeAndCertificate,
    degreeAndCertificateImageUrl,
    employeeVerification,
    previousWorkExperience,
    passportNumber,
    medicaInsurance,
    aadharImageUrl,
    panImageUrl,
    identification,
    passportSizePhoto,
    PanNumber,
    aadharNumber,
    empbranchCode,
    signatureImageUrl,
    dlNumber,
    employeeRole,
    empStatus
  } = employeeData;

  const branchId = JSON.parse(sessionStorage.getItem("branc"));
  const role = sessionStorage.getItem("role");

  return (
    <>
      <CRow className="content mb-4">
        <CCol xs={12}>
          <CCard>
            <CCardHeader>
              <div className="d-flex justify-content-between">
                <div>
                  <strong>{isEdit ? "Edit Staff" : "Add Staff"}</strong>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => navigate("/staff")}
                />
              </div>
            </CCardHeader>
            <div className="theform_text">
              <CCardBody>
                <CContainer>
                  <CForm
                    onSubmit={(event) => handleSubmit(event, isEdit)}
                    autoComplete="off"
                  >
                    <div className="staffform_header">
                      <span>Basic Details :</span>
                      <span className="mb-4">
                        {isEdit ? (
                          <>
                            <CFormLabel>Branch Name</CFormLabel>
                            <CFormInput
                              type="text"
                              value={employeeData?.branch?.branchName}
                              disabled
                            />
                          </>
                        ) : (
                          <CRow>
                            <CFormLabel className="font14">
                              Select Branch
                              <span className="red-star">{` `}*</span>
                            </CFormLabel>
                            <CFormSelect
                              id="userName"
                              name="userName"
                              onChange={handleSelectChange}
                              required
                            >
                              <option value="">Select Branch</option>
                              {Branches?.data?.map((branch) => (
                                <option key={branch.id} value={branch.id}>
                                  {branch.branchName}
                                </option>
                              ))}
                            </CFormSelect>
                          </CRow>
                        )}
                      </span>{" "}
                    </div>
                    {!isEdit && (
                      <CRow className="pt-20">
                        <CCol xs={6}>
                          <CFormLabel htmlFor="userName">
                            User Name
                            <span className="red-star">{` `}*</span>
                          </CFormLabel>
                          <CFormInput
                            type="text"
                            id="userName"
                            placeholder="User Name"
                            name="userName"
                            value={userName}
                            onChange={onChangeValue}
                            required
                          />
                        </CCol>
                        <CCol xs={6}>
                          <CFormLabel htmlFor="password">
                            Password
                            <span className="red-star">{` `}*</span>
                          </CFormLabel>
                          <CFormInput
                            type="text"
                            id="password"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={onChangeValue}
                          />
                        </CCol>
                      </CRow>
                    )}
                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="firstName">
                          First Name
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="firstName"
                          placeholder="First Name"
                          name="firstName"
                          value={firstName}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="middleName">
                          Middle Name
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="middleName"
                          placeholder="Middle Name"
                          name="middleName"
                          value={middleName}
                          onChange={onChangeValue}
                        />
                      </CCol>
                    </CRow>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="lastName">
                          Last Name
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="lastName"
                          placeholder="Last Name"
                          name="lastName"
                          value={lastName}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="planType">
                          Date of Birth
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="date"
                          id="dob"
                          name="dob"
                          value={dob}
                          onChange={onChangeValue}
                          max={maxDateOfBirth}
                        />
                      </CCol>
                    </CRow>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="gender">
                          Gender
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="custom-select"
                          id="gender"
                          name="gender"
                          type="select"
                          value={gender}
                          onChange={onChangeValue}
                          required
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </CFormSelect>
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="gender">
                          Email
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="email"
                          id="email"
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="phoneNumber">
                          Phone Number
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="phoneNumber"
                          minLength={10}
                          maxLength={10}
                          placeholder="Phone Number"
                          name="phoneNumber"
                          value={phoneNumber}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="address">
                          Address
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="address"
                          placeholder="Address"
                          name="address"
                          value={address}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>

                    <div className="staffform_header">Staff Details :</div>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="jobTitle">
                          Designation
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="jobTitle"
                          placeholder="Designation"
                          name="jobTitle"
                          value={jobTitle}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="dateOfHire">
                          Joining Date
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="date"
                          id="dateOfHire"
                          name="dateOfHire"
                          value={dateOfHire}
                          onChange={onChangeValue}
                          max={maxJoining}
                        />
                      </CCol>
                    </CRow>
                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="employeeRole">
                          Staff Role
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="mb-4 custom-select"
                          id="employeeRole"
                          name="employeeRole"
                          value={employeeRole}
                          onChange={onChangeValue}
                          type="select"
                          required
                        >
                          <option value="">Select Role</option>
                          <option value="manager">Manager</option>
                          <option value="employee">Staff</option>
                          <option value="agent">Agent</option>
                        </CFormSelect>
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="identification">
                          Identification
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="identification"
                          placeholder="Identification"
                          name="identification"
                          value={identification}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>
                    <CRow className="">
                      {/* <CCol xs={6}>
                        <CFormLabel htmlFor="employeeVerification">
                          Staff Verification
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="employeeVerification"
                          placeholder="Staff Verification"
                          name="employeeVerification"
                          value={employeeVerification}
                          onChange={onChangeValue}
                        />
                      </CCol> */}
                      <CCol xs={6}>
                        <CFormLabel htmlFor="previousWorkExperience">
                          Previous Work Experience
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="previousWorkExperience"
                          placeholder="Previous Work Experience"
                          name="previousWorkExperience"
                          value={previousWorkExperience}
                          onChange={onChangeValue}
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="medicaInsurance">
                          Medical Insurance
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="medicaInsurance"
                          placeholder="Medical Insurance"
                          name="medicaInsurance"
                          value={medicaInsurance}
                          onChange={onChangeValue}
                        />
                      </CCol>
                    </CRow>
                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="managerName">
                          Manager Name
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="managerName"
                          placeholder="Manager Name"
                          name="managerName"
                          value={managerName}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="basicSalary">
                          Basic Salary
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="number"
                          id="basicSalary"
                          placeholder="Basic Salary"
                          name="basicSalary"
                          value={basicSalary}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>
                    <div className="staffform_header">
                      Bank Account Details :
                    </div>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="bankAccountNo">
                          Bank Account Number
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="number"
                          id="bankAccountNo"
                          placeholder="Bank Account Number"
                          name="bankAccountNo"
                          value={bankAccountNo}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="ifscCode">
                          IFSC Code
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="ifscCode"
                          placeholder="IFSC Code"
                          name="ifscCode"
                          value={ifscCode}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>
                    <div className="staffform_header">
                      Qualification Details :
                    </div>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="highestEducation">
                          Highest Education
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="highestEducation"
                          placeholder="Highest Education"
                          name="highestEducation"
                          value={highestEducation}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="degreeAndCertificate">
                          Degree And Certificate
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="degreeAndCertificate"
                          placeholder="Degree and Certificate"
                          name="degreeAndCertificate"
                          value={degreeAndCertificate}
                          onChange={onChangeValue}
                        />
                      </CCol>
                    </CRow>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="degreeAndCertificateImageUrl">
                          Upload Degree And Certificate
                        </CFormLabel>
                        <CFormInput
                          type="file"
                          ref={DACRef}
                          id="degreeAndCertificateImageUrl"
                          placeholder="Upload Degree and Certificate"
                          name="degreeAndCertificateImageUrl"
                          value={degreeAndCertificateImageUrl}
                          onChange={onChangeValue}
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="passportNumber">
                          Passport Number
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="passportNumber"
                          placeholder="Passport Number"
                          name="passportNumber"
                          value={passportNumber}
                          onChange={onChangeValue}
                        />
                      </CCol>
                    </CRow>

                    <div className="staffform_header">
                      Verification Details :
                    </div>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="PanNumber">
                          PAN Number
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          id="PanNumber"
                          type="text"
                          placeholder="PAN Number"
                          name="PanNumber"
                          value={PanNumber}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="panImageUrl">
                          Upload PAN Card{" "}
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="file"
                          ref={panImageRef}
                          id="panImageUrl"
                          placeholder="Upload PAN Card "
                          name="panImageUrl"
                          value={panImageUrl}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="aadharNumber">
                          Aadhar Number
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          id="aadharNumber"
                          type="number"
                          placeholder="Aadhar Card Number"
                          name="aadharNumber"
                          value={aadharNumber}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="aadharImageUrl">
                          Upload Aadhar Card
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="file"
                          ref={aadharImageRef}
                          id="aadharImageUrl"
                          placeholder="Upload Aadhar Card"
                          name="aadharImageUrl"
                          value={aadharImageUrl}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>

                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="signatureImageUrl">
                          Upload Signature Image{" "}
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="file"
                          ref={signatureRef}
                          placeholder="Signature Image"
                          id="signatureImageUrl"
                          name="signatureImageUrl"
                          value={signatureImageUrl}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="passportSizePhoto">
                          Passport Size Photo
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="file"
                          ref={passPortPhotoRef}
                          id="passportSizePhoto"
                          placeholder="Passport Size Photo"
                          name="passportSizePhoto"
                          value={passportSizePhoto}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>
                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="dlNumber">DL Number</CFormLabel>
                        <CFormInput
                          id="dlNumber"
                          type="text"
                          placeholder="DL Number"
                          name="dlNumber"
                          value={dlNumber}
                          onChange={onChangeValue}
                        />
                      </CCol>
                    </CRow>
                    <div className="staffform_header">
                      Emergency Contact Information :
                    </div>
                    <CRow className="pt-20 mb-4">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="emergencyContactInformation">
                          Name
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          id="emergencyContactInformation"
                          placeholder="Name"
                          name="emergencyContactInformation"
                          value={emergencyContactInformation}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                      <CCol xs={6}>
                        <CFormLabel htmlFor="emergencyContactNumber">
                          Contact Number
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormInput
                          type="text"
                          minLength={10}
                          maxLength={10}
                          id="emergencyContactNumber"
                          placeholder="Contact Number"
                          name="emergencyContactNumber"
                          value={emergencyContactNumber}
                          onChange={onChangeValue}
                          required
                        />
                      </CCol>
                    </CRow>
                    <CRow className="pt-20">
                      <CCol xs={6}>
                        <CFormLabel htmlFor="empStatus">
                          Status
                          <span className="red-star">{` `}*</span>
                        </CFormLabel>
                        <CFormSelect
                          className="custom-select"
                          id="empStatus"
                          name="empStatus"
                          type="empStatus"
                          value={empStatus}
                          onChange={onChangeValue}
                          required
                        >
                          <option value="">Select Status</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </CFormSelect>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol xs={4}></CCol>
                      <CCol xs={7} className="error_er">
                        <span
                          className="red-star"
                          style={{ margin: " 0px 54px" }}
                        >
                          {error}
                        </span>
                      </CCol>
                    </CRow>

                    <CRow style={{ paddingTop: 30 }}>
                      <CCol xs={6} className="text-end">
                        <CButton type="submit" color="primary" className="px-4">
                          {isEdit ? "Update" : "Submit"}
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-left">
                        <CButton
                          onClick={() => navigate("/staff")}
                          color="primary"
                          className="px-4"
                          style={{ backgroundColor: "red" }}
                        >
                          Cancel
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CContainer>
              </CCardBody>
            </div>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default AddStaffForm;
