import React from "react";
import PropTypes from "prop-types";
import { CCard, CCardBody, CCol, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";

const EmiStatement = ({ filteredEmis, customerData }) => {

  let address_dict = JSON.parse(customerData?.address || "{}");
  let district = address_dict?.district;
  let state = address_dict?.state;
  let area = address_dict?.area;
  let country = address_dict?.country;
  let pincode = address_dict?.pincode;

  return (
    <CRow className="">
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardBody>
            <CRow>
              <CCol xs={12}>
                <div className="main_div">
                  <div className="logo">Logo</div>
                  <div className="de_tails">
                    <div className="de_rt">
                      <div className="de_span">{`${customerData?.firstName} ${customerData?.lastName}`}</div>
                      <div className="de_spn">{area}</div>
                      <div className="de_spn">{district},{state}</div>
                      <div className="de_spn">{country} - {pincode}</div>
                    </div>
                    <div className="de_lt">
                      <div className="de_spn">Loan Account No : {customerData?.loanAccountNumber}</div>
                      <div className="de_spn">Customer No : {customerData?.phoneNumber}</div>
                      <div className="de_spn">Curreny : INR</div>
                    </div>
                  </div>
                  <div className="sta">
                    Statement of {`${customerData?.firstName} ${customerData?.lastName}`}
                  </div>
                </div>
                <CCardBody>
                  <CRow>
                    <CCol xs={12}>
                      <CTable border={1} hover responsive>
                        <CTableHead className="tabl-head">
                          <CTableRow className="table-row">
                            <CTableHeaderCell className="col-1">Sr.No.</CTableHeaderCell>
                            <CTableHeaderCell className="col-1">Date</CTableHeaderCell>
                            <CTableHeaderCell className="col-8" style={{ textAlign: 'left' }}>Perticulars</CTableHeaderCell>
                            <CTableHeaderCell className="col-1">Debit</CTableHeaderCell>
                            <CTableHeaderCell className="col-1">Credit</CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>
                        <CTableBody>
                          {filteredEmis?.reverse()?.map((ele, index) => (
                            <React.Fragment key={index}>
                              <CTableRow>
                                <CTableDataCell>{index + 1}</CTableDataCell>
                                <CTableDataCell>{ele?.dueDate}</CTableDataCell>
                                <CTableDataCell style={{ textAlign: 'left' }}>&nbsp; Loan Amount</CTableDataCell>
                                <CTableDataCell>{ele.loadAmount}</CTableDataCell>
                                <CTableDataCell></CTableDataCell>
                              </CTableRow>
                              <CTableRow>
                                <CTableDataCell></CTableDataCell>
                                <CTableDataCell></CTableDataCell>
                                <CTableDataCell style={{ textAlign: 'left' }}>&nbsp; Interests</CTableDataCell>
                                <CTableDataCell>{ele?.interestAmount}</CTableDataCell>
                                <CTableDataCell></CTableDataCell>
                              </CTableRow>
                              <CTableRow>
                                <CTableDataCell></CTableDataCell>
                                <CTableDataCell></CTableDataCell>
                                <CTableDataCell style={{ textAlign: 'left' }}>&nbsp; Emi</CTableDataCell>
                                <CTableDataCell></CTableDataCell>
                                <CTableDataCell>{ele?.emiAmount}</CTableDataCell>
                              </CTableRow>
                            </React.Fragment>
                          ))}
                        </CTableBody>
                      </CTable>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

EmiStatement.propTypes = {
  filteredEmis: PropTypes.array.isRequired,
  customerData: PropTypes.object.isRequired,
  approvedLoan: PropTypes.object.isRequired,
};

export default EmiStatement;
