import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import {
  createCapitals,
  getAllCapitals,
} from "src/redux/actions/customerAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { successAlert } from "src/utils/Swal";

const CapitalAddForm = ({
  setShowAddForm,
  setShowAddButton,
  setShowSelectAndSearch,
  selectedCompany,
  setListData,
}) => {
  const dispatch = useDispatch();
  const { Capitals, newDirectorData, error } = useSelector(
    (state) => state?.customerReducer
  );

  const SchemaName = sessionStorage.getItem("schemaName");
  const [newCapital, setNewCapital] = useState(false);
  useEffect(() => {
    const obj = {
      schemaName: SchemaName,
    };
    if (newCapital === true) {
      if (newDirectorData?.status === "success") {
        successAlert("Success", "Fund Successfully Added");
        dispatch(getAllCapitals(obj));
        setNewCapital(false);
        setShowSelectAndSearch(true);
        setShowAddButton(true);
        setShowAddForm(false);
        setListData(true);
      }
    }
  }, [newDirectorData, newCapital]);

  const initialFormData = {
    loanDate: "",
    loanInterestRate: "",
    loanAmount: "",
    capital: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleShowAddButton = () => {
    setShowSelectAndSearch(true);
    setShowAddButton(true);
    setShowAddForm(false);
  };

  const handleAddForm = (e) => {
    e.preventDefault();
    const { loanDate, loanInterestRate, loanAmount, capital } = formData;
    const payload = {
      loanDate,
      schemaName: SchemaName,
      loanInterestRate:
        loanInterestRate === "" ? 0 : parseInt(loanInterestRate),
      loanAmount: loanAmount === "" ? 0 : parseInt(loanAmount),
      capital: capital === "" ? 0 : parseInt(capital),
      directorId: selectedCompany,
    };
    dispatch(createCapitals(payload));
    setNewCapital(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const { loanDate, loanInterestRate, loanAmount, capital } = formData;
  return (    
          <CCard>           
            <CCardHeader>
              <div className="close_btn p-0">
                <div>
                  <strong>Add Capital / Loan</strong>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => handleShowAddButton()}
                ></button>
              </div>
            </CCardHeader>

            <CForm onSubmit={(e) => handleAddForm(e)}>
              <CCardBody>
              <CRow>
              <h6 className="color_heading">Note : (please do not refresh the page)</h6>
            </CRow>
                <CRow className="">
                  <CCol xs={6}>
                    <div className="mb-4">
                      <CFormLabel htmlFor="capital">
                        Capital <span className="red-star">{` `}*</span>
                      </CFormLabel>
                      <CFormInput
                        type="number"
                        id="capital"
                        placeholder="Capital"
                        name="capital"
                        value={capital}
                        onChange={handleInputChange}
                      />
                    </div>
                  </CCol>

                  <CCol xs={6}>
                    <div className="mb-4">
                      <CFormLabel htmlFor="loan_amount">
                        Loan Amount
                        <span className="red-star">{` `}*</span>
                      </CFormLabel>
                      <CFormInput
                        type="number"
                        id="loan_amount"
                        placeholder="Loan Amount"
                        name="loanAmount"
                        value={loanAmount}
                        onChange={handleInputChange}
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow className="">
                  <CCol xs={6}>
                    <div className="mb-4">
                      <CFormLabel htmlFor="loan_date">
                        Loan Date
                        <span className="red-star">{` `}*</span>
                      </CFormLabel>
                      <CFormInput
                        type="date"
                        id="loan_date"
                        placeholder="Loan Date"
                        name="loanDate"
                        value={loanDate}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </CCol>

                  <CCol xs={6}>
                    <div className="mb-4">
                      <CFormLabel htmlFor="loan_interest_rate">
                        Loan Interest Rate
                        <span className="red-star">{` `}*</span>
                      </CFormLabel>
                      <CFormInput
                        type="number"
                        id="loan_interest_rate"
                        placeholder="Loan Interest Rate"
                        name="loanInterestRate"
                        value={loanInterestRate}
                        onChange={handleInputChange}
                      />
                    </div>
                  </CCol>
                </CRow>

                <div className="branch_btn">
                  <CButton type="submit">Submit</CButton>
                </div>
              </CCardBody>
            </CForm>
          </CCard>
  );
};
CapitalAddForm.propTypes = {
  setShowAddForm: PropTypes.func,
  setShowAddButton: PropTypes.func,
  setShowSelectAndSearch: PropTypes.func,
  setListData: PropTypes.func,
  selectedCompany: PropTypes.number,
  intial: PropTypes.object,
  edit: PropTypes.bool,
  callBack: PropTypes.func,
};

export default CapitalAddForm;
