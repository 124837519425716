import React from "react";

import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react";
import PropTypes from "prop-types";
const CompanyAllDetail = ({
  selectedCompany,
  setShowAccordion,
  setShowSelectAndSearch,
}) => {
  const handleCloseAccordian = () => {
    setShowSelectAndSearch(true);
    setShowAccordion(false);
  };
  return (
    <>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="d-flex header-details">
              <div>
                {/* {selectedCompany?.companyName} */}
                <b>Company Details</b>
              </div>
              <div className="close_btn p-0">
                <div></div>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => handleCloseAccordian()}
                ></button>
              </div>
            </CCardHeader>

            <CCardBody>
              {/* <div className="paymentrecord paymentrecord2"> */}
              <div style={{ width: "100%" }}>
                <div className="form-wrapper">
                  <div
                    className="statement-form statement-form2"
                    // ref={componentRef}
                    id="my-print"
                  >
                    <hr></hr>

                    <div className="maxima">
                      <div className="fifty">
                        {/* Branch Name : {selectedCompany?.branch?.branchName} */}
                      </div>
                      {/* <div className="fifty"></div> */}
                    </div>
                    <div className="stmt-headbar">
                      <div className="stmt-headbar2"></div>
                    </div>
                    <div className="maximacopy">
                      <div style={{ width: "100%" }}>
                        <div className="monusd">
                          <div style={{ width: "" }}>
                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>Company Owner Name</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span new_da">
                                  {selectedCompany?.companyOwnerName}
                                </span>
                              </div>
                            </div>

                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>Company Mobile Number</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span  new_da">
                                  {selectedCompany?.phoneNumber}
                                </span>
                              </div>
                            </div>

                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>Company Mail Id</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span new_da">
                                  {selectedCompany?.email}
                                </span>
                              </div>
                            </div>

                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>Company Owner PhoneNumber</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span new_da">
                                  {selectedCompany?.companyOwnerPhoneNumber}
                                </span>
                              </div>
                            </div>

                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>GST Number</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span new_da">
                                  {selectedCompany?.gstNumber}{" "}
                                </span>
                              </div>
                            </div>

                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>Licenses</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span new_da">
                                  {selectedCompany?.licenses}
                                </span>
                              </div>
                            </div>

                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>Identification Number</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span new_da">
                                  {selectedCompany?.taxIdentificationNumber}
                                </span>
                              </div>
                            </div>

                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>Company Reg. Date</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span new_da">
                                  {selectedCompany?.companyRegDate
                                    ? "---"
                                    : "---"}
                                </span>
                              </div>
                            </div>

                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>ZipCode</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span new_da">
                                  {selectedCompany?.zipcode || ""}
                                </span>
                              </div>
                            </div>

                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>City</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span new_da">
                                  {selectedCompany?.city}
                                </span>
                              </div>
                            </div>

                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>State</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span new_da">
                                  {selectedCompany?.state}
                                </span>
                              </div>
                            </div>

                            <div className="stmt-infocopy new_da">
                              <span className="info-headcopy">
                                <p>Number Of Employee</p>
                              </span>
                              <div className="info-inputcopy">
                                <>:</>
                                <span className="input-span new_da">
                                  {selectedCompany?.numberOfEmployee}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CCardBody>

            {/* <CCardBody>
              <CRow>
                <CCol xs={6}>
                  <label className="label">Company Owner Name</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.companyOwnerName} </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>Company Mobile Number</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.phoneNumber}{" "}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Company Mail Id</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.email} </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>Company Owner PhoneNumber</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.companyOwnerPhoneNumber} </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>GST Number</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.gstNumber}{" "}
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>Licenses</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.licenses}
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>Tax Identification Number</label>
                  <CCard>
                    <CCardBody>
                      {selectedCompany?.taxIdentificationNumber}{" "}
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>Company Reg. Date</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.companyRegDate ? "---" : "---"} </CCardBody>
                  </CCard >
                </CCol >
              </CRow >
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>ZipCode</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.zipCode} </CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>City</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.city} </CCardBody>
                  </CCard>
                </CCol>
              </CRow >
              <div className="mt-2"></div>
              <CRow>
                <CCol xs={6}>
                  <label>State</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.state}</CCardBody>
                  </CCard>
                </CCol>
                <CCol xs={6}>
                  <label>Number Of Employee</label>
                  <CCard>
                    <CCardBody>{selectedCompany?.numberOfEmployee}</CCardBody>
                  </CCard>
                </CCol>
              </CRow >
              <div className="mt-2"></div>
            </CCardBody > */}
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
CompanyAllDetail.propTypes = {
  setShowAccordion: PropTypes.func.isRequired,
  setShowSelectAndSearch: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
};

export default CompanyAllDetail;
