import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-js-pagination";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllEmiByCustomerId } from "src/redux/actions/emiAction";
import { getAllCustomers } from "src/api/allApis";
import { useReactToPrint } from "react-to-print";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { CSVLink } from "react-csv";
import { COMPANY_NAME } from "src/services/const";

const PaymentRecords = () => {
  const dispatch = useDispatch();
  const componentPDF = useRef();
  const [allCustomerData, setAllCustomerData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [lastName, setLastName] = useState("");

  const [loanAccountNumber, setLoanAccountNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [printState, setPrintState] = useState(false);
  const { emiData } = useSelector((state) => state?.emiReducer);

  useEffect(() => {
    getAllCompanyData();
  }, []);

  useEffect(() => {
    if (printState) {
      generatePDF();
    }
  }, [printState]);


  const download_btn = () => {
    setPrintState(true);
  }
  const generatePDF = useReactToPrint({

    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const getAllCompanyData = () => {
    const payload = { schemaName: SchemaName };
    getAllCustomers(payload).then((res) => {
      setAllCustomerData(res?.data);
    });
  };

  const [SchemaName, setSchemaName] = useState(
    sessionStorage.getItem("schemaName")
  );
  const obj = {
    schemaName: SchemaName,
    customerId: customerData?.id,
  };

  useEffect(() => {
    dispatch(getAllEmiByCustomerId(obj));
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = emiData?.data?.slice(indexOfFirstRow, indexOfLastRow);

  const handleSearch = () => {
    const filteredCustomer = allCustomerData.filter(
      (customer) =>
        customer.loanAccountNumber === String(loanAccountNumber) &&
        customer.firstName.toLowerCase() === firstName.toLowerCase() &&
        customer.lastName.toLowerCase() === lastName.toLowerCase()
    );

    if (filteredCustomer.length > 0) {
      setCustomerData(filteredCustomer[0]?.payments);
    } else {
      setCustomerData([]);
    }
  };

  const data = customerData?.filter((e) => {
    return e;
  });

  //select option is not there 
  // const handleRowsPerPageChange = (e) => {
  //   setRowsPerPage(e.target.value);
  //   setCurrentPage(1); 
  // };

  return (
    <div className="content">
      <CCard>
        <CCardHeader><strong>EMI Record</strong></CCardHeader>
        <CCardBody>
          <div>
            <CRow>
              <CCol xs={3}>
                <CFormInput
                  placeholder="Enter your Loan Account"
                  onChange={(e) => setLoanAccountNumber(e.target.value)}
                />
              </CCol>
              <CCol xs={3}>
                <CFormInput
                  placeholder="Enter your First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </CCol>
              <CCol xs={3}>
                <CFormInput
                  placeholder="Enter your Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </CCol>
              <CCol xs={3}>
                <CButton onClick={handleSearch}>Search</CButton>
              </CCol>
            </CRow>

            <div style={{ height: "10px" }}>{"  "}</div>

            <div className="mb-4">
              <div className="search_bar">

                <div className="search-inner">

                </div>

                <div className="btn_add mt-0 mb-14">

                  <CButton className="add-btn m-0 w-auto" onClick={download_btn}>
                    Download
                  </CButton>
                  {currentRows && (
                    <CSVLink data={currentRows === undefined ? " " : currentRows} filename={"paymentEMIRecord-table.csv"}>
                      <CButton className="add-btn w-auto m-0">
                        CSV
                      </CButton>
                    </CSVLink>
                  )}
                </div>
              </div>
            </div>
            {/* search_bar end */}
            <div>
              <div
                ref={printState ? componentPDF : null}
                style={{ padding: printState ? "20px" : "0px" }}
              >
                {printState && (
                  <div className="print-div">
                    <div>
                      <Vakilkaro />
                    </div>
                    <div>
                      <p>{COMPANY_NAME}</p>
                    </div>
                  </div>
                )}

                <CTable ref={componentPDF} hover className="" responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col" className="col-md-3">
                        <span className="spn">Payment Date</span>
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        <span className="spn">Amount</span>
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        <span className="spn">Payment Mode</span>
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col" className="col-md-2">
                        <span className="spn">Received By</span>
                      </CTableHeaderCell>

                      <CTableHeaderCell scope="col" className="col-md-1">
                        <span className="spn">Status</span>
                      </CTableHeaderCell>

                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {data?.map((e, index) => (
                      <CTableRow key={index}>
                        <CTableDataCell>
                          {e?.paymentDate ? e?.paymentDate : "13-09-2023"}
                        </CTableDataCell>
                        <CTableDataCell>
                          {e?.paymentAmount ? e?.paymentAmount : "--"}
                        </CTableDataCell>
                        <CTableDataCell>
                          {e?.paymentMode ? e?.paymentMode : " home loan"}
                        </CTableDataCell>
                        <CTableDataCell>
                          {e?.receivedBy === "null" ? "--" : e?.receivedBy}
                        </CTableDataCell>

                        <CTableDataCell>
                          {e?.status ? e?.status : "--"}
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Showing {indexOfFirstRow + 1} to{" "}
                  {Math?.min(indexOfLastRow, data?.length)} of {data?.length}{" "}
                  entries
                </div>
                <div className="pagination-container">
                  <ReactPaginate
                    activePage={currentPage}
                    itemsCountPerPage={rowsPerPage}
                    totalItemsCount={data?.length}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText="Previous"
                    nextPageText="Next"
                    firstPageText="First"
                    lastPageText="Last"
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
};
PaymentRecords.propTypes = {
  customerData: PropTypes.object,
  emiData: PropTypes.array,
};
export default PaymentRecords;
